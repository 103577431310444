<template>
    <div>
      <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div> 
    <template v-else>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table class="w-full text-left rtl:text-right text-gray-700">
          <thead>
            <tr class="bg-gray-100"> 
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input id="checkbox-all-search" type="checkbox"
                    class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="checkbox-all-search" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Serial no.
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Blood Group
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Blood Product
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Volume
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Origin
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Donation Date
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Expiry Date
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Status
                  <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                    </svg></a>
                </div>
              </th>
              <th scope="col" class="px-6">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in filteredBloodProducts" :key="product.product_id" class="border-b">
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4">
                  <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
                </div>
              </td>
              <td class="px-6 py-2">{{ product.pint_serial }}</td>
              <td class="px-6 py-2">{{ product.blood_group?.description }}</td>
              <td class="px-6 py-2">{{ product.unit_product?.name }}</td>
              <td class="px-6 py-2">{{ product.volume || '0' }} ml</td>
              <td class="px-6 py-2">{{ product.source || product.blood_donation?.origin }}</td>
              <td class="px-6 py-2">{{ formatDate(product.blood_donation?.date_added) }}</td>
              <td class="px-6 py-2">{{ formatDate(product.expiry_date) }}</td>
              <td class="px-6 py-2 text-center">{{ product.status }}</td>
              <td class="flex items-center px-6 py-2">
                <CustomButton label="View Details" @click="handleViewDetails(product.product_id)"
                  bg-class="bg-custom-yellow text-sm py-1 mr-2" />
               
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center gap-4 mt-4">
        <button @click="previousPage" :disabled="currentPage === 1"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m15 19-7-7 7-7" />
          </svg>
          Previous
        </button>
  
        <div class="flex items-center gap-2">
          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)" :class="{
            'bg-custom-blue text-white': page === currentPage,
            'text-custom-blue': page !== currentPage
          }"
            class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button">
            <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              {{ page }}
            </span>
          </button>
        </div>
  
        <button @click="nextPage" :disabled="currentPage === totalPages"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          Next
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m9 5 7 7-7 7" />
          </svg>
        </button>
      </div>
  </template>
    </div>
  </template>
  
  <script>
  import CustomButton from '@/components/CustomButton.vue';
  import api from '@/services/api';
  
  export default {
    name: 'ExternalUnitIssuanceTab',
    components: {
      CustomButton,
    },
    
    props: {
      searchQuery: {
        type: String,
        default: ''
      }
    },
  
    data() {
      return {
        currentPage: 1,
        totalPages: 0,
        perPage: 10,
        loading: false,
        donations: [],
        products: [],
        error: null,
        filters: {
          bloodGroup: '',
          dateRegistered: '',
          status: '',
          unitProduct: ''
        },
      }
    },
    mounted() {
      this.getBloodProducts();
    },
    computed: {
      visiblePages() {
        return this.calculatePageRange();
      },
      filteredBloodProducts() {
        const search = this.searchQuery.toLowerCase();
  
        return this.products.filter(product => {
          const bloodGroupDescription = product.blood_group?.description || '';
          const unitProductName = product.unit_product?.name || '';
          const bloodDonationOrigin = product.blood_donation?.origin || '';
          const productStatus = product.status || '';
          const expiryDate = product.expiry_date || '';
  
          // Validate and handle the expiryDate
          const validExpiryDate = expiryDate && !isNaN(new Date(expiryDate).getTime()) ? new Date(expiryDate).toISOString() : null;
  
          // Check search query match (case-insensitive)
          const matchesSearch =
            bloodGroupDescription.toLowerCase().includes(search) ||
            unitProductName.toLowerCase().includes(search) ||
            bloodDonationOrigin.toLowerCase().includes(search) ||
            productStatus.toLowerCase().includes(search) ||
            (validExpiryDate && validExpiryDate.toLowerCase().includes(search));
  
          // Check dropdown filters (case-insensitive)
          const matchesBloodGroup =
            !this.filters.bloodGroup || bloodGroupDescription.toLowerCase() === this.filters.bloodGroup.toLowerCase();
          const matchesUnitProduct =
            !this.filters.unitProduct || unitProductName.toLowerCase() === this.filters.unitProduct.toLowerCase();
          const matchesStatus =
            !this.filters.status || productStatus.toLowerCase() === this.filters.status.toLowerCase();
          const matchesDate =
            !this.filters.dateRegistered || expiryDate.startsWith(this.filters.dateRegistered);
  
          return matchesSearch && matchesBloodGroup && matchesUnitProduct && matchesStatus && matchesDate;
        });
      }
    },
    watch: {
      filters: { deep: true, handler: 'getBloodProducts' },
      searchQuery: 'getBloodProducts'
    },
    methods: {
  
      formatDate(date) {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
          console.error("Invalid date:", date);
          return null;
        }
  
        return parsedDate.toISOString().split('T')[0];
      },
  
      applyFilters(filters) {
        this.filters = { ...this.filters, ...filters };
        this.currentPage = 1;
      },
  
      resetFilters() {
        this.filters = {
          bloodGroup: '',
          dateRegistered: '',
          status: '',
          unitProduct: '',
        };
        this.currentPage = 1;
      },
  
      handleViewDetails(productId) {
        if (productId) {
          this.$router.push({
            name: 'ProductDetailsPage',
            params: { product_id: productId }
          });
        } else {
          console.error('Invalid productId');
        }
      },
  
      async getBloodProducts() {
  this.loading = true;
  try {
    const queryParams = {
      ...this.filters,
      search_query: this.searchQuery,
      page: this.currentPage,
      per_page: this.perPage
    };
    const response = await api.fetchFilteredProducts(queryParams);
    this.products = response.data.filter(product => product.status.toLowerCase().includes("issued to"));
    this.currentPage = response.current_page;
    this.totalPages = response.total_pages;
  } catch (error) {
    console.error("Error fetching products:", error);
  } finally {
    this.loading = false;
  }
},

      calculatePageRange() {
        const maxPagesToShow = 5;
        let startPage = Math.max(1, this.currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);
  
        // Adjust start page if we're near the end
        if (endPage - startPage + 1 < maxPagesToShow) {
          startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }
  
        return Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => startPage + i
        );
      },
  
      goToPage(page) {
        if (page >= 1 && page <= this.totalPages) {
          this.currentPage = page;
          this.getBloodProducts();
        }
      },
      async previousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          await this.getBloodProducts();
        }
      },
      async nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
          await this.getBloodProducts();
        }
      },
    },
  }
  </script>
  
  <style lang="css" scoped></style>