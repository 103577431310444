<template>
    <div class="max-w-7xl mx-auto p-4">
        <div v-if="error" class="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {{ error }}
        </div>

        <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
            <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>

        <template v-else>
            <div class="bg-white rounded-lg mb-4">
                <div class="flex justify-end">
                    <button @click="isEditing = true" :disabled="loading" class="text-custom-yellow">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>Edit
                    </button>
                </div>
                <div class="p-6">
                    <div v-if="isEditing">
                        <h2 class="text-lg font-semibold mb-4">Edit Assessment</h2>
                        <form @submit.prevent="updateAssessment">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Test Name:</div>
                                    <input v-model="form.assessment_name"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <!-- Updated Search Select for Basis -->
                                    <div>
                                        <label for="basis" class="block font-medium mb-2">Basis<span
                                                class="text-red-500">*</span></label>
                                        <div class="relative">
                                            <!-- Search Input with Selected Items -->
                                            <div
                                                class="relative flex flex-wrap items-center gap-2 px-3 py-2 border rounded-md shadow-sm shadow-gray-400 bg-white">
                                                <!-- Selected Documents Display -->
                                                <span v-for="docId in form.basis" :key="docId"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded flex items-center">
                                                    <span class="text-xs text-gray-500 mr-1">[{{ getDocumentType(docId)
                                                        }}]</span>
                                                    {{ getDocumentName(docId) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click.stop="removeBasis(docId)">x</span>
                                                </span>
                                                <!-- Search Input -->
                                                <input type="text" v-model="basisSearch" @input="filterDocuments"
                                                    @focus="handleSearchFocus" placeholder="Search document name..."
                                                    class="flex-grow border-none focus:ring-0 outline-none placeholder-gray-400" />
                                            </div>

                                            <!-- Dropdown Container -->
                                            <div v-if="showBasisDropdown"
                                                class="absolute z-20 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                                                <!-- Type Tabs -->
                                                <div class="flex border-b border-gray-200">
                                                    <button @click="selectedDocumentType = ''" :class="[
                                                        'px-4 py-2 text-sm font-medium',
                                                        !selectedDocumentType ?
                                                            'text-blue-600 border-b-2 border-blue-600' :
                                                            'text-gray-500 hover:text-gray-700'
                                                    ]">
                                                        All
                                                    </button>
                                                    <button v-for="type in documentTypes" :key="type"
                                                        @click="selectedDocumentType = type" :class="[
                                                            'px-4 py-2 text-sm font-medium',
                                                            selectedDocumentType === type ?
                                                                'text-blue-600 border-b-2 border-blue-600' :
                                                                'text-gray-500 hover:text-gray-700'
                                                        ]">
                                                        {{ type }}
                                                    </button>
                                                </div>

                                                <!-- Results Section -->
                                                <div class="max-h-60 overflow-y-auto">
                                                    <div v-if="filteredDocuments.length > 0">
                                                        <div v-for="doc in filteredDocuments" :key="doc.document_id"
                                                            @click="selectBasis(doc)"
                                                            class="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                                                            <span class="text-xs text-gray-500 mr-2">[{{
                                                                doc.document_type
                                                                }}]</span>
                                                            {{ doc.document_name }}
                                                        </div>
                                                    </div>
                                                    <div v-else class="p-4 text-center text-gray-500">
                                                        No documents found matching your criteria
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Departments:</div>
                                    <div class="relative">
                                        <div class="mt-2 w-full border border-gray-300 rounded p-2 cursor-pointer"
                                            @click="toggleDepartmentDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(departmentId, index) in form.departments"
                                                    :key="departmentId"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getDepartmentName(departmentId) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click.stop="removeDepartment(index)">x</span>
                                                </span>
                                                <span v-if="form.departments.length === 0" class="text-gray-400">
                                                    Select departments
                                                </span>
                                            </div>
                                        </div>

                                        <div v-if="isDepartmentDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="department in departments" :key="department.department_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectDepartment(department.department_id)">
                                                    {{ department.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Deadline:</div>
                                    <input v-model="form.deadline" type="date"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">User Types:</div>
                                    <div class="relative">
                                        <div class="mt-2 w-full border border-gray-300 rounded p-2 cursor-pointer"
                                            @click="toggleUserTypeDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(roleId, index) in form.roles" :key="roleId"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getRoleName(roleId) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click.stop="removeRole(index)">x</span>
                                                </span>
                                                <span v-if="form.roles.length === 0" class="text-gray-400">
                                                    Select user types
                                                </span>
                                            </div>
                                        </div>

                                        <div v-if="isUserTypeDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="role in roles" :key="role.role_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectRole(role)">
                                                    {{ role.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Number of Questions:</div>
                                    <input v-model="form.number_of_questions" type="number"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Duration:</div>
                                    <input v-model="form.duration" type="text"
                                        class="mt-2 w-full border border-gray-300 rounded p-2"
                                        placeholder="e.g., 2 hours" required />
                                </div>

                                <div class="col-span-2 border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Description:</div>
                                    <textarea v-model="form.description"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="flex justify-end mt-4">
                                <button type="submit"
                                    class="bg-custom-yellow hover:bg-orange-600 text-white px-4 py-2 rounded transition-colors">
                                    Save Changes
                                </button>
                                <button @click="isEditing = false"
                                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded transition-colors ml-2">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <div v-else>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <!-- Assessment Details -->
                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Test Name:</div>
                                <div class="mt-4">{{ assessment?.assessment_name }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Number of Candidates:</div>
                                <div class="mt-4">{{ assessment?.audience_number }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Basis:</div>
                                <div class="mt-4">
                                    <span v-for="(document, index) in assessment.documents" :key="document.document_id">
                                        {{ document.document_name }}
                                        <span v-if="index < assessment.documents.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Date of Creation:</div>
                                <div class="mt-4">{{ formatDate(assessment?.created_at) }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Department:</div>
                                <div class="mt-4">
                                    <span v-for="(department, index) in assessment.departments"
                                        :key="department.assessment_id">
                                        {{ department.name }}
                                        <span v-if="index < assessment.departments.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Deadline:</div>
                                <div class="mt-4">{{ formatDate(assessment?.deadline) }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">User Type:</div>
                                <div class="mt-4">
                                    <span v-for="(role, index) in assessment.roles" :key="role.assessment_id">
                                        {{ role.name }}
                                        <span v-if="index < assessment.roles.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Number of Questions:</div>
                                <div class="mt-4">{{ assessment?.number_of_questions }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Duration:</div>
                                <div class="mt-4">{{ assessment?.duration }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Status:</div>
                                <div class="mt-4">{{ assessment?.status }}</div>
                            </div>

                            <div class="col-span-2 border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Description:</div>
                                <div class="mt-4">
                                    <p>{{ assessment?.description }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- Action Buttons -->
                        <div class="flex justify-end gap-4 mt-4">
                            <!-- <button @click="isEditing = true" :disabled="loading"
                                class="bg-custom-blue hover:bg-blue-600 disabled:bg-blue-300 text-white px-4 py-2 rounded transition-colors">
                                Edit
                            </button> -->
                            <button v-if="assessment.status !== 'Archived'" @click="archiveAssessment"
                                :disabled="loading"
                                class="bg-red-500 hover:bg-red-600 disabled:bg-red-300 text-white px-4 py-2 rounded transition-colors">
                                Archive
                            </button>

                            <button v-else @click="unarchiveAssessment" :disabled="loading"
                                class="bg-green-500 hover:bg-green-600 disabled:bg-green-300 text-white px-4 py-2 rounded transition-colors">
                                Unarchive
                            </button>
                            <button v-if="!questionsCreated" @click="handleAddCatQuetions(assessment.assessment_id)"
                                :disabled="loading"
                                class="bg-custom-yellow hover:bg-orange-600 disabled:bg-orange-300 text-white px-4 py-2 rounded transition-colors">
                                Add C.A.T
                            </button>
                            <button v-if="questionsCreated" @click="handleReviewQuestions(assessment.assessment_id)"
                                :disabled="loading"
                                class="bg-custom-yellow hover:bg-orange-600 disabled:bg-orange-300 text-white px-4 py-2 rounded transition-colors">
                                Review Questions
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';

export default {
    name: "SupervisorAssessmentDetailsPage",
    data() {
        return {
            loading: true,
            error: null,
            assessment: null,
            assessment_id: null,
            questionsCreated: null,
            isEditing: false,
            departments: [],
            roles: [],
            documents: [],
            isDepartmentDropdownVisible: false,
            isUserTypeDropdownVisible: false,
            documentTypes: ['SOP', 'Training material', 'Manuals', 'Memo'],
            selectedDocumentType: '',
            showBasisDropdown: false,
            basisSearch: '',
            form: {
                assessment_name: '',
                audience_number: '',
                basis: [],
                departments: [],
                roles: [],
                deadline: '',
                number_of_questions: '',
                duration: '',
                status: '',
                description: ''
            }
        };
    },
    created() {
        this.assessment_id = this.$route.params.assessment_id || this.assessmentIdFromParent;
        this.fetchAssessment();
        this.checkAssessment();
        this.getDepartments();
        this.fetchRoles();
        this.fetchDocuments();
    },
    computed: {
        filteredDocuments() {
            let filtered = this.documents;

            // Filter by selected type
            if (this.selectedDocumentType) {
                filtered = filtered.filter(doc =>
                    doc.document_type === this.selectedDocumentType
                );
            }

            // Filter by search term
            if (this.basisSearch) {
                const searchTerm = this.basisSearch.toLowerCase();
                filtered = filtered.filter(doc =>
                    doc.document_name.toLowerCase().includes(searchTerm)
                );
            }

            // Ensure `form.basis` is an array before filtering
            if (Array.isArray(this.form.basis)) {
                filtered = filtered.filter(doc => !this.form.basis.includes(doc.document_id));
            }

            return filtered;
        }
    },
    methods: {
        toggleDepartmentDropdown() {
            this.isDepartmentDropdownVisible = !this.isDepartmentDropdownVisible;
        },

        toggleUserTypeDropdown() {
            this.isUserTypeDropdownVisible = !this.isUserTypeDropdownVisible;
        },

        selectDepartment(department) {
            if (!this.form.departments.includes(department.department_id)) {
                this.form.departments.push(department.department_id);
            }
            this.isDepartmentDropdownVisible = false;
        },

        selectRole(role) {
            if (!this.form.roles.includes(role.role_id)) {
                this.form.roles.push(role.role_id);
            }
            this.isUserTypeDropdownVisible = false;
        },

        removeDepartment(index) {
            this.form.departments.splice(index, 1);
        },

        removeRole(index) {
            this.form.roles.splice(index, 1);
        },

        getDepartmentName(departmentId) {
            const department = this.departments.find((dept) => dept.department_id === departmentId);
            return department ? department.name : '';
        },

        getRoleName(roleId) {
            const role = this.roles.find((r) => r.role_id === roleId);
            return role ? role.name : '';
        },

        async getDepartments() {
            try {
                const response = await api.getDepartments();
                this.departments = response.data || response;
            } catch (error) {
                console.error('Error fetching departments:', error);
                await SweetAlertService.showError("Error", "Failed to load departments");
            }
        },

        async fetchRoles() {
            try {
                const response = await api.getRoles();
                this.roles = response.data || response;
            } catch (error) {
                console.error('Error fetching roles:', error);
                await SweetAlertService.showError("Error", "Failed to load user types");
            }
        },

        async fetchDocuments() {
            this.loading = true;
            try {
                const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
                const response = await api.getDocuments(queryParams);
                this.documents = response.data || response;
                this.currentPage = response.current_page;
                this.totalPages = response.total_pages;
            } catch (error) {
                this.error = error.message || "Failed to fetch basis";
            } finally {
                this.loading = false;
            }
        },

        handleTypeChange() {
            this.basisSearch = ''; // Clear search when type changes
            this.showBasisDropdown = true;
        },

        selectBasis(document) {
            if (!Array.isArray(this.form.basis)) {
                this.form.basis = []; // Ensure basis is an array
            }

            if (!this.form.basis.includes(document.document_id)) {
                this.form.basis.push(document.document_id);
            }

            this.basisSearch = '';
            this.showBasisDropdown = false;
        },

        removeBasis(documentId) {
            const index = this.form.basis.indexOf(documentId);
            if (index > -1) {
                this.form.basis.splice(index, 1);
            }
        },

        getDocumentName(documentId) {
            const document = this.documents.find(doc => doc.document_id === documentId);
            return document ? document.document_name : '';
        },

        getDocumentType(documentId) {
            const document = this.documents.find(doc => doc.document_id === documentId);
            return document ? document.document_type : '';
        },

        filterDocuments() {
            this.showBasisDropdown = true;
        },

        // Add click outside handler to close dropdown
        handleClickOutside(event) {
            if (!event.target.closest('.basis-select')) {
                this.showBasisDropdown = false;
            }
        },

        // async fetchDocument() {
        //     this.loading = true;
        //     this.error = null;

        //     try {
        //         const response = await api.getDocument(this.document_id);
        //         this.document = response.data || response;

        //         // Set form values for editing
        //         this.form = {
        //             document_name: this.document.document_name,
        //             document_type: this.document.document_type,
        //             departments: this.document.departments.map(dep => dep.department_id),
        //             roles: this.document.roles.map(role => role.role_id),
        //             deadline: this.document.deadline,
        //             description: this.document.description
        //         };

        //     } catch (error) {
        //         this.error = error.message || "Failed to fetch documents";
        //         console.error('Error fetching document:', error);
        //     } finally {
        //         this.loading = false;
        //     }
        // },


        async updateAssessment() {
            try {
                const updatedAssessment = {
                    assessment_name: this.form.assessment_name,
                    audience_number: this.form.audience_number,
                    basis: this.form.basis.map(id => id), // Use the correct basis array
                    department_type: this.form.departments.map(id => id), // Assuming these are the correct IDs
                    user_type: this.form.roles.map(id => id), // Assuming these are the correct IDs
                    deadline: this.form.deadline,
                    number_of_questions: this.form.number_of_questions,
                    duration: this.form.duration,
                    status: this.form.status,
                    description: this.form.description
                };

                await api.updateAssessment(this.assessment_id, updatedAssessment);
                this.isEditing = false;
                this.fetchAssessment(); // Refresh the assessment details after update
            } catch (error) {
                this.error = error.message || "Failed to update assessment.";
            }
        },

        async fetchAssessment() {
            this.loading = true;
            this.error = null;
            try {
                const response = await api.getAssessment(this.assessment_id);
                this.assessment = response.data || response;

                // Populate the form with current assessment data for editing
                this.form = {
                    assessment_name: this.assessment.assessment_name,
                    audience_number: this.assessment.audience_number,
                    documents: this.assessment.documents.map(doc => doc.document_id),
                    departments: this.assessment.departments.map(dep => dep.department_id),
                    deadline: this.assessment.deadline.split('T')[0], // Ensure date is in YYYY-MM-DD format for input
                    roles: this.assessment.roles.map(role => role.role_id),
                    number_of_questions: this.assessment.number_of_questions,
                    duration: this.assessment.duration,
                    status: this.assessment.status,
                    description: this.assessment.description
                };
            } catch (error) {
                this.error = error.message || "Failed to fetch assessment details.";
                console.error("Error fetching assessment:", error);
            } finally {
                this.loading = false;
            }
        },
        async checkAssessment() {
            try {
                const response = await api.checkIfAssessmentExist(this.assessment_id);
                const questionsData = response.data || response;

                this.questionsCreated = questionsData.exists;
            } catch (error) {
                this.error = error.message || "Failed to fetch questions";
                this.questionsCreated = false;
            }
        },


        async archiveAssessment() {
            try {
                if (!this.assessment_id) throw new Error("Assessment ID is undefined");
                await api.toggleAssessmentStatus(this.assessment_id);
                await SweetAlertService.showSuccess("Success", "Assessment status updated to Archived");
                this.$router.go(0);
            } catch (error) {
                console.error(error);
                this.error = error.message || "Failed to archive document";
            }
        },

        async unarchiveAssessment() {
            try {
                if (!this.assessment_id) throw new Error("Assessment ID is undefined");
                await api.toggleAssessmentStatus(this.assessment_id);
                await SweetAlertService.showSuccess("Success", "Assessment status updated to Ongoing");
                this.$router.go(0);
            } catch (error) {
                console.error(error);
                this.error = error.message || "Failed to unarchive document";
            }
        },


        formatDate(date) {
            return date ? new Date(date).toLocaleString() : '';
        },

        handleAddCatQuetions(assessmentId) {
            this.$router.push({ name: 'AddAssessementQuestionsPage', params: { assessment_id: assessmentId } });
        },
        handleReviewQuestions() {
            this.$router.push({ name: 'QuestionsReviewPage', params: { assessment_id: this.assessment_id } });
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};
</script>

<style scoped>
/* Component-specific styles for improved alignment and responsiveness */
</style>