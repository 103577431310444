<template>
  <div>
    <AlertLayout title="Reminders!"
      message=" Patient names, ward and units required should be correctly entered to avoid confusion. <br> You will receive an sms / email notification once the requisition you send is received and processed by the receiving entity. 
      <br> Please remember to deposit the patient's blood sample at the receiving entity immediately after the requisition is made. <br>
       Kindly remember to as well inform the patient on the RISKS and BENEFITS of transfusion. "
      type="warning" />
    <h2 v-if="selecteRequisitionId" class="text-2xl py-2 text-custom-blue font-bold mb-4">Update Requisition</h2>
    <div class="container mx-auto px-8 border border-t-0">
      <h2 class="text-lg font-semibold mb-4 mt-5">Patient Details</h2>
      <!-- Form Fields for Recording Donation -->
      <form>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label class="block mb-1" for="lastName">Last Name/Other Name <span class="text-red-500">*</span></label>
            <input id="lastName" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Last Name/Other Name" v-model="formData.last_name" required />
          </div>

          <div>
            <label class="block mb-1" for="firstName">First Name <span class="text-red-500">*</span></label>
            <input id="firstName" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter First Name" v-model="formData.first_name" required />
          </div>


          <div>
            <label class="block mb-1" for="gender">Gender <span class="text-red-500">*</span></label>
            <div class="relative">
              <select id="gender"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.gender" required>
                <option value="" disabled selected>Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="birthDate">Date of Birth <span class="text-red-500">*</span></label>
            <input type="date" id="birthDate"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="select Date of Birth" v-model="formData.dob" required />
          </div>
        </div>
        <h2 class="text-lg font-semibold mb-4 mt-10">Patient Medical Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group <span
                class="text-red-500">*</span></label>
            <div class="relative">
              <select id="blood_group_id"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.blood_group_id" required>
                <option value="" disabled>Select blood group</option>
                <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
                  {{ group.description }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="patientInr">Patient INR (If FFP/CYRO is to be transfused) </label>
            <input id="patientInr" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Patient INR" v-model="formData.patient_inr" />
          </div>

          <div>
            <label class="block mb-1" for="patientType">Patient Type </label>
            <div class="relative">
              <select id="patientType"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.patient_type">
                <option value="" disabled selected>Select Type</option>
                <option value="inpatient">In-patient</option>
                <option value="outpatient">Out-patient</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="consultant">Consultant </label>
            <div class="relative">
              <select id="consultant"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.consultant">
                <option value="" disabled selected>Select Consultant</option>
                <option v-for="consultant in filteredConsultants" :key="consultant.user_id" :value="consultant.user_id">
                  {{ consultant.first_name }} {{ consultant.last_name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="ipop">IP/OP No <span class="text-red-500">*</span></label>
            <input id="ipop" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter IP/OP No" v-model="formData.ipop" required />
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="nurseInCharge">Nurse In Charge </label>
            <div class="relative">
              <select id="nurseInCharge"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.nurse_in_charge">
                <option value="" disabled selected>Select Nurse</option>
                <option v-for="nurse in filteredNurses" :key="nurse.user_id" :value="nurse.user_id">
                  {{ nurse.first_name }} {{ nurse.last_name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="ward">Select ward</label>
            <div class="relative">
              <select id="ward"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.department_id">
                <option value="" disabled selected>Select ward</option>
                <option v-for="ward in departments" :key="ward.department_id" :value="ward.department_id">
                  {{ ward.name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="lastTransfusion">Date of Last Transfusion</label>
            <input type="date" id="lastTransfusion"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="formData.previous_transfusion_date" />
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select bed</label>
            <div class="relative">
              <select id="bed_id"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.bed_id">
                <option value="" disabled selected>Select bed</option>
                <option v-for="bed in beds" :key="bed.bed_id" :value="bed.bed_id">
                  {{ bed.name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="reaction">Did Last Transfusion have a reaction</label>
            <div class="relative">
              <select id="reaction"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.reaction">
                <option value="" disabled selected>Select answer</option>
                <option value="true">yes</option>
                <option value="false">no</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="situation">Patient Situation</label>
            <div class="relative">
              <select id="situation"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.patient_situation">
                <option value="" disabled selected>Select situation</option>
                <option value="normal">Normal</option>
                <option value="emergency">Emergency</option>
                <option value="desperate">Desperate</option>
                <option value="noXmatch">Desparate (No X-match)</option>
                <option value="Urgent">Urgent</option>
                <option value="xmatch">Urgent (X-match)</option>
                <option value="nonurgent">Non-Urgent</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="intent">Select Intent</label>
            <div class="relative">
              <select id="intent"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.patient_intent">
                <option value="" disabled selected>Select Intent</option>
                <option value="transfusion">For Transfusion</option>
                <option value="theatre">For Theatre</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="consent">Signed Consent Form <span class="text-red-500">*</span></label>
            <div class="relative">
              <select id="consent"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.consent_form" required>
                <option value="" disabled selected>Select answer</option>
                <option value="true">yes</option>
                <option value="false">no</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="showConsentedField">
            <label class="block mb-1" for="consentDate">Enter date consent form was signed<span
                class="text-red-500">*</span></label>
            <input type="date" id="consentDate"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="formData.consent_date" required />
          </div>

          <div v-if="showConsentedField">
            <label class="block mb-1" for="unit_product">
              Select product for which consent form was signed <span class="text-red-500">*</span>
            </label>
            <div class="relative">
              <div
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                @click="toggleDropdown">
                <div class="flex flex-wrap gap-2">
                  <span v-for="(unit, index) in formData.unit_product_ids" :key="unit"
                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                    {{ getProductName(unit) }}
                    <span class="cursor-pointer ml-1 text-red-500" @click="removeProduct(index)">x</span>
                  </span>
                </div>
                <div v-if="!selecteRequisitionId && !formData.unit_product_ids.length" class="text-gray-400">
                  Select unit
                </div>
              </div>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>

              <!-- Dropdown to show product options -->
              <div v-if="isDropdownVisible" class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                <ul class="max-h-60 overflow-y-auto">
                  <li v-for="unit in unit_products" :key="unit.unit_product_id"
                    class="px-4 py-2 cursor-pointer hover:bg-gray-100" @click="selectProduct(unit)">
                    {{ unit.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="preTransfusion">Pre-transfusion sample collected </label>
            <div class="relative">
              <select id="preTransfusion"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.pre_transfusion_sample">
                <option value="" disabled selected>Select answer</option>
                <option value="true">yes</option>
                <option value="false">no</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="reason">Reason for Transfusion </label>
            <input id="reason" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter Reason" v-model="formData.transfusion_reason" />
          </div>

          <div v-if="formData.consent_form === 'true'">
            <label class="block mb-1" for="file_upload">
              Upload File
            </label>
            <div class="relative">
              <!-- Custom styled file input -->
              <input type="file" id="file_upload"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none cursor-pointer"
                @change="handleFileUpload" />

              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    d="M16.707 10.707l-1.414-1.414L12 12.586V4h-2v8.586l-3.293-3.293-1.414 1.414L10 15.414l6.707-6.707z" />
                </svg>
              </div>
            </div>

            <!-- Display uploaded file name -->
            <div v-if="uploadedFile" class="mt-2 text-gray-700">
              Selected File: <span class="font-semibold">{{ uploadedFile.name }}</span>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="preTransfusion">Date and Time pre-transfusion sample was collected</label>
            <input type="datetime-local" id="preTransfusion"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="formData.sample_collection_date" />
          </div>

          <div>
            <label class="block mb-1" for="preTransfusion">Date and Time Transfusion Starts</label>
            <input type="datetime-local" id="preTransfusion"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="formData.start_transfusion_date" />
          </div>

          <div>
            <label class="block mb-1" for="haemoglobin">Patient HB</label>
            <input id="haemoglobin" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter HB" v-model="formData.patient_hb" />
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Contact Nurse <span
                class="text-red-500">*</span> </label>
            <div class="relative">
              <select id="blood_group_id"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.contact_nurse" required>
                <option value="" disabled selected>Select Nurse</option>
                <option v-for="nurse in filteredNurses" :key="nurse.user_id" :value="nurse.user_id">
                  {{ nurse.first_name }} {{ nurse.last_name }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1" for="platelets">Platelets Level</label>
            <input id="platelets" type="text"
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              placeholder="Enter platelets level" v-model="formData.platelets_level" />
          </div>

        </div>
        <div class="flex justify-end space-x-4 mt-9 ">
          <button @click="handleCancel"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500">Cancel</button>
          <CustomButton v-if="!selecteRequisitionId" label="Add Product" bg-class="bg-custom-yellow"
            @click="openProductPopup" />
          <CustomButton v-if="selecteRequisitionId" label="Update Product" bg-class="bg-custom-yellow"
            @click="openProductPopup" />
        </div>
      </form>
    </div>

    <div v-if="showProductPopup"
      class="fixed inset-0 bg-gray-800 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-100">
        <h2 class="text-xl text-gray-600 font-bold mb-4">Product Details</h2>
        <form>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select product<span
                  class="text-red-500">*</span></label>
              <div class="relative">
                <select id="unit_product"
                  class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                  v-model="formData.unit_product_id">
                  <option value="" disabled selected>Select product</option>
                  <option v-for="unit in unit_products" :key="unit.unit_product_id" :value="unit.unit_product_id">
                    {{ unit.name }}
                  </option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div>
              <label class="block mb-1" for="measure">Do you want it in pints or assorted bags (milliliters)?<span
                  class="text-red-500">*</span></label>
              <select id="measure"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.uom">
                <option value="" disabled selected>Select answer</option>
                <option value="pints">Pints</option>
                <option value="milliliters">Milliliters</option>
              </select>
            </div>

            <div>
              <label class="block mb-1" for="product">Volume per Bag<span class="text-red-500">*</span></label>
              <input id="volume" type="text"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                placeholder="Enter Volume" v-model="formData.volume" :disabled="isVolumeDisabled" />
            </div>
            <div>
              <label class="block mb-1" for="productRequired">Date and Time Required<span
                  class="text-red-500">*</span></label>
              <input type="datetime-local" id="productRequired"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                v-model="formData.date_required" />
            </div>

            <div>
              <label class="block mb-1" for="product">Required Quantity<span class="text-red-500">*</span></label>
              <input id="product" type="text"
                class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                placeholder="Enter Quantity" v-model="formData.quantity" />
            </div>

          </div>
          <div class="flex justify-end">
            <button type="button" class="py-1 px-3 border rounded text-white bg-custom-yellow" @click="addProduct">
              <svg class="w-6 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="20"
                fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M5 12h14m-7 7V5" />
              </svg>
            </button>
          </div>
          <table class="w-full text-left rtl:text-right mt-5 text-gray-700">
            <thead>
              <tr class="bg-gray-100">
                <th scope="col" class="px-6">
                  <div class="flex items-center">
                    product
                  </div>
                </th>
                <th scope="col" class="px-6">
                  <div class="flex items-center">
                    Pint/Assorted Bag
                  </div>
                </th>
                <th scope="col" class="px-6">
                  <div class="flex items-center">
                    Volume
                  </div>
                </th>
                <th scope="col" class="px-6">
                  <div class="flex items-center">
                    Quantity
                  </div>
                </th>
                <th scope="col" class="px-6">
                  <div class="flex items-center">
                    Date and Time Required
                  </div>
                </th>
                <th scope="col" class="px-6">
                  <div class="flex items-center"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b" v-for="(product, index) in products" :key="index">
                <td class="px-4 py-1">{{ getProductName(product.unit_product_id) }}</td>
                <td class="px-4 py-1">{{ product.uom }}</td>
                <td class="px-4 py-1">{{ product.volume }}</td>
                <td class="px-4 py-1">{{ product.quantity }}</td>
                <td class="px-4 py-1">{{ product.date_required }}</td>
                <td class="pl-4 py-1">
                  <button type="button" class="px-2 py-1 dark:text-white bg-custom-yellow rounded"
                    @click="editProduct(index)">
                    <svg class="w-4 h-4 dark:text-white bg-custom-yellow" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                    </svg>
                  </button>
                  <button type="button" class="px-2 py-1 ml-3 dark:text-white bg-red-500 rounded"
                    @click="deleteProduct(index)">
                    <svg class="w-4 h-4 dark:text-white bg-red-500" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="flex justify-end mt-5 space-x-2">
            <button type="button" @click="closeProductPopup" class="px-4 py-1 bg-gray-200 text-gray-800 rounded">
              Close
            </button>
            <button v-if="!selecteRequisitionId" type="button" class="px-4 py-1 text-white rounded bg-custom-yellow"
              @click="submitRequisition" :disabled="products.length === 0">
              submit
            </button>
            <button v-if="selecteRequisitionId" type="button" class="px-4 py-1 text-white rounded bg-custom-yellow"
              @click="updateRequisition" :disabled="products.length === 0">
              update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from '@/components/AlertLayout.vue';
import api from '@/services/api';
import CustomButton from '@/components/CustomButton.vue';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'NewRequisitionForm',
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      bloodGroups: [],
      beds: [],
      departments: [],
      users: [], // fetched users
      selectedNurse: null,
      isDropdownVisible: false,
      uploadedFile: null,
      showProductPopup: false,
      unit_products: [],
      products: [],
      editingIndex: null,
      selecteRequisitionId: null,
      fetchedRequisitionData: [],
      formData: {
        first_name: "",
        last_name: "",
        gender: "",
        dob: "",
        blood_group_id: "",
        unit_product_id: "",
        unit_product_ids: [],
        patient_type: "",
        ipop: "",
        volume: "",
        uom: "",
        quantity: "",
        date_required: "",
        department_id: "",
        bed_id: "",
        date_added: "",
        patient_situation: "",
        patient_intent: "",
        patient_inr: "",
        transfusion_reason: "",
        patient_hb: "",
        platelets_level: "",
        contact_nurse: "",
        nurse_in_charge: "",
        consultant: "",
        previous_transfusion_date: "",
        sample_collection_date: "",
        pre_transfusion_sample: "",
        start_transfusion_date: "",
        consent_form: "",
        reaction: "",
        status: "",
        signed: "",
        consent_date: "",
        form_url: "",
      }
    };
  },
  computed: {
    filteredNurses() {
      return this.users.filter(user => {
        return user.user_roles.some(role => role.role.name === 'Nurse');
      });
    },

    filteredConsultants() {
      return this.users.filter(user => {
        return user.user_roles.some(role => role.role.name === 'Consultant');
      });
    },

    isVolumeDisabled() {
      // Disable the volume input if 'pints' is selected or no unit is selected
      return this.formData.uom === 'pints' || this.formData.uom === '';
    },

    showConsentedField() {
      return this.formData.consent_form === 'true' || this.fetchedRequisitionData.consent?.signed === true;
    },

  },

  watch: {
    'formData.uom'(newValue) {
      // Automatically set volume to 450 when 'pints' is selected
      if (newValue === 'pints') {
        this.formData.volume = 450;
      } else if (newValue === 'milliliters') {
        // Only reset volume to empty if there is no existing value (e.g., on new product add)
        if (!this.formData.volume) {
          this.formData.volume = ''; // Allow user to input volume for milliliters
        }
      }
    },
  },

  created() {
    this.fetchBloodGroups()
    this.getWards()
    this.fetchBeds()
    this.getUsers()
    this.fetchProductsData()
  },

  mounted() {
    this.selecteRequisitionId = this.$route.params.requisition_id
    if (this.selecteRequisitionId) {
      this.fetchRequisitionDetails(this.selecteRequisitionId);
    }
  },

  methods: {
    handleCancel() {
      this.$router.push({
        name: "ClinicalAreaPage",
      });
    },
    user() {
      return this.$store.getters["auth/user"];
    },

    async fetchRequisitionDetails(requisitionId) {
      try {
        const response = await api.getRequisition(requisitionId);
        const requisitionData = response.data || response[0];
        this.fetchedRequisitionData = requisitionData;
        // console.log("Requisition data:", requisitionData);

        if (requisitionData) {
          // const requisitionProducts = Array.isArray(requisitionData.requisition_products)
          //   ? requisitionData.requisition_products[0] || {}
          //   : requisitionData.requisition_products || {};

          const formattedStartTransfusionDate = requisitionData.start_transfusion_date
            ? new Date(requisitionData.start_transfusion_date).toISOString().slice(0, 16)
            : "";

          const formattedSampleCollectionDate = requisitionData.sample_collection_date
            ? new Date(requisitionData.sample_collection_date).toISOString().slice(0, 16)
            : "";

          if (requisitionData.consent?.signed === true) {
            this.formData.consent_form = 'true';
          }

          this.formData = {
            first_name: requisitionData.patient?.first_name || "",
            last_name: requisitionData.patient?.last_name || "",
            dob: requisitionData.patient?.dob || "",
            gender: requisitionData.patient?.gender || "",
            ipop: requisitionData.patient?.ipop || "",
            date_added: requisitionData.date_added || "",
            blood_group_id: requisitionData.patient?.blood_group?.blood_group_id || "",
            patient_requisition_id: requisitionData.patient_requisition_id || "",
            patient_inr: requisitionData.patient_inr || "",
            patient_type: requisitionData.patient?.patient_type || "",
            consultant: requisitionData.consultant?.user_id || "",
            nurse_in_charge: requisitionData.nurse_in_charge?.user_id || "",
            department_id: requisitionData.department?.department_id || "",
            previous_transfusion_date: requisitionData.previous_transfusion_date || "",
            bed_id: requisitionData.bed?.bed_id || "",
            reaction: requisitionData.reaction || "",
            consent_form: requisitionData.consent.signed || "",
            patient_situation: requisitionData.patient_situation || "",
            signed: requisitionData.consent?.signed || "",
            consent_date: requisitionData.consent?.date || "",
            patient_intent: requisitionData.patient_intent || "",
            pre_transfusion_sample: requisitionData.pre_transfusion_sample || "",
            transfusion_reason: requisitionData.transfusion_reason || "",
            sample_collection_date: formattedSampleCollectionDate,
            start_transfusion_date: formattedStartTransfusionDate,
            patient_hb: requisitionData.patient_hb || "",
            contact_nurse: requisitionData.contact_nurse?.user_id || "",
            platelets_level: requisitionData.platelets_level || "",
            unit_product_ids: requisitionData.consent?.products || "",

          };
          this.products = requisitionData.requisition_products || [];
        }
      } catch (error) {
        console.error("Error fetching requisition details:", error);
      }
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    async fetchBeds() {
      try {
        const response = await api.getBeds();
        this.beds = response;
      } catch (error) {
        console.error('Error fetching beds:', error);
      }
    },

    async fetchProductsData() {
      try {
        const response = await api.getUnitProducts();
        this.unit_products = response;
      } catch (error) {
        console.error('Error fetching beds:', error);
      }
    },

    async getWards() {
      try {
        const response = await api.getDepartments();
        this.departments = (response.data || response).filter(
          ward => ward.department_type === "Clinical Area"
        );

      } catch (error) {
        this.error = error.message || "Failed to fetch departments";
      }
    },

    async getUsers() {
      try {
        const response = await api.getUsers();
        this.users = response.filter(user => Array.isArray(user.user_roles) && user.user_roles.length > 0);
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      }
    },

    getProductName(unit_product_id) {
      const product = this.unit_products.find((unit) => unit.unit_product_id === unit_product_id);
      return product ? product.name : 'Unknown Product'; // Fallback if product is not found
    },

    addProduct() {
      // Validate and add product details to the products array
      if (this.formData.unit_product_id && this.formData.uom && this.formData.volume && this.formData.quantity && this.formData.date_required) {
        if (this.editingIndex !== null) {
          // Update existing product
          this.updateProduct();
        } else {
          // Add new product
          this.products.push({
            unit_product_id: this.formData.unit_product_id,
            uom: this.formData.uom,
            volume: this.formData.volume,
            quantity: this.formData.quantity,
            date_required: this.formData.date_required
          });
        }
        this.formData.unit_product_id = "";
        this.formData.volume = "";
        this.formData.uom = "";
        this.formData.quantity = "";
        this.formData.date_required = "";
      } else {
        // Handle validation error
        alert('Please fill all required fields');
      }
    },

    editProduct(index) {
      // Populate form with the selected product's data
      const product = this.products[index];

      const formattedReuiredDate = product.date_required
        ? new Date(product.date_required).toISOString().slice(0, 16)
        : "";

      this.formData.unit_product_id = product.unit_product_id;
      this.formData.volume = product.volume;
      this.formData.uom = product.uom;
      this.formData.quantity = product.quantity;
      this.formData.date_required = formattedReuiredDate;
      this.editingIndex = index; // Set editing index
    },
    updateProduct() {
      // Update the product details in the array
      if (this.editingIndex !== null) {
        // Update each field individually
        this.products[this.editingIndex].volume = this.formData.volume;
        this.products[this.editingIndex].uom = this.formData.uom;
        this.products[this.editingIndex].quantity = this.formData.quantity;
        this.products[this.editingIndex].date_required = this.formData.date_required;

        // Reset editing index after updating
        this.editingIndex = null;
      }
    },
    deleteProduct(index) {
      // Remove the product from the products array
      this.products.splice(index, 1);
    },

    openProductPopup() {
      this.productsForm
      this.showProductPopup = true;
    },

    validateFields() {
      const emptyFields = [];
      if (!this.formData.first_name) emptyFields.push('First name');
      if (!this.formData.last_name) emptyFields.push('Last name');
      if (!this.formData.dob) emptyFields.push('Date of Birth');
      if (!this.formData.department_id) emptyFields.push('Department');
      if (!this.formData.gender) emptyFields.push('Gender');
      if (!this.formData.patient_situation) emptyFields.push('Patient Situation');
      if (!this.formData.contact_nurse) emptyFields.push('Contact Nurse');
      if (!this.formData.consultant) emptyFields.push('Consultant');
      if (!this.formData.contact_nurse) emptyFields.push('Contact Nurse');
      if (!this.formData.nurse_in_charge) emptyFields.push('Nurse Incharge');
      if (!this.formData.consent_date) emptyFields.push('Consent Date');
      if (!this.formData.ipop) emptyFields.push('Patient IPOP');

      return emptyFields;
    },

    async submitRequisition() {
      try {
        const payload = {
          requisition: {
            department_id: this.formData.department_id,
            bed_id: this.formData.bed_id,
            date_added: new Date().toISOString().slice(0, 10),
            start_transfusion_date: this.formData.start_transfusion_date,
            patient_situation: this.formData.patient_situation,
            patient_intent: this.formData.patient_intent,
            patient_inr: this.formData.patient_inr,
            transfusion_reason: this.formData.transfusion_reason,
            patient_hb: this.formData.patient_hb,
            platelets_level: this.formData.platelets_level,
            contact_nurse: this.formData.contact_nurse,
            nurse_in_charge: this.formData.nurse_in_charge,
            consultant: this.formData.consultant,
            previous_transfusion_date: this.formData.previous_transfusion_date,
            sample_collection_date: this.formData.sample_collection_date,
            pre_transfusion_sample: this.formData.pre_transfusion_sample,
            consent_form: this.formData.consent_form,
            reaction: this.formData.reaction,
            status: 'Pending'
          },
          patient: {
            facility_id: this.user().facility_id,
            first_name: this.formData.first_name,
            last_name: this.formData.last_name,
            gender: this.formData.gender,
            dob: this.formData.dob,
            blood_group_id: this.formData.blood_group_id,
            patient_type: this.formData.patient_type,
            ipop: this.formData.ipop
          },
          consent: {
            signed: this.formData.consent_form,
            date: this.formData.consent_date,
            form_url: this.formData.form_url,
            products: this.formData.unit_product_ids
          },
          products: this.products,
        };

        const emptyFields = this.validateFields();
        if (emptyFields.length > 0) {
          SweetAlertService.showEmptyFieldsAlert(emptyFields);
          return;
        }

        console.log('Requisition saved successfully:', payload);

        await api.createRequisition(payload);
        await SweetAlertService.showSuccess('Success!', 'Requisition saved successfully!.');
        this.$router.push({ name: 'ClinicalAreaPage' });

      } catch (error) {
        this.error = error.message || 'Failed to save requisition';
        console.error('Error submitting the form:', error);
        SweetAlertService.showError('Error', 'Error saving requisition.')
      }
    },

    async updateRequisition() {
      try {
        const payload = {
          requisition: {
            requisition_id: this.formData.requisition_id,
            department_id: this.formData.department_id,
            bed_id: this.formData.bed_id,
            date_added: this.fetchedRequisitionData.date_added,
            start_transfusion_date: this.formData.start_transfusion_date,
            patient_situation: this.formData.patient_situation,
            patient_intent: this.formData.patient_intent,
            patient_inr: this.formData.patient_inr,
            transfusion_reason: this.formData.transfusion_reason,
            patient_hb: this.formData.patient_hb,
            platelets_level: this.formData.platelets_level,
            contact_nurse: this.formData.contact_nurse,
            nurse_in_charge: this.formData.nurse_in_charge,
            consultant: this.formData.consultant,
            previous_transfusion_date: this.formData.previous_transfusion_date,
            sample_collection_date: this.formData.sample_collection_date,
            pre_transfusion_sample: this.formData.pre_transfusion_sample,
            consent_form: this.formData.consent_form,
            reaction: this.formData.reaction === 'yes',
            status: this.formData.status || 'Pending',
          },
          patient: {
            patient_id: this.fetchedRequisitionData?.patient?.patient_id,
            facility_id: this.user().facility_id,
            first_name: this.formData.first_name,
            last_name: this.formData.last_name,
            gender: this.formData.gender,
            dob: this.formData.dob,
            blood_group_id: this.formData.blood_group_id,
            patient_type: this.formData.patient_type,
            ipop: this.formData.ipop,
          },
          consent: {
            consent_id: this.fetchedRequisitionData?.consent?.consent_id,
            signed: this.formData.signed,
            date: this.formData.consent_date,
            form_url: this.formData.form_url,
            products: this.formData.unit_product_ids
          },
          products: this.products,
        };

        const emptyFields = this.validateFields();
        if (emptyFields.length > 0) {
          SweetAlertService.showEmptyFieldsAlert(emptyFields);
          return;
        }

        await api.updateRequisition(this.selecteRequisitionId, payload);

        await SweetAlertService.showSuccess('Success!', 'Requisition updated successfully!');
        this.$router.push({ name: 'ClinicalAreaPage' });
      } catch (error) {
        this.error = error.message || 'Failed to update requisition';
        console.error('Error updating the requisition:', error);
        SweetAlertService.showError('Error', 'Error updating requisition.');
      }
    },


    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    selectProduct(product) {
      // Add product to selected array if it's not already selected
      if (!this.formData.unit_product_ids.includes(product.unit_product_id)) {
        this.formData.unit_product_ids.push(product.unit_product_id);
      }
      this.isDropdownVisible = false;
    },
    removeProduct(index) {
      // Remove the product from the selected array
      this.formData.unit_product_ids.splice(index, 1);
    },
    // oldGetProductName(unit_product_id) {
    //   // Get the product name from the id
    //   const product = this.unit_products.find(
    //     (product) => product.unit_product_id === unit_product_id
    //   );
    //   return product ? product.name : "";
    // },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFile = file;
        this.formData.form_url = file;
      }
    },

    closeProductPopup() {
      this.showProductPopup = false;
      this.productsForm = {
        product: "",
        volume: "",
        measure: "",
        required: "",
        quantity: "",
      };
    },


  },

}
</script>
