<template>
  <div>
    <AlertLayout title="Reminders!" message="<ul>
  <li>You will receive an sms / email notification once the requisition you sent is received and processed by the receiving entity.</li>
  <li>An 'Expired' status means a new blood sample has to be drawn from the patient and a new requisition form manually filled, should you choose the more blood option.</li>
  <li> Click on units amount to 'REQUEST FOR MORE BLOOD' for that particular patient.</li></ul>" type="warning" />

    <div>
      <div class="relative mb-5 justify-end  flex">
        <input type="text" id="table-search"
          class="px-4 py-2 text-gray-800 border border-gray-300 rounded-sm dark:border-gray-300 dark:placeholder-gray-400"
          placeholder="Search" />
        <button type="submit" class="bg-custom-yellow text-white rounded-r px-2 md:px-3 py-0 md:py-1">
          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
              d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" />
          </svg>
        </button>
      </div>
    </div>
    <div>
      <h3 class="text-lg font-semibold mt-5 mb-3">Select Units</h3>

      <table v-if="requisition"
        class="min-w-full divide-y mb-5 divide-gray-200 border border-gray-200 rounded-lg shadow-md">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-4 text-left text-xs tracking-wider w-1/4">Product</th>
            <th class="px-6 py-4 text-left text-xs tracking-wider w-1/4">Blood Group</th>
            <th class="px-6 py-4 text-left text-xs tracking-wider w-1/4">Unit(s) Requested/Unit(s) Issued</th>
            <th class="px-6 py-4 text-left text-xs tracking-wider w-1/4">Volume Requested</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(product, index) in requisition.assigned_units" :key="index">
            <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.unit_product.name }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.patient?.blood_group?.description }}</td>
            <td class="px-6 py-4 whitespace-nowrap text-sm">
              {{ parseFloat(product.quantity) % 1 === 0 ? parseInt(product.quantity) : product.quantity }}/
              {{ product.assigned_units.filter(unit => unit.status === 'Issued').length }}
            </td> <!-- Requested / Issued Units -->
            <td class="px-6 py-4 whitespace-nowrap text-sm">{{ product.volume }} {{ product.uom }}</td>
            <!-- Volume Requested -->
          </tr>
        </tbody>

      </table>

      <div v-else>Loading requisition details...</div>

    </div>
    <div>
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Serial No
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Blood Group
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Product
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Volume
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Days to Expiry
              </div>
            </th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in products" :key="product.product_id" class="border-b">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4"
                  @change="toggleUnitSelection(product.product_id, $event)" />
                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-2">{{ product.pint_serial }}</td>
            <td class="px-6 py-2">{{ product.blood_group.description }}</td>
            <td class="px-6 py-2">{{ product.unit_product.name }}</td>
            <td class="px-6 py-2">{{ product.volume }} mL</td>
            <td class="px-6 py-2">{{ calculateDaysToExpiry(product.expiry_date) }} days</td>
          </tr>
        </tbody>

      </table>
    </div>
    <div class="flex items-center mt-5">
      <input id="checkbox-all-search" required type="checkbox" v-model="isConfirmed"
        class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 ml-4 mr-3 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
      <label for="checkbox-all-search" class="sr-only">checkbox</label>
      <h4 class="text-md font-semibold"> I have confirmed, the assigned units are compatible with patient.</h4>
    </div>

    <div class="flex justify-end mt-5 space-x-2">

      <button type="submit" class="px-4 py-2  ml-3  bg-custom-yellow text-white rounded" @click="validateAndAssignUnit">
        Assign Unit
      </button>
      <button type="submit" class="px-4 py-2  ml-3  bg-custom-yellow text-white rounded" @click="assignAndPrintSticker">
        Assign and print sticker
      </button>
    </div>
  </div>
</template>

<script>
// import api from "@/services/api";
import AlertLayout from '@/components/AlertLayout.vue';
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';
import hospitalLogo from '@/assets/images/Jiji-Health-Blue-Logo.png';

export default {
  name: "AssignUnitPage",
  components: {
    AlertLayout

  },
  // created() {
  //   this.getRequisitions();
  // },
  props: {
    requisition_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      requisition: null,
      products: [],
      error: null,
      requisitionBloodGroupName: null,
      requisitionBloodGroupId: null,
      requisitionUnitProductId: null,
      selectedUnitIds: [],
      selectedProductIds: [],
      assigned_requisition: [],
    };
  },

  mounted() {
    this.flags = this.$store.getters["auth/flags"];
  },

  created() {
    this.getRequisitionProducts()
      .then(() => {
        this.getBloodProducts();
      })
      .catch(error => {
        console.error("Error fetching requisition products:", error);
      });
  },

  computed: {
    filteredRequisitions() {
      const search = this.searchQuery.toLowerCase();
      return this.requisitions.filter((requisitions) => {
        const safeToLower = (value) =>
          typeof value === "string" ? value.toLowerCase() : "";

        return (
          safeToLower(requisitions.user?.first_name)?.includes(search) ||
          safeToLower(requisitions.user?.last_name)?.includes(search) ||
          safeToLower(requisitions.user?.blood_group)?.includes(search)
        );
      });
    },

  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    redirectToRequisitionDetails() {
      this.$router.push({
        name: 'FacilityRequisitionDetailsPage',
      });
    },

    async getRequisitionProducts() {
      try {
        const id = this.$route.params.requisition_product_id;
        const response = await api.fetchRequisitionProduct(id);

        this.requisition = { assigned_units: [response] };

        // Ensure that requisition contains the blood_group_id and unit_product_id
        if (this.requisition.assigned_units.length > 0) {
          this.requisitionId = this.requisition.assigned_units[0].requisition.requisition_id;
          this.requisitionBloodGroupId = this.requisition.assigned_units[0].patient.blood_group.blood_group_id;
          this.requisitionBloodGroupName = this.requisition.assigned_units[0].patient.blood_group.name;
          this.requisitionUnitProductId = this.requisition.assigned_units[0].unit_product.unit_product_id;
        }

      } catch (error) {
        this.error = error.message || "Failed to fetch requisition details";
      }
    },

    async getBloodProducts() {
      try {
        // Fetch all products
        const response = await api.fetchAllProducts();

        // Filter out products with status "Assigned" or "Issued"
        let availableProducts = (response.data || response).filter(
          product => product.status === "Okay" && product.unit_product_id === this.requisitionUnitProductId
        );

        // Remove the last character from requisitionBloodGroupName
        // const requisitionBloodGroupBase = this.requisitionBloodGroupName.slice(0, -1);

        // Define compatible blood groups based on the full blood type, including Rh factor
        const compatibilityMap = {
          "A+": ["A+", "A-", "O+", "O-"],
          "A-": ["A-", "O-"],
          "B+": ["B+", "B-", "O+", "O-"],
          "B-": ["B-", "O-"],
          "AB+": ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
          "AB-": ["AB-", "A-", "B-", "O-"],
          "O+": ["O+", "O-"],
          "O-": ["O-"]
        };

        // Get the list of compatible blood types based on the requisition blood group
        const compatibleBloodTypes = compatibilityMap[this.requisitionBloodGroupName] || [];

        // Filter products based on compatibility with the given blood group name
        this.products = availableProducts.filter(product =>
          compatibleBloodTypes.includes(product.blood_group.name)
        );
      } catch (error) {
        this.error = error.message || 'Failed to fetch products';
      }
    },

    async fetchRequisition() {
      try {
        const id = this.requisition.assigned_units[0].requisition.requisition_id;
        const response = await api.getRequisition(id);
        this.assigned_requisition = (response.data || response)[0];
        return this.assigned_requisition
      } catch (error) {
        this.error = error.message || "Failed to fetch requisition details";
      }
    },


    toggleUnitSelection(productId, event) {
      if (event.target.checked) {
        // Add the unit ID to selectedUnitIds array
        this.selectedUnitIds.push(productId);
      } else {
        // Remove the unit ID from selectedUnitIds array
        const index = this.selectedUnitIds.indexOf(productId);
        if (index !== -1) {
          this.selectedUnitIds.splice(index, 1);
        }
      }
    },
    async validateAndAssignUnit() {
      if (this.selectedUnitIds.length === 0) {
        SweetAlertService.showError('Error', 'Please select at least one unit to assign.');
        return;
      }

      if (!this.isConfirmed) {
        SweetAlertService.showError('Error', 'Please confirm that the assigned units are compatible with the patient.');
        return;
      }
      await this.assignUnit();
    },

    async assignAndPrintSticker() {
      if (this.selectedUnitIds.length === 0) {
        SweetAlertService.showError('Error', 'Please select at least one unit to assign.');
        return;
      }

      if (!this.isConfirmed) {
        SweetAlertService.showError('Error', 'Please confirm that the assigned units are compatible with the patient.');
        return;
      }

      try {
        const payload = {
          requisition_product_id: this.$route.params.requisition_product_id,
          units: this.selectedUnitIds.map(unitId => ({
            product_id: unitId, // Send the selected unit ID in the payload
          })),
          assigned_by: this.user().user_id,
          issued_by: "",
          issued_to_name: "",
          issued_on: "",
          status: "Assigned",
        };

        const requisition_id = this.requisitionId;
        console.log("req id", requisition_id)

        if (!requisition_id) {
          throw new Error('Requisition ID is missing.');
        }

        await api.assignUnit(payload);
        await SweetAlertService.showSuccess('Success!', 'Unit assigned successfully!');
        await this.handlePrintSticker();
        this.$router.push({
          name: "FacilityRequisitionDetailsPage",
          params: { requisition_id },
        });
      } catch (error) {
        this.error = error.message || 'Failed to assign unit';
        console.error("Error assigning unit:", error.message);
        SweetAlertService.showError('Error', 'Error assigning unit.');
      }
    },
    async assignUnit() {
      try {
        const payload = {
          requisition_product_id: this.$route.params.requisition_product_id,
          units: this.selectedUnitIds.map(unitId => ({
            product_id: unitId, // Send the selected unit ID in the payload
          })),
          assigned_by: this.user().user_id,
          issued_by: "",
          issued_to_name: "",
          issued_on: "",
          status: "Assigned",
        };

        const requisition_id = this.requisitionId;

        if (!requisition_id) {
          throw new Error('Requisition ID is missing.');
        }

        await api.assignUnit(payload);
        await SweetAlertService.showSuccess('Success!', 'Unit assigned successfully!');
        this.$router.push({
          name: "FacilityRequisitionDetailsPage",
          params: { requisition_id },
          query: { flag: "inventory_management" }
        });
      } catch (error) {
        this.error = error.message || 'Failed to assign unit';
        console.error("Error assigning unit:", error.message);
        SweetAlertService.showError('Error', 'Error assigning unit.');
      }
    },

    async handlePrintSticker() {
      const printWindow = document.createElement('iframe');
      printWindow.style.position = 'absolute';
      printWindow.style.width = '0';
      printWindow.style.height = '0';
      document.body.appendChild(printWindow);

      console.log('selected' + this.selectedUnitIds)

      const product = await this.fetchRequisition()

      // const filteredProducts = product.requisition_products.filter((requisitionProduct) => {
      //   return requisitionProduct.assigned_units.some((assignedUnit) =>
      //     assignedUnit.product_id.includes(this.selectedUnitIds)
      //   );
      // });

      try {
        const patient = product.patient;
        const department = product.department.name;
        const consultant = product.consultant;
        const sample = product.samples[0];

        let allPrintContent = '';
        product.requisition_products[0].assigned_units.forEach(unit => {
          const productDetails = {
            name: unit.unit_product.name || "N/A",
            volume: unit.volume || "N/A",
            bloodGroup: unit.blood_group.description || "N/A",
            serialNumber: unit.pint_serial || "N/A",
            expiryDate: unit.expiry_date || "N/A"
          };

          const printContent = `
        <div class="page-container">
          <div class="sticker-container">
            <div class="logo-container">
              <img src="${hospitalLogo}" alt="Hospital Logo" class="logo">
            </div>
            <h1 class="compatibility-title">Compatibility Label</h1>
            <div class="compatibility-container">
              <div class="patient-info">
                <h3>Patient Details</h3>
                <p><span class="label">Patient Name:</span> <span class="value">${patient.first_name} ${patient.last_name}</span></p>
                <p><span class="label">IP/OP No:</span> <span class="value">${patient.ipop}</span></p>
                <p><span class="label">Ward:</span> <span class="value">${department}</span></p>
                <p><span class="label">Blood Group:</span> <span class="value">${patient.blood_group.description}</span></p>
                <p><span class="label">Dr. Name:</span> <span class="value">${consultant.first_name} ${consultant.last_name}</span></p>
              </div>
              <div class="product-info">
                <h3>Product Details</h3>
                <p><span class="label">Serial No:</span> <span class="value">${productDetails.serialNumber}</span></p>
                <p><span class="label">Product:</span> <span class="value">${productDetails.name}</span></p>
                <p><span class="label">Blood Group:</span> <span class="value">${productDetails.bloodGroup}</span></p>
                <p><span class="label">Sample Lab No:</span> <span class="value">${sample.sample_lab_number}</span></p>
                <p><span class="label">Expiry Date:</span> <span class="value">${new Date(productDetails.expiryDate).toLocaleDateString()}</span></p>
              </div>
            </div>
            <div class="crossmatch-info">
              <p><span class="label">X-Match done by:</span> <span class="value">${sample.gxm_confirmed_by.first_name || ''} ${sample.gxm_confirmed_by.last_name || ''}</span></p>
              <p><span class="label">On:</span> <span class="value">${new Date(sample.gxm_confirmed_on).toLocaleDateString()}</span></p>
            </div>
          </div>
        </div>
      `;

          allPrintContent += printContent;
        });

        const printDocument = `
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page {
            size: A6;
            margin: 0;
          }
          * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }
          body {
            font-family: Manjari, sans-serif;
            margin: 0;
            padding: 0;
            background-color: white;
          }
          .page-container {
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .sticker-container {
            width: 500px;  /* Fixed width for the content */
            padding: 20px;
            background-color: white;
            border: 1px solid #ddd;  /* Optional: adds a light border */
            box-shadow: 0 0 10px rgba(0,0,0,0.1);  /* Optional: adds a subtle shadow */
          }
          .logo-container {
            text-align: center;
            margin-bottom: 10px;
          }
          .logo {
            height: 60px;
            margin-bottom: 5px;
          }
          .hospital-name {
            color: #cc0000;
            margin: 5px 0;
            font-size: 16px;
          }
          .compatibility-title {
            text-align: center;
            font-size: 20px;
            margin: 10px 0;
          }
          .compatibility-container {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-top: 10px;
          }
          .patient-info, .product-info {
            flex: 1;
          }
          h3 {
            font-size: 18px;
            margin: 0 0 10px 0;
          }
          p {
            margin: 5px 0;
            font-size: 16px;
            line-height: 1.3;
          }
          .label {
            font-weight: normal;
          }
          .value {
            font-weight: bold;
          }
          .crossmatch-info {
            text-align: center;
            margin-top: 15px;
            font-weight: bold;
          }
          @media print {
            .page-container {
              height: 100%;
              page-break-after: always;
            }
            .sticker-container {
              border: none;
              box-shadow: none;
            }
          }
        </style>
      </head>
      <body>
        ${allPrintContent}
      </body>
      </html>
    `;

        printWindow.contentDocument.open();
        printWindow.contentDocument.write(printDocument);
        printWindow.contentDocument.close();

        printWindow.contentWindow.onload = function () {
          printWindow.contentWindow.print();
        };
      } catch (error) {
        this.error = error.message || 'Failed to fetch donation';
      }
    },

    calculateDaysToExpiry(expiryDate) {
      const today = new Date();
      const expiry = new Date(expiryDate);
      const timeDifference = expiry - today;
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return daysDifference > 0 ? daysDifference : 0; // Return 0 if the product has expired
    }



  },
};
</script>

<style lang="css" scoped></style>
