<template>
  <div>
    <AlertLayout
      title="Reminders!"
      message="Incorrect screening results could be fatal to recipients. Kindly ensure you enter the correct results and information below.
      <br> Kindly note that you can only process one unit at a time"
      type="warning"
    />
    <div class="mx-auto px-10 py-2 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Issue Out Product</h2>
      <form class="grid grid-cols-2 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="blood_group"
            >Select blood group</label
          >
          <div class="relative">
            <select
              id="blood_group_id"
              v-model="formData.blood_group_id"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              @change="filterUnitProducts"
              required
            >
              <option value="" disabled>Select blood group</option>
              <option
                v-for="group in bloodGroups"
                :key="group.blood_group_id"
                :value="group.blood_group_id" 
              >
                {{ group.description }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="product_id">
            Pint Serial <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <div
              class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              @click="toggleDropdown"
            >
              <div class="flex flex-wrap gap-2">
                <span
                  v-for="(productId, index) in formData.product_ids"
                  :key="productId"
                  class="bg-blue-100 text-blue-600 px-2 py-1 rounded"
                >
                  {{ getProductName(productId) }}
                  <span
                    class="cursor-pointer ml-1 text-red-500"
                    @click="removeProduct(index)"
                    >x</span
                  >
                </span>
              </div>
              <div v-if="!formData.product_ids.length" class="text-gray-400">
                Select unit serial number
              </div>
            </div>
            <!-- Dropdown icon -->
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343Z 8z"
                />
              </svg>
            </div>
            <!-- Dropdown options -->
            <div
              v-if="isDropdownVisible"
              class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded"
            >
              <ul class="max-h-60 overflow-y-auto">
                <li
                  v-for="product in filteredUnitProducts"
                  :key="product.product_id"
                  class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  @click="selectProduct(product.product_id)"
                >
                  {{ product.pint_serial }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium"
            >Issue to (Facility Name)</label
          >
          <input
            type="text"
            v-model="formData.facility"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter facility name"
            required
          />
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium"
            >Received by</label
          >
          <input
            type="text"
            v-model="formData.received_by"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter name"
            required
          />
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="issuedBy"
            >Issued By <span class="text-red-500">*</span></label
          >
          <select
            v-model="formData.user_id"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            required
          >
            <option value="" disabled>Select User</option>
            <option
              v-for="user in users"
              :key="user.user_id"
              :value="user.user_id"
            >
              {{ user.first_name }} {{ user.last_name }}
            </option>
          </select>
        </div>

        <div>
          <label
            class="block mb-2 text-gray-700 font-medium"
            for="bloodExpiryDate"
          >
            Date Issued<span class="text-red-500">*</span></label
          >
          <input
            type="date"
            v-model="formData.expiry_date"
            class="w-full px-2 py-3 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            required
          />
        </div>
      </form>

      <table class="w-full text-left rtl:text-right mt-5 text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="px-6">
              <div class="flex items-center">Pint Serial</div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">product</div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">Volume</div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">Blood Group</div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">Expiry Date</div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(product, index) in selectedProducts"
            :key="product.product_id"
            class="border-b"
          >
            <td class="px-4 py-1">{{ product.pint_serial }}</td>
            <td class="px-4 py-1">{{ product.unit_product.name }}</td>
            <td class="px-4 py-1">{{ product.volume }}</td>
            <td class="px-4 py-1">{{ product.blood_group.name }}</td>
            <td class="px-4 py-1">  {{ new Date(product.expiry_date).toLocaleDateString('en-CA') }}</td>
            <td class="pl-4 py-1">
             
              <button
                type="button"
                class="px-2 py-1 ml-3 dark:text-white bg-red-500 rounded"
                @click="removeProduct(index)"
              >
                <svg
                  class="w-4 h-4 dark:text-white bg-red-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                  />
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center justify-between mt-8">
        <button
          @click="handleBackToInventory"
          class="px-4 py-2 border rounded text-orange-500 border-orange-500"
        >
          Back
        </button>

        <div class="flex items-center ml-auto">
          <button
            @click="resetForm"
            v-if="!officer_id"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500"
          >
            Cancel
          </button>
          <CustomButton
            v-if="!officer_id"
            label="Save"
            bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleSubmitNewUnit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";

export default {
  name: "ExternalUnitIssuanceForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: "",
      bloodGroups: [],
      bloodProducts: [],
      unitProducts: [],
      users: [],
      filteredUnitProducts: [],
      selectedProducts: [],
      isDropdownVisible: false,
      formData: {
        blood_group_id: "",
        facility: "",
        user_id: "",
        received_by: "",
        product_ids: [],
        expiry_date: "",
      },
      status: "active",
      error: null,
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.fetchBloodGroups();
    this.fetchBloodProducts();
    this.fetchUnitProducts();
  },
  created() {
    this.getUsers();
  },
  methods: {
    handleBackToInventory() {
      this.$router.push({ name: "InventoryManagementPage" });
    },

    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },

    validateFields() {
      const emptyFields = [];
      if (!this.formData.user_id) emptyFields.push("Issued By");
      if (!this.formData.product_ids) emptyFields.push("Pint serial");
      if (!this.formData.blood_group_id) emptyFields.push("Blood Group");
      if (!this.formData.received_by) emptyFields.push("Received By");
      if (!this.formData.facility) emptyFields.push("Facility");
      if (!this.formData.expiry_date) emptyFields.push("Expiry Date");

      return emptyFields;
    },

    async handleSubmitNewUnit() {
      const data = {
        blood_group_id: this.formData.blood_group_id,
        facility: this.formData.facility,
        user_id: this.formData.user_id,
        received_by: this.formData.received_by,
        product_ids: this.formData.product_ids,
        expiry_date: this.formData.expiry_date,
      }

      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.createUnitTransfer(data);
        await SweetAlertService.showSuccess('Success!', 'unit transferred successfully!.');
        this.$router.push({ name: 'InventoryManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to save unit transfer';
        console.error('Error:', this.error);
        SweetAlertService.showError('Error', 'Error updating unit transfer.')
      }
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error("Error fetching blood groups:", error);
      }
    },

    async getUsers() {
      try {
        const response = await api.getUsers();
        this.users = response.filter(
          (user) => Array.isArray(user.user_roles) && user.user_roles.length > 0
        );
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      }
    },

    async fetchBloodProducts() {
      try {
        const response = await api.getUnitProducts();
        this.bloodProducts = response || [];
      } catch (error) {
        this.error = "Failed to fetch blood products";
      }
    },

    async fetchUnitProducts() {
      try {
        const response = await api.fetchAllProducts();
        this.unitProducts = (response || []).filter(product => product.status === "Okay");
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    filterUnitProducts() {
      if (this.formData.blood_group_id) {
        this.filteredUnitProducts = this.unitProducts.filter(
          (product) => product.blood_group_id === this.formData.blood_group_id
        );
      } else {
        this.filteredUnitProducts = [];
      }
    },

    resetForm() {
      this.formData = {
        pint_serial: "",
        source: "",
        blood_group_id: "",
        unit_product_id: "",
        volume: "",
        expiry_date: "",
      };
    },

    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },

    getProductName(productId) {
      const product = this.filteredUnitProducts.find(
        (p) => p.product_id === productId
      );
      return product ? product.pint_serial : "";
    },

    selectProduct(productId) {
    if (!this.formData.product_ids.includes(productId)) {
      this.formData.product_ids.push(productId);
      const selectedProduct = this.unitProducts.find(p => p.product_id === productId);
      if (selectedProduct) {
        this.selectedProducts.push(selectedProduct);
      }
      this.isTestDropdownVisible = false;
    }
  },
    
    removeProduct(index) {
      const removedProductId = this.formData.product_ids[index];
      this.formData.product_ids.splice(index, 1);
      this.selectedProducts = this.selectedProducts.filter(
        (p) => p.product_id !== removedProductId
      );
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>

