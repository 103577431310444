<template>
    <div v-if="show"
        class="fixed inset-0 bg-gray-900 bg-opacity-60 z-10 overflow-y-auto h-full w-full flex items-center justify-center text-base py-8">
        <div class="bg-white rounded-lg shadow-lg w-8/12 max-h-[90vh] flex flex-col">
            <!-- Header with border bottom -->
            <div class="flex items-center justify-between p-4 border-b border-gray-200">
                <h2 class="text-2xl font-bold">Upload New Material</h2>
                <button @click="closeModal" class="text-gray-500 hover:text-gray-700" aria-label="Close">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                        </path>
                    </svg>
                </button>
            </div>

            <!-- Form content with scrolling -->
            <div class="overflow-y-auto flex-1 px-8">
                <div class="py-6">
                    <form @submit.prevent="submitForm">
                        <div class="grid grid-cols-2 gap-6">
                            <!-- Left Column -->
                            <div class="space-y-4">
                                <div>
                                    <label for="documentName" class="block font-medium mb-2">Document Name<span
                                            class="text-red-500">*</span></label>
                                    <input id="documentName" v-model="formData.document_name" type="text"
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required />
                                </div>

                                <div>
                                    <label for="documentType" class="block font-medium mb-2">Document Type<span
                                            class="text-red-500">*</span></label>
                                    <select id="documentType" v-model="formData.document_type"
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required>
                                        <option value="">Select Document Type</option>
                                        <option v-for="type in documentTypes" :key="type" :value="type">{{ type }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Department Multi-Select -->
                                <div v-if="showDepartmentField" class="mb-4">
                                    <label for="department" class="block font-medium mb-2">
                                        Department<span class="text-red-500">*</span>
                                    </label>
                                    <div class="relative">
                                        <!-- Selected Departments -->
                                        <div class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                                            @click="toggleDepartmentDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(department, index) in formData.department_type"
                                                    :key="department"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getDepartmentName(department) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click="removeDepartment(index)">x</span>
                                                </span>
                                            </div>
                                            <div v-if="!formData.department_type.length" class="text-gray-400">
                                                Select department
                                            </div>
                                        </div>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20">
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>

                                        <!-- Dropdown to show department options -->
                                        <div v-if="isDepartmentDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="department in departments" :key="department.department_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectDepartment(department)">
                                                    {{ department.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <!-- Right Column -->
                            <div class="space-y-4">
                                <!-- User Type Multi-Select -->
                                <div v-if="showUserTypeField" class="mb-4">
                                    <label for="userType" class="block font-medium mb-2">
                                        User Type<span class="text-red-500">*</span>
                                    </label>
                                    <div class="relative">
                                        <!-- Selected Roles -->
                                        <div class="w-full px-3 py-2 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                                            @click="toggleUserTypeDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(role, index) in formData.user_type" :key="role"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getRoleName(role) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click="removeRole(index)">x</span>
                                                </span>
                                            </div>
                                            <div v-if="!formData.user_type.length" class="text-gray-400">
                                                Select User Type
                                            </div>
                                        </div>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20">
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                        </div>

                                        <!-- Dropdown to show role options -->
                                        <div v-if="isUserTypeDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="role in roles" :key="role.role_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectRole(role)">
                                                    {{ role.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label for="deadline" class="block font-medium mb-2">Deadline<span
                                            class="text-red-500">*</span></label>
                                    <input id="deadline" v-model="formData.deadline" type="datetime-local"
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required />
                                </div>


                                <div>
                                    <label for="uploadDocument" class="block font-medium mb-2">Upload Document<span
                                            class="text-red-500">*</span></label>
                                    <input id="uploadDocument" type="file" @change="handleFileUpload" 
                                        class="border border-gray-300 px-3 py-2 rounded-md w-full" required />
                                </div>

                            </div>
                        </div>

                        <!-- Full width Description field -->
                        <div class="mt-6">
                            <label for="documentDescription" class="block font-medium mb-2">Document Description<span
                                    class="text-red-500">*</span></label>
                            <textarea id="documentDescription" v-model="formData.description"
                                class="border border-gray-300 px-3 py-2 rounded-md w-full" rows="3" required></textarea>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Footer with buttons -->
            <div class="border-t border-gray-200 p-4 px-8">
                <div class="flex justify-end">
                    <button type="button" @click="clearForm"
                        class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-md mr-2">
                        Cancel
                    </button>
                    <button @click="submitForm" :disabled="isSubmitting"
                        class="bg-custom-yellow hover:bg-yellow-500 text-white font-medium py-2 px-4 rounded-md">
                        {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AWS from 'aws-sdk'; // Import AWS SDK
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';

export default {
    name: 'NewMaterialUploadForm',
    components: {
        // MultiSelectDropdown
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selectedFile: null,
            isSubmitting: false,
            errorMessage: '',
            formData: {
                document_name: '',
                document_type: '',
                department_type: [],
                user_type: [],
                deadline: '',
                description: '',
                file_url: '',
                date_uploaded: new Date().toISOString()
            },
            documentTypes: ['SOP', 'Training material', 'Manuals', 'Memo'],
            departments: [],
            roles: [],
            uploadedFile: null,
            isDepartmentDropdownVisible: false,
            isUserTypeDropdownVisible: false,
            showDepartmentField: true, // Set based on your logic
            showUserTypeField: true,
            error: null
        };
    },
    created() {
        this.getDepartments();
        this.fetchRoles();
    },
    methods: {
        toggleDepartmentDropdown() {
            this.isDepartmentDropdownVisible = !this.isDepartmentDropdownVisible;
        },

        toggleUserTypeDropdown() {
            this.isUserTypeDropdownVisible = !this.isUserTypeDropdownVisible;
        },

        selectDepartment(department) {
            if (!this.formData.department_type.includes(department.department_id)) {
                this.formData.department_type.push(department.department_id);
            }
            this.isDepartmentDropdownVisible = false; // Close dropdown after selection
        },

        selectRole(role) {
            if (!this.formData.user_type.includes(role.role_id)) {
                this.formData.user_type.push(role.role_id);
            }
            this.isUserTypeDropdownVisible = false; // Close dropdown after selection
        },

        removeDepartment(index) {
            this.formData.department_type.splice(index, 1);
        },

        removeRole(index) {
            this.formData.user_type.splice(index, 1);
        },

        getDepartmentName(departmentId) {
            const department = this.departments.find((dept) => dept.department_id === departmentId);
            return department ? department.name : '';
        },

        getRoleName(roleId) {
            const role = this.roles.find((r) => r.role_id === roleId);
            return role ? role.name : '';
        },
        async getDepartments() {
            try {
                const response = await api.getDepartments();
                this.departments = response.data || response;
                console.log('Fetched departments:', this.departments);
            } catch (error) {
                console.error('Error fetching departments:', error);
                this.error = error.message || "Failed to fetch departments";
                await SweetAlertService.showError("Error", "Failed to load departments");
            }
        },

        async fetchRoles() {
            try {
                const response = await api.getRoles();
                this.roles = response.data || response;
            } catch (error) {
                console.error('Error fetching roles:', error);
                this.error = error.message || "Failed to fetch roles";
                await SweetAlertService.showError("Error", "Failed to load user types");
            }
        },

        handleFileUpload(event) {
            // Capture the selected file
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
            }
        },

        async uploadFile() {
            if (!this.selectedFile) {
                alert("No file selected.");
                return;
            }
            // Configure AWS SDK
            const s3 = new AWS.S3({
                endpoint: 'lon1.digitaloceanspaces.com', // Replace with your Spaces endpoint
                signatureVersion: 'v4',
                accessKeyId: 'DO00PK2KLQ4HUBKTB6R3',
                secretAccessKey: 'L3yOKmH3Dqfpc3tVZv4Mz5O+jd1bXTobKS//zmY72Rk',
                region: 'lon1',
            });

            const params = {
                Bucket: 'jiji-bucket', // Replace with your Space name
                Key: this.selectedFile.name,
                Body: this.selectedFile,
                ACL: 'public-read', // Make the file public if needed
                ContentType: this.selectedFile.type,
            };

            try {
                const result = await s3.upload(params).promise();
                console.log(result);

               if(result) {
                return result.Location;
               }
                this.$toast.success('File uploaded successfully.');
            } catch (error) {
                this.$toast.error('Unable to upload file, please check your internet connection.');
                return '';
            }
        },

        async submitForm() {
            if (this.formData.department_type.length === 0) {
                await SweetAlertService.showError("Error", "Please select at least one department");
                return;
            }

            if (this.formData.user_type.length === 0) {
                await SweetAlertService.showError("Error", "Please select at least one user type");
                return;
            }

            this.isSubmitting = true;
            this.errorMessage = '';

            try {
                if (this.selectedFile) {
                    const fileUrl = await this.uploadFile();
                    if (!fileUrl) {
                        this.isSubmitting = false;
                        return;
                    }
                    this.formData.file_url = fileUrl;
                }

                console.log(this.formData);

                // Then submit the form with the updated file_url
                const response = await api.createDocument(this.formData);

                this.$emit('document-created', response.data);
                await SweetAlertService.showSuccess("Success", "Document Details uploaded successfully");
                this.clearForm();
                this.closeModal();
                this.$router.go(0);
            } catch (error) {
                console.error('Error uploading document:', error);
                const errorMessage = error.response?.data?.message || 'Failed to upload document. Please try again.';
                await SweetAlertService.showError("Error", errorMessage);
            } finally {
                this.isSubmitting = false;
            }
        },

        clearForm() {
            this.formData = {
                document_name: '',
                document_type: '',
                department_type: [],
                user_type: [],
                deadline: '',
                description: '',
                file_url: '',
                date_uploaded: new Date().toISOString()
            };
            this.uploadedFile = null;
            const fileInput = document.getElementById('uploadDocument');
            if (fileInput) fileInput.value = '';
        },

        closeModal() {
            this.clearForm();
            this.$emit('close-modal');
        }
    }
};
</script>