const state = {
  user: null,
  token: null,
  roles: [],
  claims: [],
  flags: []
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setClaims(state, claims) {
    state.claims = claims;
  },
  setFlags(state, flags) {
    state.flags = flags;
  },
};

const actions = {
  login({ commit }, { data, token, roles, flags }) {
    commit("setUser", data);
    commit("setToken", token);
    commit("setRoles", roles);
    commit("setFlags", flags);

    // Extract and set claims based on roles
    const claims = roles.flatMap((role) => role.claims);
    commit("setClaims", claims);
  },

  logout({ commit }) {
    commit("setUser", null);
    commit("setToken", null);
    commit("setRoles", []);
    commit("setClaims", []);
    commit("setFlags", []);
  },
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  roles: (state) => state.roles,
  claims: (state) => state.claims,
  flags: (state) => state.flags,
  hasRole: (state) => (roleName) => {
    return state.roles.some((role) => role.name === roleName);
  },
  hasClaim: (state) => (claim) => {
    return state.claims.includes(claim);
  },
  hasFlag: (state) => (flag) => {
    return state.flags.includes(flag);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
