<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div>
    <template v-else>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table class="w-full text-left rtl:text-right text-gray-700">
          <thead>
            <tr class="bg-gray-100">
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input id="checkbox-all-search" type="checkbox"
                    class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label for="checkbox-all-search" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Test Name
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Department
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Deadline
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  No of Candidates
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">
                  Status
                </div>
              </th>
              <th scope="col" class="px-6">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(assessment, index) in filteredAssessments" :key="index" class="border-b">
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                  <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
                </div>
              </td>
              <td class="px-6 py-1">{{ assessment.assessment_name }}</td>
              <td class="px-6 py-1">
                <ul>
                  <li v-for="(department, index) in assessment.departments" :key="department.department_id">
                    {{ department.name }}
                    <span v-if="index < assessment.departments.length - 1">,</span>
                  </li>
                </ul>
              </td>
              <td class="px-6 py-1">{{ new Date(assessment.deadline).toISOString().split('T')[0] }}</td>
              <td class="px-6 py-1 text-center">{{ assessment.audience_number }}</td>
              <td class="px-6 py-1">
                <span class="p-2 rounded-lg" :class="{
                  'bg-green-200 text-green-800': ['Ongoing'].includes(assessment.status),
                  'bg-blue-200 text-blue-800': ['Completed'].includes(assessment.status),
                  'bg-yellow-200 text-yellow-800': ['Pending'].includes(assessment.status),
                  'bg-gray-200 text-gray-800': ['Archived'].includes(assessment.status)
                }">
                  {{ assessment.status }}
                </span>
              </td>
              <td class="flex items-center px-4 py-2">
                <CustomButton label="Details" bg-class="bg-custom-yellow text-sm py-1" @click="
                  handleShowDetails(assessment.assessment_id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center gap-4 mt-4">
        <button @click="previousPage" :disabled="currentPage === 1"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m15 19-7-7 7-7" />
          </svg>
          Previous
        </button>

        <div class="flex items-center gap-2">
          <button v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{
            'bg-custom-blue text-white': page === currentPage,
            'text-custom-blue': page !== currentPage
          }" class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button">
            <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              {{ page }}
            </span>
          </button>
        </div>

        <button @click="nextPage" :disabled="currentPage === totalPages"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          Next
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m9 5 7 7-7 7" />
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "SupervisorAssessementTab",
  components: {
    CustomButton,
  },
  created() {
    this.fetchAssessment();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      assessments: [],
      error: null,
      currentPage: 1,
      totalPages: 0,
      perPage: 10,
      loading: false,
      filters: {
        department_id: "",
        assessmentDeadlineDate: "",
        assessmentStatus: "",

      },
    };
  },
  computed: {
    filteredAssessments() {
      return this.assessments.filter((assessment) => {
        const search = this.searchQuery.toLowerCase();
        const safeToLower = (value) =>
          typeof value === "string" ? value.toLowerCase() : "";

        // Apply search filter
        const matchesSearch =
          safeToLower(assessment.assessment_name).includes(search) ||
          safeToLower(assessment.assessment_type).includes(search) ||
          safeToLower(assessment.status).includes(search) ||
          safeToLower(new Date(assessment.deadline).toLocaleDateString("en-CA")).includes(search);


        // Apply additional filters

        const matchesDepartment =
          this.filters.department_id === "" ||
          safeToLower(assessment.departments?.name) === safeToLower(this.filters.department_id);

          const matchesDeadline =
          this.filters.assessmentDeadlineDate === "" ||
          new Date(document.deadline)
            .toLocaleDateString("en-CA")
            .includes(this.filters.assessmentDeadlineDate);

        const matchesStatus =
          this.filters.assessmentStatus === "" ||
          safeToLower(assessment.status) === safeToLower(this.filters.assessmentStatus);

        return matchesSearch && matchesDepartment && matchesDeadline && matchesStatus;
      });
    },
  },
  watch: {
    filters: { deep: true, handler: 'fetchassessments' },
    searchQuery: 'fetchassessments'
  },
  methods: {
    async fetchAssessment() {
      this.loading = true;
      try {
        const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
        const response = await api.getAssessments(queryParams);
        this.assessments = response.data || response;
        this.currentPage = response.current_page;
        this.totalPages = response.total_pages;
      } catch (error) {
        this.error = error.message || "Failed to fetch assessments";
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.currentPage = 1;
    },

    resetFilters() {
      this.filters = {
        department_id: "",
        assessmentDeadlineDate: "",
        assessmentStatus: "",
      };
      this.currentPage = 1;
    },

    async goToPage(page) {
      this.currentPage = page;
      await this.fetchAssessment();
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchAssessment();
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchAssessment();
      }
    },

    handleShowDetails(assessment_id) {
      this.$router.push({
        name: 'SupervisorAssessementDetailsPage',
        params: { assessment_id }
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
