<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div>
    <template v-else>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table class="w-full text-left rtl:text-right text-gray-700">
          <thead>
            <tr class="bg-gray-100">
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input id="checkbox-all-search" type="checkbox"
                    class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                  <label for="checkbox-all-search" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Test Name</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Deadline</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Duration</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Exerminer</div>
              </th>
              <th scope="col" class="px-6">
                <div class="flex items-center">Status</div>
              </th>
              <th scope="col" class="px-6">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(assessment, index) in filteredAssessments" :key="index" class="border-b">
              <td class="w-4 p-4">
                <div class="flex items-center">
                  <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                  <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
                </div>
              </td>
              <td class="px-6 py-1">{{ assessment.assessment_name }}</td>
              <td class="px-6 py-1">
                {{ new Date(assessment.deadline).toLocaleTimeString() }}
              </td>
              <td class="px-6 py-1">
                {{ assessment.duration }}
              </td>
              <td class="px-6 py-2">
                {{ assessment.created_by?.first_name }}
                {{ assessment.created_by?.last_name }}
              </td>
              <td class="px-6 py-2">
                <span class="p-2 rounded-lg" :class="{
                  'bg-green-100 text-green-800': ['Complete', 'Ongoing'].includes(assessment.status),
                  'bg-red-100 text-red-800': ['Pending'].includes(assessment.status)
                }">
                  {{ assessment.status }}
                </span>
              </td>
              <td class="flex items-center px-4 py-2">
                <CustomButton label="Details" bg-class="bg-custom-yellow text-sm py-1"
                  @click="handleShowDetails(assessment.assessment_id)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center gap-4 mt-4">
        <button @click="previousPage" :disabled="currentPage === 1"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m15 19-7-7 7-7" />
          </svg>
          Previous
        </button>

        <div class="flex items-center gap-2">
          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)" :class="{
            'bg-custom-blue text-white': page === currentPage,
            'text-custom-blue': page !== currentPage
          }"
            class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button">
            <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              {{ page }}
            </span>
          </button>
        </div>

        <button @click="nextPage" :disabled="currentPage === totalPages"
          class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          Next
          <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
            viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m9 5 7 7-7 7" />
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>


<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "TheatreOnlyDaysTab",
  components: {
    CustomButton,
  },
  created() {
    this.fetchAssessment();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      assessments: [],
      error: null,
      currentPage: 1,
      totalPages: 0,
      perPage: 10,
      loading: false,
      filters: {
        status: "",
      },
    };
  },
  computed: {
    userClaims() {
      return this.$store.getters["auth/claims"];
    },
    userData() {
      return this.$store.getters["auth/user"];
    },

    visiblePages() {
      return this.calculatePageRange();
    },

    filteredAssessments() {
      const search = this.searchQuery.toLowerCase();
      return this.assessments.filter((assessments) => {
        const assessmentName = assessments.asessment_name || '';
        const status = assessments.status || '';

        const matchesSearch =
          assessmentName.toLowerCase().includes(search) ||
          status.toLowerCase().includes(search);

        const matchesStatus =
          !this.filters.status || status.toLowerCase() === this.filters.status.toLowerCase();

        return matchesSearch && matchesStatus;
      });
    }
  },

  watch: {
    filters: { deep: true, handler: 'fetchAssessment' },
    searchQuery: 'fetchAssessment'
  },

  methods: {
    async fetchAssessment() {
      this.loading = true;
      try {
        const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
        const response = await api.getStaffAssessments(queryParams);
        this.assessments = response.data || response;
        this.currentPage = response.current_page;
        this.totalPages = response.total_pages;
      } catch (error) {
        this.error = error.message || "Failed to fetch assessments";
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.currentPage = 1;
    },

    resetFilters() {
      this.filters = {
        documentTypes: "",
      };
      this.currentPage = 1;
    },

    calculatePageRange() {
      const maxPagesToShow = 5;
      let startPage = Math.max(1, this.currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);

      // Adjust start page if we're near the end
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },

    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchAssessment();
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchAssessment();
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchAssessment();
      }
    },

    handleShowDetails(assessment_id) {
      this.$router.push({
        name: "StaffAssessmentDetailsPage",
        params: { assessment_id: assessment_id },
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
