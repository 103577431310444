<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div> 
    <template v-else>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Role Name
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Description
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(role, index) in filteredRoles" :key="index" class="border-b">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-1">{{ role.name }}</td>
            <td class="px-6 py-1">{{ role.description }}</td>
            <td class="flex items-center px-4 py-2">
              <CustomButton label="Edit role" @click="openClaimModal(role.role_id)"
                bg-class="bg-custom-yellow text-sm py-1" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center gap-4 mt-4">
      <button disabled
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m15 19-7-7 7-7" />
        </svg>
        Previous
      </button>
      <div class="flex items-center gap-2">
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg bg-custom-blue text-center align-middle font-medium text-white shadow-md shadow-custom-blue/10 transition-all hover:shadow-lg hover:shadow-custom-blue/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            1
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            2
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            3
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            4
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            5
          </span>
        </button>
      </div>
      <button
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        Next
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>

    <div v-if="showClaimsModal"
      class="fixed inset-0 bg-gray-800 bg-opacity-60 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-3/6 mt-4 mb-4">
        <h2 class="text-2xl text-gray-800 font-bold mb-4">Permisions Assigning</h2>
        <hr class="h-px my-4 bg-orange-200 border-0 dark:bg-orange-200">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div v-for="(claims, category) in categorizedClaims" :key="category" class="mb-6">
            <h3 class="text-lg font-semibold text-gray-700 mb-2">{{ category }}</h3>
            <div class="grid grid-cols-1 gap-4">
              <div v-for="claim in claims" :key="claim.claim_id" class="flex items-center">
                <input type="checkbox" :id="'claim-' + claim.claim_id" class="mr-2" />
                <label :for="'claim-' + claim.claim_id" class="text-md text-gray-700">{{ claim.name }}</label>
              </div>
            </div>
            <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200">
          </div>
        </div>
        <div class="flex justify-end space-x-2">
          <button type="button" @click="closeClaimModal"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded">Cancel</button>
          <button @click="handleClaimSubmit()" class="px-4 py-2 bg-custom-yellow text-white rounded">Submit</button>
        </div>
      </div>
    </div>
</template>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "TheatreOnlyDaysTab",
  components: {
    CustomButton,
  },
  created() {
    this.fetchRoles();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      roles: [],
      roles_claims: [],
      categorizedClaims: {},
      currentRoleId: '',
      showClaimsModal: false,
      error: null,
      loading: false
    };
  },
  computed: {
    filteredRoles() {
      const search = this.searchQuery.toLowerCase();
      return this.roles.filter((roles) => {
        const safeToLower = (value) =>
          typeof value === "string" ? value.toLowerCase() : "";

        return (
          safeToLower(roles.name)?.includes(search) ||
          safeToLower(roles.description)?.includes(search)
        );
      });
    },
    userClaims() {
      return this.$store.getters['auth/claims'];
    },
    userData() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    this.fetchClaims()
  },
  methods: {
    async fetchRoles() {
      this.loading = true
      try {
        const response = await api.getRoles();
        this.roles = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      } finally {
        this.loading = false
      }
    },
    async fetchClaims() {
      try {
        const response = await api.getClaims();
        this.roles_claims = response.data || response;
        this.categorizeClaims();
      } catch (error) {
        this.error = error.message || "Failed to fetch claims";
      }
    },
    categorizeClaims() {
      this.categorizedClaims = this.roles_claims.reduce((categories, claim) => {
        if (!categories[claim.category]) {
          categories[claim.category] = [];
        }
        categories[claim.category].push(claim);
        return categories;
      }, {});
    },
    openClaimModal(role_id) {
      this.currentRoleId = role_id;
      this.showClaimsModal = true;
      this.fetchRolesClaims(role_id);
    },
    closeClaimModal() {
      this.showClaimsModal = false;
    },

    async fetchRolesClaims(roleId) {
      try {
        const response = await api.fetchRolesClaims(roleId);
        const roleClaims = response.data || response;

        this.$nextTick(() => {
          this.roles_claims.forEach(claim => {
            const checkbox = document.getElementById('claim-' + claim.claim_id);
            if (checkbox) {
              checkbox.checked = false;
            }
          });

          // Check the checkboxes for the current role claims
          roleClaims.forEach(claimId => {
            const checkbox = document.getElementById('claim-' + claimId);
            if (checkbox) {
              checkbox.checked = true;
            }
          });
        });
      } catch (error) {
        console.error("Failed to fetch role claims:", error);
      }
    },

    async handleClaimSubmit() {
      try {
        const selectedClaims = [];
        for (const [category, claims] of Object.entries(this.categorizedClaims)) {
          if (Array.isArray(claims)) {
            claims.forEach(claim => {
              const checkbox = document.getElementById('claim-' + claim.claim_id);
              if (checkbox && checkbox.checked) {
                selectedClaims.push(claim.claim_id);
              }
            });
          } else {
            console.error(`Expected 'claims' to be an array for category '${category}', but got:`, claims);
          }
        }

        const payload = {
          role_id: this.currentRoleId,
          claims: {
            claim_ids: selectedClaims
          }
        };

        console.log('Payload:', payload);

        await api.addClaimsToRole(payload);
        this.closeClaimModal();
        await SweetAlertService.showSuccess('Success!', 'Previleges updated successfully!.');
      } catch (error) {
        console.error("Failed to assign previledges:", error);
        SweetAlertService.showError('Error', 'Error updating priviledges.');
      }
    }
  },
};
</script>

<style lang="css" scoped></style>