<template>
    <div class="max-w-7xl mx-auto p-4">
        <div v-if="error" class="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
            {{ error }}
        </div>

        <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
            <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>

        <div v-else>
            <div class="bg-white rounded-lg mb-6">
                <div class="flex justify-end">
                    <button @click="isEditing = true" :disabled="loading" class="text-custom-yellow">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>Edit
                    </button>
                </div>

                <div class="p-6">
                    <div v-if="isEditing">
                        <form @submit.prevent="updateDocument">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Document Name:</div>
                                    <input v-model="form.document_name"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" />
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Document Type:</div>
                                    <select v-model="form.document_type"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" required>
                                        <option value="">Select Document Type</option>
                                        <option v-for="type in documentTypes" :key="type" :value="type">
                                            {{ type }}
                                        </option>
                                    </select>
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Departments:</div>
                                    <div class="relative">
                                        <div class="mt-2 w-full border border-gray-300 rounded p-2 cursor-pointer"
                                            @click="toggleDepartmentDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(departmentId, index) in form.departments"
                                                    :key="departmentId"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getDepartmentName(departmentId) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click.stop="removeDepartment(index)">x</span>
                                                </span>
                                                <span v-if="form.departments.length === 0" class="text-gray-400">
                                                    Select departments
                                                </span>
                                            </div>
                                        </div>

                                        <div v-if="isDepartmentDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="department in departments" :key="department.department_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectDepartment(department)">
                                                    {{ department.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">User Types:</div>
                                    <div class="relative">
                                        <div class="mt-2 w-full border border-gray-300 rounded p-2 cursor-pointer"
                                            @click="toggleUserTypeDropdown">
                                            <div class="flex flex-wrap gap-2">
                                                <span v-for="(roleId, index) in form.roles" :key="roleId"
                                                    class="bg-blue-100 text-blue-600 px-2 py-1 rounded">
                                                    {{ getRoleName(roleId) }}
                                                    <span class="cursor-pointer ml-1 text-red-500"
                                                        @click.stop="removeRole(index)">x</span>
                                                </span>
                                                <span v-if="form.roles.length === 0" class="text-gray-400">
                                                    Select user types
                                                </span>
                                            </div>
                                        </div>

                                        <div v-if="isUserTypeDropdownVisible"
                                            class="absolute z-10 bg-white shadow-lg mt-1 w-full border rounded">
                                            <ul class="max-h-60 overflow-y-auto">
                                                <li v-for="role in roles" :key="role.role_id"
                                                    class="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                                    @click="selectRole(role)">
                                                    {{ role.name }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Deadline:</div>
                                    <input v-model="form.deadline" type="date"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" />
                                </div>

                                <div class="col-span-2 border-b border-gray-200 py-3">
                                    <div class="font-medium text-gray-700">Document Description:</div>
                                    <textarea v-model="form.description"
                                        class="mt-2 w-full border border-gray-300 rounded p-2" rows="4"></textarea>
                                </div>
                            </div>
                            <div class="flex justify-end mt-4">
                                <button type="submit"
                                    class="bg-custom-yellow hover:bg-orange-600 text-white px-4 py-2 rounded transition-colors">
                                    Save Changes
                                </button>
                                <button @click="isEditing = false"
                                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded transition-colors ml-2">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                    <div v-else>
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Document Name:</div>
                                <div class="mt-4">{{ document?.document_name }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Document Type:</div>
                                <div class="mt-4">{{ document?.document_type }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Department:</div>
                                <div class="mt-4">
                                    <span v-for="(department, index) in document.departments"
                                        :key="department.document_id">
                                        {{ department.name }}
                                        <span v-if="index < document.departments.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">User Type:</div>
                                <div class="mt-4">
                                    <span v-for="(role, index) in document.roles" :key="role.document_id">
                                        {{ role.name }}
                                        <span v-if="index < document.roles.length - 1">, </span>
                                    </span>
                                </div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Date Uploaded:</div>
                                <div class="mt-4">{{ new Date(document?.date_uploaded).toLocaleString() }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Deadline:</div>
                                <div class="mt-4">{{ new Date(document?.deadline).toLocaleString() }}</div>
                            </div>

                            <div class="border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Status:</div>
                                <div class="mt-4">{{ document?.status }}</div>
                            </div>

                            <div class="col-span-2 border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Document Description:</div>
                                <div class="mt-4">
                                    <p>{{ document?.description }}</p>
                                </div>
                            </div>

                            <div class="col-span-2 border-b border-gray-200 py-3">
                                <div class="font-medium text-gray-700">Uploaded Document:</div>
                                <div class="py-2 px-2 ">
                                    <iframe :src="`${document.file_url}`" width="100%" height="700px"></iframe>
                                </div>
                            </div>
                        </div>

                        <div class="flex justify-end gap-4 mt-4">
                            <button v-if="document.status !== 'Archived'" @click="archiveDocument" :disabled="loading"
                                class="bg-red-500 hover:bg-red-600 disabled:bg-red-300 text-white px-4 py-2 rounded transition-colors">
                                Archive
                            </button>

                            <button v-else @click="unarchiveDocument" :disabled="loading"
                                class="bg-green-500 hover:bg-green-600 disabled:bg-green-300 text-white px-4 py-2 rounded transition-colors">
                                Unarchive
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
    name: 'SupervisorDocumentsDetailsPage',
    data() {
        return {
            document: null,
            documentTypes: ['SOP', 'Training material', 'Manuals', 'Memo'],
            departments: [],
            roles: [],
            isDepartmentDropdownVisible: false,
            isUserTypeDropdownVisible: false,
            loading: true,
            error: null,
            document_id: null,
            isEditing: false,
            form: {
                document_name: '',
                document_type: '',
                departments: [],
                roles: [],
                deadline: '',
                description: ''
            }
        };
    },
    created() {
        this.document_id = this.$route.params.document_id;
        this.fetchDocument();
        this.getDepartments();
        this.fetchRoles();
    },
    methods: {
        toggleDepartmentDropdown() {
            this.isDepartmentDropdownVisible = !this.isDepartmentDropdownVisible;
        },

        toggleUserTypeDropdown() {
            this.isUserTypeDropdownVisible = !this.isUserTypeDropdownVisible;
        },

        selectDepartment(department) {
            if (!this.form.departments.includes(department.department_id)) {
                this.form.departments.push(department.department_id);
            }
            this.isDepartmentDropdownVisible = false;
        },

        selectRole(role) {
            if (!this.form.roles.includes(role.role_id)) {
                this.form.roles.push(role.role_id);
            }
            this.isUserTypeDropdownVisible = false;
        },

        removeDepartment(index) {
            this.form.departments.splice(index, 1);
        },

        removeRole(index) {
            this.form.roles.splice(index, 1);
        },

        getDepartmentName(departmentId) {
            const department = this.departments.find((dept) => dept.department_id === departmentId);
            return department ? department.name : '';
        },

        getRoleName(roleId) {
            const role = this.roles.find((r) => r.role_id === roleId);
            return role ? role.name : '';
        },

        async getDepartments() {
            try {
                const response = await api.getDepartments();
                this.departments = response.data || response;
            } catch (error) {
                console.error('Error fetching departments:', error);
                await SweetAlertService.showError("Error", "Failed to load departments");
            }
        },

        async fetchRoles() {
            try {
                const response = await api.getRoles();
                this.roles = response.data || response;
            } catch (error) {
                console.error('Error fetching roles:', error);
                await SweetAlertService.showError("Error", "Failed to load user types");
            }
        },

        async fetchDocument() {
            this.loading = true;
            this.error = null;

            try {
                const response = await api.getDocument(this.document_id);
                this.document = response.data || response;

                // Set form values for editing
                this.form = {
                    document_name: this.document.document_name,
                    document_type: this.document.document_type,
                    departments: this.document.departments.map(dep => dep.department_id),
                    roles: this.document.roles.map(role => role.role_id),
                    deadline: this.document.deadline,
                    description: this.document.description
                };

            } catch (error) {
                this.error = error.message || "Failed to fetch documents";
                console.error('Error fetching document:', error);
            } finally {
                this.loading = false;
            }
        },

        async updateDocument() {
            try {
                const updatedDocument = {
                    document_name: this.form.document_name,
                    document_type: this.form.document_type,
                    department_type: this.form.departments.map(id => id), // Assuming these are the correct IDs
                    user_type: this.form.roles.map(id => id), // Assuming these are the correct IDs
                    deadline: new Date(this.form.deadline).toISOString(), // Ensure the deadline is in the correct format
                    description: this.form.description
                };

                await api.updateDocument(this.document_id, updatedDocument);
                this.isEditing = false;
                this.fetchDocument(); // Refresh the document details after update
            } catch (error) {
                this.error = error.message || "Failed to save document";
            }
        },

        async archiveDocument() {
            try {
                if (!this.document_id) throw new Error("Document ID is undefined");
                await api.toggleDocumentStatus(this.document_id);
                await SweetAlertService.showSuccess("Success", "Document status updated to Archived");
                this.$router.go(0);
            } catch (error) {
                console.error(error);
                this.error = error.message || "Failed to archive document";
            }
        },

        async unarchiveDocument() {
            try {
                if (!this.document_id) throw new Error("Document ID is undefined");
                await api.toggleDocumentStatus(this.document_id);
                await SweetAlertService.showSuccess("Success", "Document status updated to Active");
                this.$router.go(0);
            } catch (error) {
                console.error(error);
                this.error = error.message || "Failed to unarchive document";
            }
        },
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>