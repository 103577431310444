<template>
  <div>
  
    <div class="container mx-auto p-10 border border-t-0">
      <h2 class="text-2xl font-bold mb-6">Record pre-donation vitals</h2>

      <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="weight"
            >Weight (Min: 50) <span class="text-red-500">*</span></label
          >
          <input
            id="weight"
            type="number"
            v-model="formData.weight"
            :class="{
              'border-red-500 bg-red-100': invalidFields.weight,
              'border-gray-300': !invalidFields.weight,
            }"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Weight"
            @blur="validateWeight"
            required
          />
        </div>
 
        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="hb_levels"
            >HB levels (Range: 12 - 18) <span class="text-red-500">*</span></label
          >
          <input
            id="hb_levels" 
            type="number"
            v-model="formData.hb_levels"
            :class="{
              'border-red-500 bg-red-100': invalidFields.hb_levels,
              'border-gray-300': !invalidFields.hb_levels,
            }"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="HB levels"
             @blur="validateHb"
            required
          />
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="temperature"
            >Temperature (Range: 35.5 - 37.2)<span class="text-red-500">*</span></label
          >
          <input
            id="temperature"
            type="number"
            v-model="formData.temperature"
            step="0.1"
            :class="{
              'border-red-500  bg-red-100': invalidFields.temperature,
              'border-gray-300': !invalidFields.temperature,
            }"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Temperature"
            @blur="validateTemperature"
            required
          />
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium" for="pulse"
            >Pulse (Range: 60 - 100) <span class="text-red-500">*</span></label
          >
          <input
            id="pulse"
            type="number"
            v-model="formData.pulse"
            :class="{
              'border-red-500  bg-red-100': invalidFields.pulse,
              'border-gray-300': !invalidFields.pulse,
            }"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Pulse"
            @blur="validatePulse"
            required
          />
        </div>

        <div>
          <label
            class="block mb-2 text-gray-700 font-medium"
            for="blood_pressure"
            >Blood Pressure (Range: 100-140 / 60-90) <span class="text-red-500">*</span></label
          >
          <input
            id="blood_pressure"
            type="text"
            v-model="formData.blood_pressure"
             :class="{'border-red-500  bg-red-100': invalidFields.blood_pressure, 
             'border-gray-300': !invalidFields.blood_pressure}"
            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            placeholder="Blood Pressure (e.g. 120/80)"
             @blur="validateBloodPressure"
            required
          />
        </div>

        <div>
          <label class="block mb-1" for="regularDonor"
            >Arm Inspection <span class="text-red-500">*</span></label
          >
          <div class="relative">
            <select
              id="regularDonor"
              :class="{
                'border-red-500 bg-red-100': invalidFields.arm_inspection,
                'border-gray-300': !invalidFields.arm_inspection
            }"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              v-model="formData.arm_inspection"
               @change="validateArmInspection"
              required
            >
              <option value="" disabled selected>Select</option>
              <option value="pass">pass</option>
              <option value="fail">fail</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
      </form>

      <div class="mt-8 flex justify-between">
        <button
          class="px-4 py-2 border rounded text-orange-500 border-orange-500"
          @click="clearVitalsForm"
        >
          Clear
        </button>
        <div class="flex items-center space-x-2 mt-4 sm:mt-0">
          <CustomButton
            label="Save and continue"
            bg-class="bg-custom-yellow px-8 py-3"
            @click="validateAndSave('continue')"
          />
          <CustomButton
            label="Save and defer"
            bg-class="bg-custom-red px-8 py-3"
            @click="validateAndSave('defer')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";

export default {
  name: "DonorVitalsForm",
  components: {
    CustomButton,
  },
  props: {
    donorId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // console.log('Fetching>>>>> Donor ID:', this.$route.params.donor_id);
  },
  data() {
    return {
      formData: {
        weight: "",
        hb_levels: "",
        temperature: "",
        pulse: "",
        blood_pressure: "",
        arm_inspection: "",
      },
      invalidFields: {
        weight: false,
        temperature: false,
        pulse: false,
        blood_pressure: false,
        arm_inspection: false,
        hb_levels: false,
      },
    };
  },

  created() {
    this.fetchDonorDetails();
  },

  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    async fetchDonorDetails() {
      try {
        const donorId = this.donorId;
        const response = await api.getDonor(donorId);
        this.donor = response.data || response;
        // console.log("Fetched donor details:", this.donor);
      } catch (error) {
        this.error = error.message || "Failed to fetch donor details";
        console.error("Error fetching donor details:", this.error);
      }
    },

    validateForm() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (value === '') {
          emptyFields.push(key.replace(/_/g, ' '));
        }
      }
      return emptyFields;
    },

    async validateWeight() {
      const weight = this.formData.weight;

      // Mark the field as invalid if weight is below the threshold
      if (weight && weight < 50) {
        this.invalidFields.weight = true;

        // Show confirmation dialog
        await SweetAlertService.showConfirmation(
          "Weight Below Minimum",
          "The entered weight is below 50kg. Are you sure this is correct?"
        );
      } else {
        // Reset the invalid state if weight is valid
        this.invalidFields.weight = false;
      }
    },

    async validateHb() {
  const hb_levels = this.formData.hb_levels;


  // Validate the Hb levels
  if (hb_levels && (hb_levels < 12 || hb_levels > 18)) {
    this.invalidFields.hb_levels = true;

    // Show a confirmation dialog
    await SweetAlertService.showConfirmation(
      "HB Levels Out of Range",
      `The entered Hb level  is outside the normal range (12 - 18). Are you sure this is correct?`
    );
  } else {
    // Reset the invalid state if Hb levels are valid
    this.invalidFields.hb_levels = false;
  }
},


    async validateArmInspection() {
    const inspection = this.formData.arm_inspection;

    // Reset invalid state for empty or valid "pass" value
    if (!inspection || inspection === 'pass') {
        this.invalidFields.arm_inspection = false;
        return;
    }

    // Handle "fail" selection
    if (inspection === 'fail') {
        this.invalidFields.arm_inspection = true;

         await SweetAlertService.showConfirmation(
            'Arm Inspection Failed',
            'You selected "fail" for arm inspection. Are you sure this is correct?'
        );
    } else {
        this.invalidFields.arm_inspection = false;
    }
},


    async validateTemperature() {
      const temp = this.formData.temperature;

      if (temp && (temp < 35.5 || temp > 37.2)) {
        this.invalidFields.temperature = true;

        await SweetAlertService.showConfirmation(
          'Temperature Out of Range',
          'The entered temperature is outside the normal range (35.5°C - 37.2°C). Are you sure this is correct?'
        );
      } else {
        this.invalidFields.temperature = false;
      }
    },

    async validatePulse() {
      const pulse = this.formData.pulse;
      if (pulse && (pulse < 60 || pulse > 100)) {
        this.invalidFields.pulse = true;
         await SweetAlertService.showConfirmation(
          'Pulse Out of Range',
          'The entered pulse is outside the normal range (60-100 bpm). Are you sure this is correct?'
        );
        
      } else {
        this.invalidFields.pulse = false;
      }
    },

    async validateBloodPressure() {
      const bp = this.formData.blood_pressure;

      if (!bp) {
        this.invalidFields.blood_pressure = false;
        return;
    }
      const validFormat = /^\d{2,3}\/\d{2,3}$/.test(bp);
      if (!validFormat) {
        SweetAlertService.showError(
          'Invalid Blood Pressure',
          'Please enter blood pressure in the correct format (e.g., 120/80).'
        );
        this.invalidFields.blood_pressure = true;
        return;
      }

      const [systolic, diastolic] = bp.split('/').map(Number);
      if (
        systolic < 100 || systolic > 140 ||
        diastolic < 60 || diastolic > 90
      ) {

        this.invalidFields.blood_pressure = true;
        await SweetAlertService.showConfirmation(
          'Blood Pressure Out of Range',
          'The entered blood pressure is outside the normal range (100-140/60-90). Are you sure this is correct?'
        );
      } else {
        this.invalidFields.blood_pressure = false;
      }
    },

    validateAndSave(action) {
      const emptyFields = this.checkEmptyFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
      } else {
        if (action === "continue") {
          this.handleSaveAndContinue();
        } else {
          this.handleSaveAndDefer();
        }
      }
    },

    checkEmptyFields() {
      const emptyFields = [];
      for (const [key, value] of Object.entries(this.formData)) {
        if (!value.toString().trim()) {
          emptyFields.push(this.getFieldName(key));
        }
      }
      return emptyFields;
    },

    getFieldName(key) {
      const fieldNames = {
        weight: "Weight",
        hb_levels: "HB levels",
        temperature: "Temperature",
        pulse: "Pulse",
        blood_pressure: "Blood Pressure",
      };
      return fieldNames[key] || key;
    },

    async handleSaveAndContinue() {
      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {

        const bloodPressure = this.formData.blood_pressure;

        // Validate the format using a regular expression
        const validFormat = /^\d{2,3}\/\d{2,3}$/.test(bloodPressure);
        if (!validFormat) {
          SweetAlertService.showError('Invalid Input', 'Please enter blood pressure in the correct format (e.g., 120/80).');
          return;
        }

        // Extract systolic and diastolic values
        const [systolic, diastolic] = bloodPressure.split('/').map(Number);

        // Combine formData with additional fields like added_by
        const payload = {
          ...this.formData,
          systolic: systolic,  // Send systolic value separately
          diastolic: diastolic, // Send diastolic value separately
          added_by: this.user().user_id,
        };

        const response = await api.createDonorVital(this.donorId, payload);
        console.log('Donor vitals saved successfully:', response);
        await SweetAlertService.showSuccess('Success!', 'Donor vitals saved successfully!.');
        this.$emit('move-to-next-step', this.donorId);
      } catch (error) {
        console.error('Error submitting the form:', error);
        SweetAlertService.showError('Error', 'Something went wrong while saving donor vitals.');
      }
    },


    async handleSaveAndDefer() {
      const emptyFields = this.validateForm();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        const bloodPressure = this.formData.blood_pressure;

        const validFormat = /^\d{2,3}\/\d{2,3}$/.test(bloodPressure);
        if (!validFormat) {
          SweetAlertService.showError('Invalid Input', 'Please enter blood pressure in the correct format (e.g., 120/80).');
          return;
        }

        const [systolic, diastolic] = bloodPressure.split('/').map(Number);

        const payload = {
          ...this.formData,
          systolic: systolic, 
          diastolic: diastolic, 
          added_by: this.user().user_id,
        };

        const response = await api.createDonorVital(this.donorId, payload);
        console.log('Donor vitals saved successfully:', response);

        // Extract donor_id from response
        const newDonorId = this.donorId;
        if (!newDonorId) {
          throw new Error('Missing donor_id in response');
        }

        await SweetAlertService.showSuccess('Success!', 'Donor vitals saved. Continue to defferal form.');

        // Use the extracted donor_id for redirection
        this.$router.push({
          name: 'NewDonorDeferralForm',
          params: { donor_id: newDonorId }
        });

      } catch (error) {
        console.error('Error submitting the form:', error);
        SweetAlertService.showError('Error', 'Something went wrong while saving donor vitals and initiating deferral.');
      }
    },

    handleBack() {
      this.$emit('move-to-previous-step');
    },

    clearVitalsForm() {
      this.formData = {
        weight: "",
        hb_levels: "",
        temperature: "",
        pulse: "",
        blood_pressure: "",
        arm_inspection: "",
      };
    },

    redirectToRecordDonationForm() {
      this.$router.push({
        name: "NewRecordDonationForm",
        params: { donorId: this.$route.params.donor_id },
      });
    },

    redirectDeferralForm() {
      this.$router.push({
        name: "NewDonorDeferralForm",
        params: { donorId: this.$route.params.donor_id },
      });
    },
  },
};
</script>

<style scoped>
.border-red-500 {
  border-color: #f87171; /* Tailwind default red */
}
.bg-red-100 {
  background-color: #fee2e2; /* Tailwind default light red */
}
</style>
