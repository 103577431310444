<template>
  <div>
    <AlertLayout title="Reminders!" message="Kindly fill in the details as required below." type="warning" />
    <div class="mx-auto p-10 border border-t-0">
      <h2 class="text-lg font-bold mb-6">Add New Facility</h2>
      <form class="grid grid-cols-1 md:grid-cols-2 gap-5">

        <div>
          <label class="block mb-1">Facility Name <span class="text-red-500">*</span></label>
          <input type="text" v-model="facilityData.name"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter facility name" required>
        </div>

        <div>
          <label class="block mb-1">Email <span class="text-red-500">*</span></label>
          <input type="email" v-model="facilityData.email"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter email" required>
        </div>

        <div>
          <label class="block mb-1">Facility Code <span class="text-red-500">*</span></label>
          <input type="text" v-model="facilityData.facility_code"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter facility code" required>
        </div>

        <div>
          <label class="block mb-1">Facility Type <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="terms_agreed" v-model="facilityData.facility_type"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select facility type</option>
              <option value="Private">Private</option>
              <option value="Public">Public</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1">Contact Person Name <span class="text-red-500">*</span></label>
          <input type="text" v-model="facilityData.contact_person_name"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter contact person's name" required>
        </div>

        <div>
          <label class="block mb-1">Contact Person Phone <span class="text-red-500">*</span></label>
          <input type="text" v-model="facilityData.contact_person_phone"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter contact person's phone" required>
        </div>

        <div>
          <label class="block mb-1">Country <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="country_id" v-model="facilityData.country_id"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select country</option>
              <option v-for="region in regions" :key="region.country_id" :value="region.country_id">
                {{ region.name }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1">County <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="county_id" v-model="facilityData.county_id"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select county</option>
              <option v-for="county in filteredCounties" :key="county.county_id" :value="county.county_id">
                {{ county.name }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
        </div>


        <div>
          <label class="block mb-1">Sub-county <span class="text-red-500">*</span></label>
          <input type="text" v-model="facilityData.second_level_devision"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter second level division" required>
        </div>

        <div>
          <label class="block mb-1">Address <span class="text-red-500">*</span></label>
          <input type="text" v-model="facilityData.third_level_devision"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter third level division" required>
        </div>

        <div>
          <label class="block mb-1">Longitude</label>
          <input type="text" v-model="facilityData.longitude"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter longitude">
        </div>

        <div>
          <label class="block mb-1">Latitude</label>
          <input type="text" v-model="facilityData.latitude"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter latitude">
        </div>

        <div>
          <label class="block mb-1">Account Status <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="terms_agreed" v-model="facilityData.account_status"
              class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled>Select status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1">Upload Facility Logo</label>
          <input  type="file" accept=".svg" @change="handleFileUpload"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required />
        </div>
      </form>
      
      <div class="flex items-center justify-between mt-8">
       
           <button  @click="handleBackToFacilities"  v-if="!officer_id"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Back</button>
        <div class="flex items-center ml-auto">
             <button @click="resetForm"  v-if="!facility_id"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500" >Cancel</button>
          <CustomButton v-if="!facility_id" label="Add Admin" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleAdminModal" />
          <CustomButton v-if="facility_id" label="Update" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleUpdateFacility" />
        </div>
      </div>
    </div>
    <div v-if="showAdminModal"
      class="fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-hidden h-full w-full flex items-center justify-center text-base">
      <div class="bg-white rounded-lg shadow-xl w-8/12 overflow-hidden">
        <div class="flex items-center justify-between p-4 border-b">
          <h2 class="text-xl text-gray-700 font-semibold">Create Admin</h2>
          <button @click="closeAdminModal" class="text-gray-500 hover:text-gray-700" aria-label="Close">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
              </path>
            </svg>
          </button>
        </div>
        <form class="grid grid-cols-1 md:grid-cols-3 gap-5 p-6">
          <div>
            <label class="block mb-1">First name <span class="text-red-500">*</span></label>
            <input type="text" v-model="userData.first_name"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter first name" required>
          </div>

          <div>
            <label class="block mb-1">Middle name</label>
            <input type="text" v-model="userData.middle_name"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter middle name" required>
          </div>

          <div>
            <label class="block mb-1">Last name <span class="text-red-500">*</span></label>
            <input type="text" v-model="userData.last_name"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter last name" required>
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium">Gender <span class="text-red-500">*</span></label>
            <div class="relative">
              <select id="gender" v-model="userData.gender"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled class="text-gray-400">Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1">Date of Birth</label>
            <input type="date" v-model="userData.dob"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300" required>
          </div>

          <div>
            <label class="block mb-1">ID Number</label>
            <input type="number" v-model="userData.id_number"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter ID number or passport" required>
          </div>

          <div>
            <label class="block mb-1">Phone number <span class="text-red-500">*</span></label>
            <input type="text" v-model="userData.phone_number"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter phone number" required>
          </div>

          <div>
            <label class="block mb-1">Email <span class="text-red-500">*</span></label>
            <input type="email" v-model="userData.email"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
              placeholder="Enter ID number or passport" required>
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium" for="blood_group">Select blood group</label>
            <div class="relative">
              <select id="blood_group_id" v-model="userData.blood_group_id"
                class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled>Select blood group</option>
                <option v-for="group in bloodGroups" :key="group.blood_group_id" :value="group.blood_group_id">
                  {{ group.description }}
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium">select contact mode<span
                class="text-red-500">*</span></label>
            <div class="relative">
              <select id="wing" v-model="userData.contact_mode"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled class="text-gray-400">select contact mode</option>
                <option value="both">Both</option>
                <option value="phone">Phone</option>
                <option value="email">Email</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-2 text-gray-700 font-medium">select notification type</label>
            <div class="relative">
              <select id="wing" v-model="userData.notification_type"
                class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                required>
                <option value="" disabled class="text-gray-400">select notification type</option>
                <option value="All Reports">All Reports</option>
                <option value="New Requisition">New Requisition</option>
                <option value="Start transfussion">Start transfussion</option>
                <option value="Transfusioin reaction">Transfusion reaction</option>
                <option value="New Share request">New Share request</option>
                <option value="Weekly Reports">Weekly Reports</option>
                <option value="Monthly report">Monthly report</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <label class="block mb-1">Select Role <span class="text-red-500">*</span></label>
            <select id="role" v-model="userData.role_id"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              disabled>
              <option value="" disabled class="text-gray-400">Select user role</option>
              <option v-for="role in roles" :key="role.role_id" :value="role.role_id">
                {{ role.name }}
              </option>
            </select>
          </div>

        </form>
        <div class="flex items-center justify-end mt-4">
          <CustomButton v-if="!facility_id" label="Save" bg-class="bg-custom-yellow px-8 py-3 m-6"
            @click="handleSubmitNewFacility" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';
import AWS from 'aws-sdk'; // Import AWS SDK



export default {
  name: "NewFacilityForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      showAdminModal: false,
      bloodGroups: [],
      roles: [],
      selectedFile: null,
      departments: [],
      facilityData: {
        name: '',
        email: '',
        facility_code: '',
        facility_type: '',
        contact_person_name: '',
        contact_person_phone: '',
        country_id: '',
        county_id: '',
        second_level_devision: '',
        third_level_devision: '',
        longitude: '',
        latitude: '',
        account_status: '',
        logo: '',
      },
      userData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        dob: '',
        contact_mode: '',
        email: '',
        phone_number: '',
        id_number: '',
        blood_group_id: '',
        notification_type: '',
        role_id: '',
      },
      status: 'active',
      error: null,
      regions: [],
      facility_id: '',
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.facility_id = this.$route.params.facilityId;
  },
  created() {
    this.fetchRegions();
    this.fetchBloodGroups();
    this.fetchRoles();
    const facilityId = this.$route.params.facilityId;
    if (facilityId) {
      this.fetchFacilityData(facilityId);
    }
  },
  computed: {
    filteredCounties() {
      const selectedCountry = this.regions.find(region => region.country_id === this.facilityData.country_id);
      return selectedCountry ? selectedCountry.counties : [];
    }
  },
  methods: {
    handleBackToFacilities() {
      this.$router.push({ name: 'UserManagementPage' });
    },

    handleAdminModal() {
      this.showAdminModal = true
    },

    closeAdminModal() {
      this.showAdminModal = false
    },

    validateFacilityUserFields() {
      const emptyFields = [];
      if (!this.facilityData.name) emptyFields.push('Facility name');
      if (!this.facilityData.email) emptyFields.push('First email');
      if (!this.facilityData.facility_type) emptyFields.push('Facility Type');
      if (!this.facilityData.facility_code) emptyFields.push('Facility Code');
      if (!this.facilityData.contact_person_name) emptyFields.push('Contact person name');
      if (!this.facilityData.contact_person_phone) emptyFields.push('Contact person phone');
      if (!this.facilityData.county_id) emptyFields.push('County');
      if (!this.facilityData.second_level_devision) emptyFields.push('Facility Sub-county');
      if (!this.facilityData.third_level_devision) emptyFields.push('Facility Address');
      if (!this.facilityData.account_status) emptyFields.push('Facility Account status');
      if (!this.userData.first_name) emptyFields.push('Admin First name');
      if (!this.userData.last_name) emptyFields.push('Admin Last name');
      if (!this.userData.gender) emptyFields.push('Admin Gender');
      if (!this.userData.email) emptyFields.push('Admin Email');
      if (!this.userData.phone_number) emptyFields.push('Admin Phone number');
      if (!this.userData.contact_mode) emptyFields.push('Admin Contact Mode');
      if (!this.userData.role_id) emptyFields.push('Admin Role');

      return emptyFields;
    },

    validateFacilitUpdateFields() {
      const emptyFields = [];
      if (!this.facilityData.name) emptyFields.push('Facility name');
      if (!this.facilityData.email) emptyFields.push('First email');
      if (!this.facilityData.facility_type) emptyFields.push('Facility Type');
      if (!this.facilityData.facility_code) emptyFields.push('Facility Code');
      if (!this.facilityData.contact_person_name) emptyFields.push('Contact person name');
      if (!this.facilityData.contact_person_phone) emptyFields.push('Contact person phone');
      if (!this.facilityData.country_id) emptyFields.push('Country');
      if (!this.facilityData.county_id) emptyFields.push('County');
      if (!this.facilityData.second_level_devision) emptyFields.push('Facility Sub-county');
      if (!this.facilityData.third_level_devision) emptyFields.push('Facility Address');
      if (!this.facilityData.account_status) emptyFields.push('Facility Account status');

      return emptyFields;
    },
 
    

    async handleUpdateFacility() {
      const data = { ...this.facilityData };

      const emptyFields = this.validateFacilitUpdateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.updateFacility(this.facility_id, data);
        await SweetAlertService.showSuccess('Success!', 'Facility updated successfully!.');
        this.$router.push({ name: 'UserManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to update Facility';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', 'Error updating facility.')
      }
    },

    async fetchRegions() {
      try {
        const response = await api.getRegions();
        this.regions = response.regions || [];
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    },

    populateFormData(facilityData) {
      this.facilityData.name = facilityData.name || '';
      this.facilityData.email = facilityData.email || '';
      this.facilityData.facility_code = facilityData.facility_code || '';
      this.facilityData.facility_type = facilityData.facility_type || '';
      this.facilityData.contact_person_name = facilityData.contact_person_name || '';
      this.facilityData.contact_person_phone = facilityData.contact_person_phone || '';
      this.facilityData.country_id = facilityData.country_id || '';
      this.facilityData.county_id = facilityData.county_id || '';
      this.facilityData.second_level_devision = facilityData.second_level_devision || '';
      this.facilityData.third_level_devision = facilityData.third_level_devision || '';
      this.facilityData.longitude = facilityData.longitude || '';
      this.facilityData.latitude = facilityData.latitude || '';
      this.facilityData.account_status = facilityData.account_status || '';
    },

     resetForm() {
      this.facilityData= {
        name: '',
        email: '',
        facility_code: '',
        facility_type: '',
        contact_person_name: '',
        contact_person_phone: '',
        country_id: '',
        county_id: '',
        second_level_devision: '',
        third_level_devision: '',
        longitude: '',
        latitude: '',
        account_status: '',
      };
      this.userData= {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        dob: '',
        contact_mode: '',
        email: '',
        phone_number: '',
        id_number: '',
        blood_group_id: '',
        notification_type: '',
        role_id: '',
      };
    },

    async fetchFacilityData(facilityId) {
      try {
        const response = await api.getFacility(facilityId);
        const facilityData = response.data || response;
        this.populateFormData(facilityData.facility);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        this.error = error.message || 'Failed to fetch facility data';
      }
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    handleFileUpload(event) {
            // Capture the selected file
            const file = event.target.files[0];
            if (file) {
              if (!file.type.includes('svg')) {
            alert('Only SVG files are allowed.');
            return;
        }
                this.selectedFile = file;
            }
        },

        async uploadFile() {
            if (!this.selectedFile) {
                alert("No file selected.");
                return;
            }
            // Configure AWS SDK
            const s3 = new AWS.S3({
                endpoint: 'lon1.digitaloceanspaces.com', // Replace with your Spaces endpoint
                signatureVersion: 'v4',
                accessKeyId: 'DO00PK2KLQ4HUBKTB6R3',
                secretAccessKey: 'L3yOKmH3Dqfpc3tVZv4Mz5O+jd1bXTobKS//zmY72Rk',
                region: 'lon1',
            });

            const params = {
                Bucket: 'jiji-bucket', // Replace with your Space name
                Key: this.selectedFile.name,
                Body: this.selectedFile,
                ACL: 'public-read', // Make the file public if needed
                ContentType: this.selectedFile.type,
            };

            try {
                const result = await s3.upload(params).promise();
                console.log(result);

               if(result) {
                return result.Location;
               }
                this.$toast.success('File uploaded successfully.');
            } catch (error) {
                this.$toast.error('Unable to upload file, please check your internet connection.');
                return '';
            }
        },

        async handleSubmitNewFacility() {
      const emptyFields = this.validateFacilityUserFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      // console.log(payload)

      try {
        if (this.selectedFile) {
                    const fileUrl = await this.uploadFile();
                    if (!fileUrl) {
                        this.isSubmitting = false;
                        return;
                    }
                    this.facilityData.logo = fileUrl;
                }

        const payload = {
        facility: { ...this.facilityData },
        user: { ...this.userData },
        };

        // Send the combined payload to the API
        await api.createFacilityWithAdmin(payload);
        await SweetAlertService.showSuccess('Success', 'Facility and User saved successfully!.');
        this.$router.push({ name: 'UserManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to create Facility and User';
        console.error('error:', this.error);
        SweetAlertService.showError('Error', error.message || 'Error saving facility and user.');
      }
    },

    async fetchRoles() {
      try {
        const response = await api.getRoles();
        this.roles = response.data || response;
        const adminRole = this.roles.find(role => role.name === "Admin");
        if (adminRole) {
          this.userData.role_id = adminRole.role_id;
        }
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
