<template>
    <div>
        <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div> 
    <template v-else>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
            <table class="w-full text-left rtl:text-right text-gray-700">
                <thead>
                    <tr class="bg-gray-100">
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input id="checkbox-all-search" type="checkbox"
                                    class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Serial no.
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Donor Name
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Blood Group
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Volume
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Type of Donation
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Donation Process
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Donation Date
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Donated To
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            <div class="flex items-center">
                                Status
                                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                                    </svg></a>
                            </div>
                        </th>
                        <th scope="col" class="px-6">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(donation, index) in filteredBloodDonations" :key="donation.donation_id" class="border-b">
                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4">
                                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <td class="px-6 py-2">{{ donation.pint_serial }}</td>
                        <td class="px-6 py-2">{{ donation.donor.user.first_name }} {{ donation.donor.user.last_name }}
                        </td>
                        <td class="px-6 py-2">{{ donation.donor?.user?.blood_group_id?.description ?? 'Unknown' }}</td>
                        <td class="px-6 py-2">{{ donation.quantity ?? '0' }} ml</td>
                        <td class="px-6 py-2">{{ donation.donation_type }} </td>
                        <td class="px-6 py-2">{{ donation.technology }} </td>
                        <td class="px-6 py-2">{{ donation.date_added }} </td>
                        <td class="px-6 py-2">{{ donation.donor.patient ?? 'N/A' }} </td>
                        <td class="px-6 py-2">
                            
                            <span class="px-2 py-1 rounded" :class="{
                                'text-red-500 bg-red-100': ['Unscreened', 'Disposed', 'Pending Disposal'].includes(getDonationStatus(donation)),
                                'text-green-500 bg-green-100': ['Pending Approval', 'Ongoing Screening'].includes(getDonationStatus(donation)),
                                'text-orange-500 bg-orange-100': ['Approved', 'Split', 'Requote', 'Leuco-reduction'].includes(getDonationStatus(donation))
                            }">
                                {{ getDonationStatus(donation) }}
                            </span>
                        </td>
                        <td class="flex items-center px-6 py-2">
                            <template
                                v-if="getDonationStatus(donation) === 'Unscreened' || getDonationStatus(donation) === 'Ongoing Screening'">
                                <CustomButton label="Screen" @click="handleScreen(donation.blood_donation_id)"
                                    bg-class="bg-custom-yellow text-sm py-2" />
                            </template>
                            <template v-if="getDonationStatus(donation) === 'Pending Approval'">
                                <CustomButton label="Approve" @click="handleApprove(donation.blood_donation_id)"
                                    bg-class="bg-custom-yellow text-sm py-2" />
                            </template>
                            <template v-if="getDonationStatus(donation) === 'Pending Disposal'">
                                <CustomButton label="Dispose" @click="handleDispose(donation.blood_donation_id)"
                                    bg-class="bg-red-400 text-sm py-2" />
                            </template>
                            <template
                                v-if="getDonationStatus(donation) === 'Approved' || getDonationStatus(donation) === 'Disposed' || getDonationStatus(donation) === 'Split' || getDonationStatus(donation) === 'Requote' || getDonationStatus(donation) === 'Leuco-reduction'">
                                <CustomButton label="View Details"
                                    @click="handleViewDetails(donation.blood_donation_id)"
                                    :disabled="isLoading[donation.blood_donation_id]" 
                                    :bg-class="isLoading[donation.blood_donation_id] ? 'bg-gray-300 cursor-not-allowed' : 'bg-custom-yellow text-sm py-1'"   />
                            </template>
                            <CustomButton label="Donation sticker" @click="handlePrintSticker(donation.blood_donation_id)"
                            :disabled="isLoading[donation.blood_donation_id]" 
                            :bg-class="isLoading[donation.blood_donation_id] ? 'bg-gray-300 cursor-not-allowed' : 'bg-custom-blue text-sm py-1 ml-2'" />
                            <div v-if="isLoading[donation.blood_donation_id]" class="ml-2 animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-gray-900"></div>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex justify-center gap-4 mt-4">
            <button @click="previousPage" :disabled="currentPage === 1"
                class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button">
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m15 19-7-7 7-7" />
                </svg>
                Previous
            </button>

            <div class="flex items-center gap-2">
                <button v-for="page in visiblePages" :key="page" @click="goToPage(page)" :class="{
                    'bg-custom-blue text-white': page === currentPage,
                    'text-custom-blue': page !== currentPage
                }" class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button">
                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        {{ page }}
                    </span>
                </button>
            </div> 

            <button @click="nextPage" :disabled="currentPage === totalPages"
                class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button">
                Next
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m9 5 7 7-7 7" />
                </svg>
            </button>
        </div>
    </template>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue';
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';
import hospitalLogo from '@/assets/images/Jiji-Health-Blue-Logo.png';

export default {
    name: 'BloodUnitsTab',
    components: {
        CustomButton,
    },

    props: {
        searchQuery: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            currentPage: 1,
            totalPages: 0,
            perPage: 10,
            loading: false,
            donations: [],
            isLoading: {},
            error: null,
            filters: {
                bloodGroup: "",
                dateRegistered: '',
                donationProcess: '',
                status: '',
            },
        }
    },

    created() {
        this.getBloodUnitDonations();
    },

    computed: {
        visiblePages() {
            return this.calculatePageRange();
        },
        filteredBloodDonations() {
            const search = this.searchQuery.toLowerCase();

            return this.donations.filter(donation => {
                const firstName = donation.donor?.user?.first_name || '';
                const lastName = donation.donor?.user?.last_name || '';
                const bloodGroupDescription = donation.donor?.user?.blood_group_id?.description || '';
                const quantity = donation.quantity?.toString() || '';
                const donationType = donation.donation_type || '';
                const technology = donation.technology || '';
                const dateAdded = donation.date_added || '';
                const origin = donation.origin || '';
                const pintSerial = donation.pint_serial || '';
                const donationStatus = this.getDonationStatus(donation) || '';

                // Validate and handle the dateAdded
                const validDateAdded = dateAdded && !isNaN(new Date(dateAdded).getTime()) ? new Date(dateAdded).toISOString() : null;

                // Check search query match (case-insensitive)
                const matchesSearch =
                    firstName.toLowerCase().includes(search) ||
                    lastName.toLowerCase().includes(search) ||
                    bloodGroupDescription.toLowerCase().includes(search) ||
                    quantity.toLowerCase().includes(search) ||
                    donationType.toLowerCase().includes(search) ||
                    technology.toLowerCase().includes(search) ||
                    (validDateAdded && validDateAdded.toLowerCase().includes(search)) ||
                    origin.toLowerCase().includes(search) ||
                    pintSerial.toLowerCase().includes(search) ||
                    donationStatus.toLowerCase().includes(search);

                // Check dropdown filters (case-insensitive)
                const matchesBloodGroup =
                    !this.filters.bloodGroup ||
                    bloodGroupDescription.toLowerCase() === this.filters.bloodGroup.toLowerCase();
                const matchesDateRegistered =
                    !this.filters.dateRegistered || dateAdded.startsWith(this.filters.dateRegistered);
                const matchesDonationProcess =
                    !this.filters.donationProcess || technology.toLowerCase() === this.filters.donationProcess.toLowerCase();
                const matchesStatus =
                    !this.filters.status || donationStatus.toLowerCase() === this.filters.status.toLowerCase();

                return matchesSearch && matchesBloodGroup && matchesDateRegistered && matchesDonationProcess && matchesStatus;
            });
        }
    },

    watch: {
        filters: { deep: true, handler: 'getBloodUnitDonations' },
        searchQuery: 'getBloodUnitDonations'
    },

    methods: {

        applyFilters(filters) {
            this.filters = { ...this.filters, ...filters };
            this.currentPage = 1;
        },

        resetFilters() {
            this.filters = {
                bloodGroup: "",
                dateRegistered: '',
                donationProcess: '',
                status: "",
            };
            this.currentPage = 1;
        },

        async handleScreen(donationId) {
            this.isLoading[donationId] = true;
            try {
                await new Promise(resolve => setTimeout(resolve, 500));
            if (donationId) {
                this.$router.push({
                    name: 'UnitScreeningForm',
                    params: { blood_donation_id: donationId }
                });
            } else {
                console.error('Invalid donationId');
            }
        } catch (error) {
                console.error(error);
                } finally {
                this.isLoading[donationId] = false; // Reset loading state
                }
        },


        async handleViewDetails(donationId) {
            this.isLoading[donationId] = true;
            try {
                await new Promise(resolve => setTimeout(resolve, 500));
                this.$router.push({
                    name: 'UnitDetailsPage',
                    params: { blood_donation_id: donationId }
                }); 
                } catch (error) {
                console.error(error);
                } finally {
                this.isLoading[donationId] = false; // Reset loading state
                }
            },

        async handleApprove(donationId) {
            try {
                await api.confirmScreening(donationId);
                await SweetAlertService.showSuccess('Success!', 'Blood Unit approved successfully!');
                this.$router.go(0);
            } catch (error) {
                this.error = error.message || 'Failed to confirm screening';
            }
        },

        async handleDispose(donationId) {
            try {
                await api.disposeScreening(donationId);
                await SweetAlertService.showSuccess('Success!', 'Blood Unit disposed successfully!');
                this.$router.go(0);
            } catch (error) {
                this.error = error.message || 'Failed to dispose screening';
            }
        },

        async getBloodUnitDonations() {
            this.loading = true;
            try {
                const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
                const response = await api.getBloodDonations(queryParams);
                this.donations = response.data || response;
                this.currentPage = response.current_page;
                this.totalPages = response.total_pages;
                console.log('data:', this.donations);
            } catch (error) {
                this.error = error.message || 'Failed to fetch donations';
            } finally {
                this.loading = false;
            }
        },

        calculatePageRange() {
            const maxPagesToShow = 5;
            let startPage = Math.max(1, this.currentPage - Math.floor(maxPagesToShow / 2));
            let endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);

            // Adjust start page if we're near the end
            if (endPage - startPage + 1 < maxPagesToShow) {
                startPage = Math.max(1, endPage - maxPagesToShow + 1);
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
            );
        },

        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
                this.getBloodUnitDonations();
            }
        },
        async previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                await this.getBloodUnitDonations();
            }
        },
        async nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                await this.getBloodUnitDonations();
            }
        },

        calculateDaysRemaining(expiryDate) {
            const today = new Date();
            const expiry = new Date(expiryDate);
            const timeDiff = expiry - today;
            return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        },

        allTestsConfirmed(donation) {
            const tests = [
                donation.screening_test?.hepatitis_b,
                donation.screening_test?.hepatitis_c,
                donation.screening_test?.hiv,
                donation.screening_test?.vdrl,
                donation.screening_test?.malaria,
                donation.screening_test?.blood_clot
            ];

            return tests.every(test => test !== null && test !== '');
        },

       

        checkForPendingDisposal(donation) {
            const tests = {
                hepatitis_b: donation.screening_test?.hepatitis_b,
                hepatitis_c: donation.screening_test?.hepatitis_c,
                hiv: donation.screening_test?.hiv,
                vdrl: donation.screening_test?.vdrl,
                malaria: donation.screening_test?.malaria,
                blood_clot: donation.screening_test?.blood_clot
            };

            // Check for blood_clot and vdrl custom values
            if (tests.blood_clot === "Yes" || tests.vdrl === "Reactive") {
                return true;
            }

            // Check if any other test is positive
            const otherTestResults = [
                tests.hepatitis_b,
                tests.hepatitis_c,
                tests.hiv,
                tests.malaria,
            ];

            return otherTestResults.includes("Positive");
        },

        getDonationStatus(donation) {
            if (donation.screening_logs) {
                const logsWithConfirmedBy = donation.screening_logs.filter(log => log.confirmed_by?.user_id !== null);

                if (this.checkForPendingDisposal(donation) && logsWithConfirmedBy.length === 6 &&   this.allTestsConfirmed(donation) && donation.unit_product_id !== null && donation.blood_group_id !== null && donation.expiry_date !== null && donation.pint_serial !== null && donation.origin !== null && donation.approved_by === null) {
                    return 'Pending Disposal';
                }
                else if (logsWithConfirmedBy.length === 6 && donation.unit_product_id !== null && donation.blood_group_id && donation.expiry_date !== null && donation.pint_serial !== null && donation.origin !== null && this.allTestsConfirmed(donation) && donation.approved_by === null) {
                    return 'Pending Approval';
                } else {
                    return donation.status;
                }
            }
        },

        async handlePrintSticker(donationId) {
            this.isLoading[donationId] = true;
      const printWindow = document.createElement('iframe');
      printWindow.style.position = 'absolute';
      printWindow.style.width = '0';
      printWindow.style.height = '0';
      document.body.appendChild(printWindow);

      try {
        await new Promise(resolve => setTimeout(resolve, 500)); 

        const donation = this.donations.find(d => d.blood_donation_id === donationId);
    if (!donation) {
      throw new Error('Donation not found');
    }

    // Extract necessary details
    const donatedTo = donation.donor?.patient || 'N/A';
    const donationType = donation.donation_type || 'N/A';
    const bagNo = donation.pint_serial || 'N/A';
    const dateDonated = donation.created_at
      ? new Date(donation.created_at).toLocaleDateString()
      : 'N/A';

        // Prepare print content
        const printContent = `
        <div class="page-container">
          <div class="sticker-container">
            <div class="logo-container">
              <img src="${hospitalLogo}" alt="Hospital Logo" class="logo">
            </div>    
            <div class="donation-info">
                <div>
                <p>Donated To:<strong> ${donatedTo} </strong></p>
              </div>
              <div>
                <p>Donated Type:<strong> ${donationType} </strong></p>
              </div>
              <div>
                <p>Bag No:<strong> ${bagNo} </strong></p>
              </div>
              <div>
                <p>Donation Date:<strong>${dateDonated}</strong></p>
              </div>
              
            </div>
          </div>
          </div>
        `;

        // Prepare the full document for printing
        const printDocument = `
          <html>
          <head>
            <title>Print Preview</title>
            <style>
              body {
                font-family: Manjari, sans-serif;
                text-align: center;
                margin: 0;
                padding: 0;
              }
              .page-container {
                width: 100vw;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .sticker-container {
                margin: auto;
                width: 500px;  /* Fixed width for the content */
                padding: 20px;
                background-color: white;
              }
              .logo-container {
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
              }
              .logo {
                height: 100px;
              }
             
              .donation-info {
                margin-bottom: 10px;
              }
              .donation-info p {
                font-size: 20px;
              }
             
              @media print {
                @page {
                  size: A8;
                  margin: 0;
                }
                body {
                  margin: 0;
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                }
                .sticker-container {
                  width: auto;
                  margin: 0;
                  padding: 10px;
                }
              }
            </style>
          </head>
          <body>
            ${printContent}
          </body>
          </html>
        `;

        // Open the print document
        printWindow.contentDocument.open();
        printWindow.contentDocument.write(printDocument);
        printWindow.contentDocument.close();

        // Print when the document is loaded
        printWindow.contentWindow.onload = function () {
          printWindow.contentWindow.print();
        };
      } catch (error) {
        this.error = error.message || 'Failed to fetch donation';
      } finally {
        this.isLoading[donationId] = false
     }
    },
    },
}
</script>


<style lang="css" scoped></style>