<template>
  <div class="bg-cover bg-center bg-no-repeat bg-custom-login-image">
    <div class="flex items-center justify-center h-screen px-6 bg-gray-900/80">
      <div
        class="max-w-sm p-6 bg-custom-blue shadow-md w-[400px] h-[520px] top-[156px] left-[520px] rounded-[16px]"
      >
        <div class="flex items-center justify-center">
          <img
            class="object-cover w-[200px] h-[64px] top-[23px]"
            src="@/assets/images/damusasa_logo.svg"
            alt="Your avatar"
          />
        </div>
        <div class="flex justify-center font-thin text-white text-xs">
          <div>Welcome to damu sasa,</div>
        </div>
        <div class="flex justify-center font-thin text-white text-xs">
          <div>
            Advancing <span class="text-custom-yellow">Healthcare</span> Tech!
          </div>
        </div>

        <!-- Error Alert -->
        <transition name="fade">
          <div
            v-if="errorMessage"
            class="p-4 mb-1 mt-3 text-sm text-red-800 rounded-lg bg-orange-50 dark:text-red-400"
            role="alert"
          >
            <span class="font-medium">{{ errorMessage }}</span>
          </div>
        </transition>

        <form class="mt-8 px-3" @submit.prevent="login">
          <label class="block">
            <span class="text-sm text-white">Email or Phone</span>
            <input
              type="text"
              class="block w-full mt-1 px-3 py-2 dark:placeholder-gray-400 rounded-md"
              v-model="email"
              placeholder="johndoe@mail.com or 0712345678"
            />
          </label>

          <label class="block mt-4 relative">
            <span class="text-sm text-white">Password</span>
            <input
              ref="passwordField"
              :type="passwordVisible ? 'text' : 'password'"
              class="block w-full mt-1 px-3 py-2 dark:placeholder-gray-400 rounded-md"
              v-model="password"
              placeholder="your password"
            />
            <div
              class="absolute inset-y-4 py-8 right-0 pr-3 flex items-center text-sm leading-5"
            >
              <button
                type="button"
                id="togglePassword"
                class="text-gray-500 focus:outline-none focus:text-gray-600 hover:text-gray-600"
                @click="togglePasswordVisibility"
              >
                <svg
                  v-if="passwordVisible"
                  class="w-6 h-6 text-gray-800 dark:text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z"
                  />
                  <path
                    d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z"
                  />
                  <path
                    d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z"
                  />
                </svg>
                <svg
                  v-else
                  class="w-6 h-6 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-width="2"
                    d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                  />
                  <path
                    stroke="currentColor"
                    stroke-width="2"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </button>
            </div>
          </label>

          <div class="mt-6">
            <button
              type="submit"
              class="w-full font-semibold leading-relaxed px-4 py-2 text-md text-center text-white bg-custom-yellow rounded-md focus:outline-none hover:text-custom-blue"
            >
              Log in
            </button>
          </div>
          <div class="flex justify-center mt-4 text-white">
            <div>
              <a class="block text-sm hover:underline" href="#"
                >Forgot your password?</a
              >
            </div>
          </div>
          <div class="flex justify-center mt-4 text-white">
            <div>
              <a class="block text-sm hover:underline" @click="openTermsPopup"
                >Terms and Conditions</a
              >
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="showTermsPopup"
        class="fixed inset-0 bg-gray-600 bg-opacity-50 h-full w-full flex items-center justify-center"
      >
        <div class="relative bg-white p-8 rounded-lg shadow-xl max-w-4xl max-h-[90vh] overflow-y-auto w-full">

          <button type="button" class="absolute top-4 right-4 -mx-1.5 -my-1.5 p-1.5 inline-flex text-gray-800 items-center justify-center h-8 w-8" @click="closeTermsPopup" aria-label="Close">
          <span class="sr-only">Dismiss</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>

          <AlertLayout
            title="Reminders!"
            message="
        <ul>Kindly read the following terms and conditions carefully.</ul>
      "
            type="warning"
          />
          <h2 class="text-3xl font-bold mb-4">
            Terms and Conditions for Jiji Health Blood Management Information
            System (BMIS)
          </h2>
          <div>
            <EducationalAlerts
              title="Agreement"
              message="By accessing or using the Jiji Health Blood Management Information System (BMIS), you agree
                to comply with and be bound by these Terms and Conditions. If you do not agree, you must not 
                use the system"
              type="warning"
              :hasMargin="true"
            />
            <EducationalAlerts
              title="License"
              message="
           Jiji Health grants you a non-exclusive, non-transferable, and revocable license to use the BMIS 
            to manage blood donations, inventory, and related services, subject to compliance with these 
            terms.
           "
              type="warning"
            />
            <EducationalAlerts
              title=" System Access"
              message="
            <li> <b>Eligibility:</b> The BMIS is intended for authorized blood banks, hospitals, and healthcare 
            professionals only.</li>
                        <li> <b>Login Credentials:</b> Users must safeguard their login credentials and ensure they are not 
            shared with unauthorized parties.</li>
                        <li> <b>Access Restrictions:</b> Unauthorized access or misuse of the system may lead to 
            immediate termination and potential legal action.</li>"
              type="warning"
            />
            <EducationalAlerts
              title=" User Responsibilities"
              message="You agree to:
      <li>Use the BMIS only for its intended purposes of managing blood donation and transfusion services.</li>
      <li>Ensure accurate and up-to-date data entry for all operations.</li>
      <li> Maintain the confidentiality of donor and recipient information..</li>
      You agree not to:
      <li> Modify, copy, or distribute the BMIS without prior authorization.</li>
      <li> Use the system for fraudulent or unlawful purposes.</li> "
              type="warning"
            />
            <EducationalAlerts
              title=" Data Protection and Privacy"
              message="
            <li> <b>Data Ownership:</b> All data entered into the BMIS remains the property of the respective 
            healthcare facility.</li>
            <li> <b>Compliance:</b> Jiji Health complies with all relevant data protection laws, including 
            Kenya’s Data Protection Act. Users must ensure that data shared through the system 
            complies with these regulations.</li>
            <li> <b>Confidentiality:</b> Jiji Health uses robust measures to protect data but will not be liable for 
              breaches caused by user negligence.</li>"
              type="warning"
            />

            <EducationalAlerts
              title="Fees and Payment"
              message="
            <li> <b>Subscription Fees:</b> The BMIS is offered on a subscription basis, with fees determined by
              the selected plan.</li>
            <li> <b>Payment Terms:</b>  Payments must be made as per the agreed terms to maintain access to 
            the system. Late payments may result in suspension of service.</li>
            <li> <b>Refunds:</b> Refunds, if applicable, are governed by your subscription agreement.</li>"
              type="warning"
            />

            <EducationalAlerts
              title=" System Availability"
              message="
            <li> <b>Uptime:</b> Jiji Health strives to provide 99% uptime but does not guarantee uninterrupted 
              access due to maintenance or unforeseen circumstances.</li>
            <li> <b>Maintenance:</b>Scheduled maintenance and updates may temporarily restrict access. 
              Users will be notified in advance of planned downtime.</li>"
              type="warning"
            />

            <EducationalAlerts
              title=" Liability and Warranties"
              message="
            <li> <b>No Warranty:</b>The BMIS is provided “as-is” without any warranties, express or implied.</li>
            <li> <b>Limitation of Liability:</b> Jiji Health is not responsible for any direct, indirect, or 
              consequential damages arising from the use of the system, including data loss.</li>"
              type="warning"
            />
            <EducationalAlerts
              title=" Termination"
              message="
              Jiji Health may suspend or terminate access to the BMIS if:
            <li> Users fail to comply with these Terms and Conditions.</li>
            <li> Subscription payments are not made as agreed.
              Upon termination, users may request data export, subject to fees and conditions.</li>"
              type="warning"
            />
            <EducationalAlerts
              title="Intellectual Property"
              message="
             The BMIS, including its software, design, and features, remains the intellectual property 
              of Jiji Health. Unauthorized use, duplication, or redistribution is prohibited."
              type="warning"
            />
            <EducationalAlerts
              title="Amendments"
              message="
             Jiji Health reserves the right to amend these Terms and Conditions. Continued use of the BMIS 
            after such changes signifies acceptance of the updated terms."
              type="warning"
            />
            <EducationalAlerts
              title="Governing Law and Dispute Resolution"
              message="
            <li>These Terms and Conditions are governed by the laws of Kenya.</li>
            <li> Disputes will be resolved through arbitration in accordance with Kenyan Arbitration 
              Rules</li>"
              type="warning"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import AlertLayout from "@/components/AlertLayout.vue";
import EducationalAlerts from "@/components/EducationalAlerts.vue";

export default {
  name: "LoginPage",
  components: {
    AlertLayout,
    EducationalAlerts,
  },
  data() {
    return {
      email: "",
      password: "",
      passwordVisible: false,
      showTermsPopup: false,
      errorMessage: "", // Property for error message
    };
  },
  methods: {
    validateFields() {
      const emptyFields = [];
      if (!this.email) emptyFields.push("Email/Phone Number");
      if (!this.password) emptyFields.push("Password");

      return emptyFields;
    },

    openTermsPopup() {
      this.showTermsPopup = true;
    },

    closeTermsPopup() {
      this.showTermsPopup = false;
    },

    async login() {
      // Clear any previous error messages
      this.errorMessage = "";

      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        this.errorMessage = `Please fill the following field(s): ${emptyFields.join(
          ", "
        )}`;
        this.hideErrorMessage();
        return;
      }

      try {
        const response = await api.login({
          email: this.email,
          password: this.password,
        });

        const { data, token, roles, flags } = response;
        // Dispatch to Vuex store
        this.$store.dispatch("auth/login", { data, token, roles, flags });

        // Redirect after successful login
        this.$router.push("/donor-management");
      } catch (error) {
        // Set error message for wrong credentials
        this.errorMessage = "Wrong email or password";
        this.hideErrorMessage();
      }
    },

    hideErrorMessage() {
      setTimeout(() => {
        this.errorMessage = "";
      }, 3500);
    },

    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      const passwordField = this.$refs.passwordField;
      passwordField.type = this.passwordVisible ? "text" : "password";
    },
  },
};
</script>

<style scoped>
/* CSS for the fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
