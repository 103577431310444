<template>
  <div>
    <AlertLayout title="Reminders!"
      message="Kindly note that the officer will receive a notification via SMS with a link to set up their account passwords.
    <br> Kindly select the functions to be perfomed by the new officer. You may select two or more.
    <br> Kindly remember to place the officer in the correct department and select the fuctions to be perfomed by the new officer" type="warning" />
    <div class="mx-auto px-10 py-2 border border-t-0">
      <h2 class="text-lg font-bold mb-6">{{ officer_id ? 'Update Officer' : 'Add new officer' }}</h2>
      <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <label class="block mb-1">First name <span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.first_name"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter first name" required>
        </div>

        <div>
          <label class="block mb-1">Middle name</label>
          <input type="text" v-model="formData.middle_name"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter middle name" required>
        </div>

        <div>
          <label class="block mb-1">Last name <span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.last_name"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter last name" required>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">Gender <span class="text-red-500">*</span></label>
          <div class="relative">
            <select id="gender" v-model="formData.gender"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

       
        <div>
          <label class="block mb-1">Phone number <span class="text-red-500">*</span></label>
          <input type="text" v-model="formData.phone_number"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter phone number" required>
        </div>

        <div>
          <label class="block mb-1">Email <span class="text-red-500">*</span></label>
          <input type="email" v-model="formData.email"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray-400 shadow-sm shadow-gray-300"
            placeholder="Enter ID number or passport" required>
        </div>


        <div>
          <label class="block mb-2 text-gray-700 font-medium">select contact mode<span
              class="text-red-500">*</span></label>
          <div class="relative">
            <select id="wing" v-model="formData.contact_mode"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">select contact mode</option>
              <option value="both">Both</option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-2 text-gray-700 font-medium">select notification type</label>
          <div class="relative">
            <select id="wing" v-model="formData.notification_type"
              class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
              required>
              <option value="" disabled class="text-gray-400">select notification type</option>
              <option value="New Requisition">New Requisition</option>
              <option value="Start transfussion">Start transfussion</option>
              <option value="Transfusioin reaction">Transfusion reaction</option>
              <option value="New Share request">New Share request</option>
              <option value="Weekly Reports">Weekly Reports</option>
              <option value="Monthly report">Monthly report</option>
              <option value="All Reports">All Reports</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>

        <div>
          <label class="block mb-1">Select Department <span class="text-red-500">*</span></label>
          <select id="wing" v-model="formData.facility_department_id"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled class="text-gray-400">select department</option>
            <option v-for="department in departments" :key="department.facility_departments[0].facility_department_id"
              :value="department.facility_departments[0].facility_department_id">
              {{ department.name }}
            </option>
          </select>
        </div>

        <div>
          <label class="block mb-1">Select Role <span class="text-red-500">*</span></label>
          <select id="wing" v-model="formData.role_id"
            class="w-full p-2.5 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
            required>
            <option value="" disabled class="text-gray-400">select user role</option>
            <option v-for="role in roles" :key="role.role_id" :value="role.role_id">
              {{ role.name }}
            </option>
          </select>
        </div>

      </form>
      <div class="flex items-center justify-between mt-8">

        <button @click="handleBackToOfficers" v-if="!officer_id"
          class="px-4 py-2 border rounded text-orange-500 border-orange-500">Back</button>
        <div class="flex items-center ml-auto">
          <button @click="resetForm" v-if="!officer_id"
            class="px-4 py-2 border rounded text-orange-500 border-orange-500">Cancel</button>
          <CustomButton v-if="!officer_id" label="Save" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleSubmitNewOfficer" />
          <CustomButton v-if="officer_id" label="Update" bg-class="bg-custom-yellow px-8 py-3 ml-3"
            @click="handleUpdateOfficer" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from "@/components/CustomButton.vue";
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: "NewUsersForm",
  components: {
    AlertLayout,
    CustomButton,
  },
  data() {
    return {
      user: '',
      bloodGroups: [],
      roles: [],
      departments: [],
      officer_id: '',
      formData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        dob: '',
        contact_mode: '',
        email: '',
        phone_number: '',
        id_number: '',
        blood_group_id: '',
        notification_type: '',
        role_id: '',
        facility_department_id: '',
      },
      status: 'active',
      error: null,
    };
  },
  mounted() {
    this.user = this.$store.getters["auth/user"];
    this.officer_id = this.$route.params.userId;
  },
  computed: {
    maxDOB() {
      const today = new Date();
      const maxDate = new Date(
        today.getFullYear() - 16,
        today.getMonth(),
        today.getDate()
      );
      return maxDate.toISOString().split('T')[0];
    }
  },
  created() {
    this.fetchBloodGroups();
    this.fetchRoles();
    this.getDepartments();

    const userId = this.$route.params.userId;
    if (userId) {
      this.fetchUserData(userId);
    }
  },
  methods: {
    handleBackToOfficers() {
      this.$router.push({ name: 'UserManagementPage' });
    },
    

    validateFields() {
      const emptyFields = [];
      
      if (!this.formData.first_name) emptyFields.push('First name');
      if (!this.formData.last_name) emptyFields.push('Last name');
      if (!this.formData.gender) emptyFields.push('Gender');
      if (!this.formData.contact_mode) emptyFields.push('Contact Mode');
      if (!this.formData.role_id) emptyFields.push('Role');
      if (!this.formData.facility_department_id) emptyFields.push('Department');
      
      // Check for either email or phone number presence
      if (!this.formData.email && !this.formData.phone_number) {
        emptyFields.push('At least one of Email or Phone number is required');
      }

      // Check age validation
      if (this.formData.dob) {
        const birthDate = new Date(this.formData.dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (age < 16 || (age === 16 && monthDiff < 0)) {
          emptyFields.push('Age must be at least 16 years');
        }
      }

      return emptyFields;
    },

    async fetchBloodGroups() {
      try {
        const response = await api.getBloodGroups();
        this.bloodGroups = response;
      } catch (error) {
        console.error('Error fetching blood groups:', error);
      }
    },

    async getDepartments() {
      try {
        const response = await api.getDepartments();
        this.departments = response.data || response;
        console.log(this.departments)
      } catch (error) {
        this.error = error.message || "Failed to fetch departments";
      }
    },

    async fetchRoles() {
      try {
        const response = await api.getRoles();
        this.roles = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      }
    },

    async fetchUserData(userId) {
      try {
        const response = await api.getUser(userId);
        const userData = response.data || response;
        this.populateFormData(userData);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        this.error = error.message || 'Failed to fetch user data';
      }
    },
    populateFormData(userData) {
      this.formData.first_name = userData.first_name || '';
      this.formData.middle_name = userData.middle_name || '';
      this.formData.last_name = userData.last_name || '';
      this.formData.gender = userData.gender || '';
      this.formData.dob = userData.dob || '';
      this.formData.contact_mode = userData.contact_mode || '';
      this.formData.email = userData.email || '';
      this.formData.phone_number = userData.phone_number || '';
      this.formData.id_number = userData.id_number || '';
      this.formData.blood_group_id = userData.blood_group_id || '';
      this.formData.notification_type = userData.notification_type || '';
      this.formData.role_id = userData.user_roles?.[0]?.role_id || '';
      this.formData.facility_department_id = userData.user_roles?.[0]?.facility_department_id || '';
    },

    async handleUpdateOfficer() {
      const data = {
        facility_id: this.user.facility_id,
        first_name: this.formData.first_name,
        middle_name: this.formData.middle_name,
        last_name: this.formData.last_name,
        gender: this.formData.gender,
        dob: this.formData.dob,
        contact_mode: this.formData.contact_mode,
        email: this.formData.email,
        phone_number: this.formData.phone_number,
        id_number: this.formData.id_number,
        blood_group_id: this.formData.blood_group_id,
        notification_type: this.formData.notification_type,
        facility_department_id: this.formData.facility_department_id,
        role_id: this.formData.role_id,
      };

      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.updateUser(this.officer_id, data);
        await SweetAlertService.showSuccess('Success', 'Officer saved successfully!.');
        this.$router.push({ name: 'UserManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to update Officer';
        console.error('Error:', this.error);
        SweetAlertService.showError('Error', error.message || 'Error saving officer.');
      }
    },

    resetForm() {
      this.formData = {
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: '',
        dob: '',
        contact_mode: '',
        email: '',
        phone_number: '',
        id_number: '',
        blood_group_id: '',
        notification_type: '',
        role_id: '',
        facility_department_id: '',
      };
    },

    async handleSubmitNewOfficer() {
      const data = {
        facility_id: this.user.facility_id,
        first_name: this.formData.first_name,
        middle_name: this.formData.middle_name,
        last_name: this.formData.last_name,
        gender: this.formData.gender,
        dob: this.formData.dob,
        contact_mode: this.formData.contact_mode,
        email: this.formData.email,
        phone_number: this.formData.phone_number,
        id_number: this.formData.id_number,
        blood_group_id: this.formData.blood_group_id,
        notification_type: this.formData.notification_type,
        facility_department_id: this.formData.facility_department_id,
        role_id: this.formData.role_id,
      };

      const emptyFields = this.validateFields();
      if (emptyFields.length > 0) {
        SweetAlertService.showEmptyFieldsAlert(emptyFields);
        return;
      }

      try {
        await api.createUser(data);
        await SweetAlertService.showSuccess('Success!', 'Officer Added successfully!.');
        this.$router.push({ name: 'UserManagementPage' });
      } catch (error) {
        this.error = error.message || 'Failed to save Officer';
        console.error('Error:', this.error);
        SweetAlertService.showError('Error', 'Error updating officer.')
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* Your existing styles */
</style>
