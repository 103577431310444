<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div>
    <template v-else>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Donor Names
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                National ID
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Blood Group
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Gender
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Residence
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                No. of Donations
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Donation Process
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Donors Consent
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Date Registered
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(donor, index) in filteredDonors" :key="index" class="border-b">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-2">
              {{ donor.user.first_name }} {{ donor.user.last_name }}
            </td>
            <td class="px-6 py-2">{{ donor.user.id_number }}</td>
            <td class="px-6 py-2">
              {{ donor.user?.blood_group_id?.description ?? "Unknown" }}
            </td>
            <td class="px-6 py-2">{{ donor.user.gender }}</td>
            <td class="px-6 py-2">{{ donor.county_id?.name ?? "Unknown" }}</td>
            <td class="px-6 py-2 text-center">
              {{ donor.blood_donations ? donor.blood_donations.length : 0 }}
            </td>
            <td class="px-6 py-2">
              {{
                donor.blood_donations && donor.blood_donations.length > 0
                  ? donor.blood_donations[0].technology === "Normal"
                    ? "Normal/Regular"
                    : donor.blood_donations[0].technology
                  : "N/A"
              }}
            </td>
            <td class="px-6 py-2">{{ donor.terms_agreed }}</td>
            <td class="px-6 py-2">
              {{ new Date(donor.created_at).toLocaleDateString("en-CA") }}
            </td>
            <td class="flex items-center px-6 py-2">
              <CustomButton label="View Details" 
                @click="handleDonorDetails(donor.donor_id)"
                :disabled="isLoading[donor.donor_id]"
                :bg-class="isLoading[donor.donor_id] ? 'bg-gray-300 cursor-not-allowed' : 'bg-custom-yellow text-sm py-1'" />
                <div v-if="isLoading[donor.donor_id]" class="ml-2 animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-gray-900"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination -->
    <div class="flex justify-center gap-4 mt-4">
      <button @click="previousPage" :disabled="currentPage === 1"
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m15 19-7-7 7-7" />
        </svg>
        Previous
      </button>

      <div class="flex items-center gap-2">
        <button v-for="page in visiblePages" :key="page" @click="goToPage(page)" :class="{
          'bg-custom-blue text-white': page === currentPage,
          'text-custom-blue': page !== currentPage
        }"
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {{ page }}
          </span>
        </button>
      </div>

      <button @click="nextPage" :disabled="currentPage === totalPages"
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        Next
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>
  </template>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "DonorRegistryTab",
  components: {
    CustomButton,
  },
  mounted() {
    this.fetchDonors();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      perPage: 10,
      loading: false,
      isLoading: {},
      donors: [],
      error: null,
      filters: {
        bloodGroup: "",
        dateRegistered: "",
        gender: "",
        donationProcess: "",
      },
    };
  },
  computed: {
    visiblePages() {
      return this.calculatePageRange();
    },
    filteredDonors() {
      return this.donors.filter((donor) => {
        const search = this.searchQuery.toLowerCase();
        const safeToLower = (value) =>
          typeof value === "string" ? value.toLowerCase() : "";

        // Apply search filter
        const matchesSearch =
          safeToLower(donor.user?.first_name).includes(search) ||
          safeToLower(donor.user?.last_name).includes(search) ||
          safeToLower(donor.user?.id_number).includes(search) ||
          safeToLower(donor.user?.blood_group_id?.description).includes(
            search
          ) ||
          safeToLower(donor.user?.gender).includes(search) ||
          safeToLower(donor.county_id?.name).includes(search) ||
          safeToLower(
            donor.blood_donations && donor.blood_donations.length > 0
              ? donor.blood_donations[0].technology
              : "Unknown"
          ).includes(search) ||
          safeToLower(donor.terms_agreed).includes(search) ||
          safeToLower(
            new Date(donor.created_at).toLocaleDateString("en-CA")
          ).includes(search);

        // Apply additional filters
        const matchesBloodGroup =
          this.filters.bloodGroup === "" ||
          (this.filters.bloodGroup &&
            !donor.user?.blood_group_id?.description) ||
          safeToLower(donor.user?.blood_group_id?.description) ===
          safeToLower(this.filters.bloodGroup);

        const matchesDateRegistered =
          this.filters.dateRegistered === "" ||
          new Date(donor.created_at)
            .toLocaleDateString("en-CA")
            .includes(this.filters.dateRegistered);

        const matchesGender =
          this.filters.gender === "" ||
          safeToLower(donor.user?.gender) === safeToLower(this.filters.gender);

        const matchesDonationProcess =
          this.filters.donationProcess === "" ||
          (donor.blood_donations && donor.blood_donations.length > 0
            ? safeToLower(donor.blood_donations[0].technology) ===
            safeToLower(this.filters.donationProcess)
            : this.filters.donationProcess.toLowerCase() === "unknown");

        return (
          matchesSearch &&
          matchesBloodGroup &&
          matchesDateRegistered &&
          matchesGender &&
          matchesDonationProcess
        );
      });
    },
  },
  methods: {

   async handleDonorDetails(donorId) {
      this.isLoading[donorId] = true;
      try {
      await new Promise(resolve => setTimeout(resolve, 500));
        this.$router.push({
          name: "DonorDetailsPage",
          params: { donor_id: donorId },
        });
        } catch (error) {
        console.error(error);
      } finally {
        this.isLoading[donorId] = false; 
      }
      },

    calculatePageRange() {
      const maxPagesToShow = 5;
      let startPage = Math.max(1, this.currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);

      // Adjust start page if we're near the end
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },

    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchDonors();
      }
    },
    
    async previousPage() {
      console.log(this.currentPage, this.totalPages)
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchDonors();
      }
    },
    async nextPage() {
      // console.log(this.currentPage,this.totalPages)
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchDonors();
      }
    },

    async fetchDonors() {
      this.loading = true;
      try {
        const queryParams = {
          ...this.filters,
          search_query: this.searchQuery,
          page: this.currentPage,
          per_page: this.perPage
        };
        const response = await api.getDonors(queryParams);
        this.donors = response.data;
        this.currentPage = response.current_page;
        this.totalPages = response.total_pages;
      } catch (error) {
        this.error = error.message || "Failed to fetch donors";
      } finally {
        this.loading = false;
      }
    },


    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.currentPage = 1;
      this.fetchDonors();
    },

    resetFilters() {
      this.filters = {
        bloodGroup: "",
        dateRegistered: "",
        gender: "",
        donationProcess: "",
      };
      this.currentPage = 1;
      this.fetchDonors();
    },

  },
};
</script>

<style lang="css" scoped></style>
