<template>
  <div>
    <StepperLayout :steps="steps" :current-step="currentTab" />

    <div>
      <component
        :is="currentTabComponent"
        :formData="formData"
        :donorId="donorId"
        @move-to-previous-step="goToPreviousStep"
        @move-to-next-step="goToNextStep"
        @final-submit="handleFinalSubmit"
        @donor-created="handleDonorCreated"
      >
      </component>
    </div>
  </div>
</template>

<script>
import EducationalAlerts from "@/components/EducationalAlerts.vue";
import StepperLayout from "@/components/StepperLayout.vue";
import NewDonorEducationalPamphlet from "../forms/NewDonorEducationalPamphlet.vue";
import NewDonorInformationForm from "../forms/NewDonorInformationForm.vue";
import NewDonorQuetionnaireForm from "../forms/NewDonorQuestionnaireForm.vue";
import NewDonorDeclarationForm from "../forms/NewDonorDeclarationForm.vue";
import api from "@/services/api";
import SweetAlertService from "@/services/SweetAlertService";

export default {
  name: "NewDonorQuestionnaireForm",
  components: {
    EducationalAlerts,
    StepperLayout,
    NewDonorEducationalPamphlet,
    NewDonorInformationForm,
    NewDonorQuetionnaireForm,
    NewDonorDeclarationForm,
  },
  data() {
    return {
      currentTab: "NewDonorEducationalPamphlet",
      formData: {
        educationalPamphlet: {},
        donorInformation: {},
        questionnaire: {},
        declaration: {},
      },
      facility_id: this.user().facility_id,
      steps: [
        {
          id: "NewDonorEducationalPamphlet",
          title: "1. Educational Pamphlet",
          subtitle: "Read educational pamphlet",
          active: true,
        },
        {
          id: "NewDonorInformationForm",
          title: "2. Donor Information",
          subtitle: "Provide donor information",
          active: false,
        },
        {
          id: "NewDonorQuetionnaireForm",
          title: "3. Donor Questionnaire",
          subtitle: "Answer the donor questionnaire",
          active: false,
        },
        {
          id: "NewDonorDeclarationForm",
          title: "4. Declaration",
          subtitle: "Fill in declaration & consent form",
          active: false,
        },
      ],
    };
  },
  computed: {
    currentTabComponent() {
      switch (this.currentTab) {
        case "NewDonorEducationalPamphlet":
          return NewDonorEducationalPamphlet;
        case "NewDonorInformationForm":
          return NewDonorInformationForm;
        case "NewDonorQuetionnaireForm":
          return NewDonorQuetionnaireForm;
        case "NewDonorDeclarationForm":
          return NewDonorDeclarationForm;
        default:
          return NewDonorEducationalPamphlet;
      }
    },
  },
  methods: {
    user() {
      return this.$store.getters["auth/user"];
    },

    handleDonorCreated(newDonorId) {
      this.donorId = newDonorId;
    },
    setActiveStep(stepId) {
      this.steps.forEach((step) => {
        step.active = step.id === stepId;
      });
      this.currentTab = stepId;
    },
    goToPreviousStep() {
      const currentIndex = this.steps.findIndex(
        (step) => step.id === this.currentTab
      );
      if (currentIndex > 0) {
        this.setActiveStep(this.steps[currentIndex - 1].id);
      }
    },
    goToNextStep() {
      const currentIndex = this.steps.findIndex(
        (step) => step.id === this.currentTab
      );
      if (currentIndex < this.steps.length - 1) {
        this.setActiveStep(this.steps[currentIndex + 1].id);
      }
    },
    handleFinalSubmit() {
     const user= {
            first_name: this.formData.donorInformation.first_name,
            last_name: this.formData.donorInformation.last_name,
            dob: this.formData.donorInformation.dob,
            gender: this.formData.donorInformation.gender,
            email: this.formData.donorInformation.email,
            phone_number: this.formData.donorInformation.phone_number,
            blood_group_id: this.formData.donorInformation.blood_group_id,
            id_number: this.formData.donorInformation.id_number,
            physical_address: this.formData.donorInformation.physical_address,
            donor_status: this.formData.donorInformation.donor_status,
            nok_names: this.formData.donorInformation.nok_names,
            nok_phone: this.formData.donorInformation.nok_phone,
            nok_relationship: this.formData.donorInformation.nok_relationship,
            donation_status: this.formData.donorInformation.donation_status,
            last_donation_date: this.formData.donorInformation.last_donation_date,
            enroll_as_donor: this.formData.donorInformation.enroll_as_donor,
            county_id: this.formData.donorInformation.county_id,
            terms_agreed: this.formData.donorInformation.terms_agreed,
            patient: this.formData.donorInformation.patient,
            ward: this.formData.donorInformation.ward,
            password: "123456",  
            password_confirmation: "123456"         
           };

      const booking = {
        preferred_date: this.formData.donorInformation.preferred_date,
        preferred_time: this.formData.donorInformation.preferred_time,
      };

      const donorQuestionnaire = {
        feeling_healthy: this.formData.questionnaire?.feeling_healthy === "yes",
        take_flight: this.formData.questionnaire?.take_flight === "yes",
        minimum_weight: this.formData.questionnaire?.minimum_weight === "yes",
        active_allergy: this.formData.questionnaire?.active_allergy === "yes",
        severe_disease: this.formData.questionnaire?.severe_disease === "yes",
        had_alcohol: this.formData.questionnaire?.had_alcohol === "yes",
        had_drugs: this.formData.questionnaire?.had_drugs === "yes",
        taking_medication:
          this.formData.questionnaire?.taking_medication === "yes",
        donated_blood_component:
          this.formData.questionnaire?.donated_blood_component === "yes",
        dental_work: this.formData.questionnaire?.dental_work === "yes",
        unexplained_sickness:
          this.formData.questionnaire?.unexplained_sickness === "yes",
        relative_has_hepatitis:
          this.formData.questionnaire?.relative_has_hepatitis === "yes",
        had_body_piercing:
          this.formData.questionnaire?.had_body_piercing === "yes",
        immunization_vaccinations:
          this.formData.questionnaire?.immunization_vaccinations === "yes",
        pos_for_Hepatitis_C:
          this.formData.questionnaire?.pos_for_Hepatitis_C === "yes",
        had_surgery: this.formData.questionnaire?.had_surgery === "yes",
        infection_exposure:
          this.formData.questionnaire?.infection_exposure === "yes",
        medical_condition:
          this.formData.questionnaire?.medical_condition === "yes",
        positive_for_HIV:
          this.formData.questionnaire?.positive_for_HIV === "yes",
        bleeding_condition:
          this.formData.questionnaire?.bleeding_condition === "yes",
        rejected_as_donor:
          this.formData.questionnaire?.rejected_as_donor === "yes",
        yellow_fever_vaccine:
          this.formData.questionnaire?.yellow_fever_vaccine === "yes",
          hepatitis_rabies_vaccine:
          this.formData.questionnaire?.hepatitis_rabies_vaccine === "yes",
          covid_vaccine: this.formData.questionnaire?.covid_vaccine === "yes",
          had_blood_transfusion: this.formData.questionnaire?.had_blood_transfusion === "yes", 
          had_tatoo: this.formData.questionnaire?.had_tatoo === "yes", 
          had_acupuncture: this.formData.questionnaire?.had_acupuncture === "yes", 
          has_periods: this.formData.questionnaire?.has_periods === "yes", 
          is_breastfeeding: this.formData.questionnaire?.is_breastfeeding === "yes", 
          is_pregnant: this.formData.questionnaire?.is_pregnant === "yes",
      };

      const declaration = {
        read_educational_material:
          this.formData.declaration.read_educational_material,
        consent_checkbox: this.formData.declaration.consent_checkbox,
        blood_testing_consent: this.formData.declaration.blood_testing_consent,
        terms_agreed: this.formData.declaration.terms_agreed,
        consent_form: this.formData.declaration.consent_form,
      };

      const payload = {
        donor_questionnaire: donorQuestionnaire,
        declaration: declaration,
        booking: booking,
        user: user,
        
      };

      payload.facility_id = this.user().facility_id;

      // Call your API with the payload
      this.submitToApi(payload); 
    },
    async submitToApi(payload) {
      try {
        const response = await api.createDonor(payload);
        console.log("Submission successful:", response);
        await SweetAlertService.showSuccess(
          "Success!",
          "Donor information saved successfully!."
        );
        this.$router.push({ name: "DonorManagementPage" });
      } catch (error) {
        console.error("Error submitting the form:", error);
        await SweetAlertService.showError(
          "Submission Error",
          "An error occurred while saving the donor information."
        );
      }
    },
  },
};
</script>
