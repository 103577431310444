<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div> 
    <template v-else>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Name
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Email
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Phone Number
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                ID Number
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Contact Mode
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Facility
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Department
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Registration date
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Status
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in filteredUsers" :key="index" class="border-b">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-1">{{ user.first_name }} {{ user.last_name }}</td>
            <td class="px-6 py-1">{{ user.email }}</td>
            <td class="px-6 py-1">{{ user.phone_number }}</td>
            <td class="px-6 py-1">{{ user.id_number }}</td>
            <td class="px-6 py-1">{{ user.contact_mode }}</td>
            <td class="px-6 py-1">
              {{ user.user_roles?.[0]?.facility_department?.facility?.name ?? '' }}
            </td>
            <td class="px-6 py-1">
              {{ user.user_roles?.[0]?.facility_department?.department?.name ?? '' }}
            </td>
            <td class="px-6 py-2">
              {{ new Date(user.created_at).toLocaleString() ?? '' }}
            </td>
            <td class="px-6 py-1">{{ user.account_status }}</td>
            <td class="flex items-center px-4 py-2">
              <CustomButton label="Details"  @click="handleShowDetails(user.user_id)" 
              :disabled="isLoading[user.user_id]" 
              :bg-class="isLoading[user.user_id] ? 'bg-gray-300 cursor-not-allowed' : 'bg-custom-yellow text-sm py-1'" />
              <div v-if="isLoading[user.user_id]" class="ml-2 animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-gray-900"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center gap-4 mt-4">
      <button disabled
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m15 19-7-7 7-7" />
        </svg>
        Previous
      </button>
      <div class="flex items-center gap-2">
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg bg-custom-blue text-center align-middle font-medium text-white shadow-md shadow-custom-blue/10 transition-all hover:shadow-lg hover:shadow-custom-blue/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            1
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            2
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            3
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            4
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            5
          </span>
        </button>
      </div>
      <button
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        Next
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>
    </template>
  </div>
</template>
<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "UserManagementTab",
  components: {
    CustomButton,
  },
  created() {
    this.fetchUsers();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      users: [],
      error: null,
      isLoading: {},
      loading: false,
      filters: {
        contactMode: '',
        facility: '',
        department: '',
        registrationDate: '',
        status: ''
      }
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        const search = this.searchQuery.toLowerCase();
        const safeToLower = value => typeof value === 'string' ? value.toLowerCase() : '';

        // Apply search filter
        const matchesSearch =
          safeToLower(user.first_name).includes(search) ||
          safeToLower(user.last_name).includes(search) ||
          safeToLower(user.email).includes(search) ||
          safeToLower(user.phone_number).includes(search) ||
          safeToLower(user.id_number).includes(search) ||
          safeToLower(user.contact_mode).includes(search) ||
          safeToLower(user.user_roles?.[0]?.facility_department?.facility?.name ?? '').includes(search) ||
          safeToLower(user.user_roles?.[0]?.facility_department?.department?.name ?? '').includes(search) ||
          safeToLower(new Date(user.created_at).toLocaleDateString()).includes(search) ||
          safeToLower(user.account_status).includes(search);

        // Apply additional filters
        const matchesName = this.filters.name === '' ||
          safeToLower(user.first_name + ' ' + user.last_name).includes(safeToLower(this.filters.name));

        const matchesContactMode = this.filters.contactMode === '' ||
          safeToLower(user.contact_mode) === safeToLower(this.filters.contactMode);

        const matchesFacility = this.filters.facility === '' ||
          safeToLower(user.user_roles?.[0]?.facility_department?.facility?.name ?? '').includes(safeToLower(this.filters.facility));

        const matchesDepartment = this.filters.department === '' ||
          safeToLower(user.user_roles?.[0]?.facility_department?.department?.name ?? '').includes(safeToLower(this.filters.department));

        const matchesRegistrationDate = this.filters.registrationDate === '' ||
          new Date(user.created_at).toLocaleDateString().includes(this.filters.registrationDate);

        const matchesStatus = this.filters.status === '' ||
          safeToLower(user.account_status) === safeToLower(this.filters.status);

        return matchesSearch && matchesName && matchesContactMode && matchesFacility && matchesDepartment &&
          matchesRegistrationDate && matchesStatus;
      });
    },
    userClaims() {
      return this.$store.getters['auth/claims'];
    },
    userData() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    async fetchUsers() {
      this.loading = true
      try {
        const response = await api.getUsers();
        const allUsers = response.data || response;

        //Disclaimer-  Filter users who have the user_roles property and it's not empty -  Do not remove this filter
        this.users = allUsers.filter(user => Array.isArray(user.user_roles) && user.user_roles.length > 0);
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      } finally {
        this.loading = false
      }
    },

    async handleShowDetails(userId) {
      this.isLoading[userId] = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
      this.$router.push({
        name: 'NewUserForm',
        params: { userId }
      });
    }catch {
          this.isLoading[userId] = false
      }
    },

    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
    },

    resetFilters() {
      this.filters = {
        contactMode: '',
        facility: '',
        department: '',
        registrationDate: '',
        status: ''
      };
    }
  },
};
</script>

<style lang="css" scoped></style>
