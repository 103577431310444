import { createRouter, createWebHistory } from "vue-router";
import Login from "./views/auth/LoginPage.vue";
import DonorManagementPage from "./views/pages/DonorManagementPage.vue";
import InventoryManagementPage from "./views/pages/InventoryManagementPage.vue";
import RequisitionManagementPage from "./views/pages/RequisitionManagementPage.vue";
import TheatreDaysManagementPage from "./views/pages/TheatreDaysManagementPage.vue";
import NewDonorQuestionnaireForm from "./views/pages/NewDonorQuestionnairePage.vue";
import NewDonorRegistrationPage from "./views/pages/NewDonorRegistrationPage.vue";
import DepartmentsPage from "./views/pages/DepartmentsPage.vue";
import DonorQuestionnaireInformation from "./views/pages/DonorQuestionnaireInformation.vue";
import NewTheatreDayForm from "./views/forms/NewTheatreDayForm.vue";
import NewRequisitionForm from "./views/forms/NewRequisitionForm.vue";
import DonorDetailsPage from "./views/pages/DonorDetailsPage.vue";
import UserManagementPage from "./views/pages/UserManagementPage.vue";
import EditDonorInformationPage from "./views/pages/EditDonorInformationPage.vue";
import ClinicalAreaPage from "./views/pages/ClinicalAreaPage.vue";
import NewDonorQuestionnairePage from "./views/pages/NewDonorQuestionnairePage.vue";
import NewDonorQuestionnaireMobilePage from "./views/deleted_files/NewDonorQuestionnaireMobilePage.vue";
import EditDonorQuestionnairePage from "./views/pages/EditDonorQuestionnairePage.vue";
import NewDepartmentForm from "./views/forms/NewDepartmentForm.vue";
import NewBedTypeForm from "./views/forms/NewBedTypeForm.vue";
import NewUserForm from "./views/forms/NewUserForm.vue";
import NewFacilityForm from "./views/forms/NewFacilityForm.vue";
import NewBedForm from "./views/forms/NewBedForm.vue";
import UnitScreeningForm from "./views/forms/UnitScreeningForm.vue";
import ProfilePage from "./views/pages/ProfilePage.vue";
import FacilityRequisitionDetailsPage from "./views/pages/FacilityRequisitionDetailsPage.vue";
import NewRoleForm from "./views/forms/NewRoleForm.vue";
import NewDonorDeferralForm from "./views/forms/NewDonorDeferralForm.vue";
import NewDonorVitalsForm from "./views/forms/NewDonorVitalsForm.vue";
import NewRecordDonationForm from "./views/forms/NewRecordDonationForm.vue";
import NewReactionForm from "./views/forms/NewReactionForm.vue";
import UnitDetailsPage from "./views/pages/UnitDetailsPage.vue";
import ProductDetailsPage from "./views/pages/ProductDetailsPage.vue";
import ComponentPreparationPage from "./views/pages/ComponentPreparationPage.vue";
import AddSampleForm from "./views/forms/AddSampleForm.vue";
import AssignUnitPage from "./views/pages/AssignUnitPage.vue";
import LiveCounterTallyPage from "./views/pages/LiveCounterTallyPage.vue";
import UnitTargetsForm from "./views/forms/UnitTargetsForm.vue";
import TransfusionChart from "./views/pages/TransfusionChart.vue";
import NewReferralFacilityForm from "./views/forms/NewReferralFacilityForm.vue";
import NewExternalUnit from "./views/forms/NewExternalUnit.vue";
import ExternalUnitIssuanceForm from "./views/forms/ExternalUnitIssuanceForm.vue";
import SampleDetailsPage from "./views/pages/SampleDetailsPage.vue";
import StaffLearningManagementPage from "./views/pages/StaffLearningManagementPage.vue";
import SupervisorLearningManagementPage from "./views/pages/SupervisorLearningManagementPage.vue";
import StaffDocumentDetailsPage from "./views/pages/StaffDocumentDetailsPage.vue";
import StaffAssessmentDetailsPage from "./views/pages/StaffAssessmentDetailsPage.vue";
import StaffAssessmentTestPage from "./views/pages/StaffAssessmentTestPage.vue";
import SupervisorDocumentsDetailsPage from "./views/pages/SupervisorDocumentsDetailsPage.vue";
import SupervisorAssessementDetailsPage from "./views/pages/SupervisorAssessementDetailsPage.vue";
import ExamTestReviewPage from "./views/pages/ExamTestReviewPage.vue";
import AddAssessementQuestionsPage from "./views/pages/AddAssessementQuestionsPage.vue";
import QuestionsReviewPage from "./views/pages/QuestionsReviewPage .vue";

const routes = [
  {
    path: "/donor-questionnaire",
    name: "NewDonorQuestionnaireMobilePage",
    component: NewDonorQuestionnaireMobilePage,
    meta: {
      title: "Donor Questionnaire",
      layout: "empty",
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "Login", layout: "empty", requiresAuth: false },
  },
  {
    path: "/donor-management",
    name: "DonorManagementPage",
    component: DonorManagementPage,
    meta: { title: "Donor Management" },
  },
  {
    path: "/facility-requisition-details/:requisition_id",
    name: "FacilityRequisitionDetailsPage",
    component: FacilityRequisitionDetailsPage,
    props: true,
    meta: { title: "Pending Requisition Details" },
  },
  {
    path: "/sample-details/:requisition_id",
    name: "SampleDetailsPage",
    component: SampleDetailsPage,
    props: true,
    meta: { title: "Sample Details" },
  },
  {
    path: "/drm/new-donor-questionnaire",
    name: "NewDonorQuestionnaireForm",
    component: NewDonorQuestionnaireForm,
    meta: { title: "New Donor Questionnaire" },
  },
  {
    path: "/drm/donor-questionnaire",
    name: "NewDonorQuestionnairePage",
    component: NewDonorQuestionnairePage,
    meta: { title: "Donor Questionnaire" },
  },
  {
    path: "/drm/edit-donor-questionnaire/:donor_id",
    name: "EditDonorQuestionnairePage",
    component: EditDonorQuestionnairePage,
    meta: { title: "Edit Donor Questionnaire" },
  },
  {
    path: "/donor-details/:donor_id",
    name: "DonorDetailsPage",
    component: DonorDetailsPage,
    props: true,
    meta: { title: "Donor Brief" },
  },
  {
    path: "/blood-details/:blood_donation_id",
    name: "UnitDetailsPage",
    component: UnitDetailsPage,
    props: true,
    meta: { title: "Blood Unit Details" },
  },
  {
    path: "/product-details/:product_id",
    name: "ProductDetailsPage",
    component: ProductDetailsPage,
    props: true,
    meta: { title: "Product Details" },
  },
  {
    path: "/donor-vitals/:donor_id",
    name: "NewDonorVitalsForm",
    component: NewDonorVitalsForm,
    props: true,
    meta: { title: "Donor Vitals" },
  },
  {
    path: "/record-donation/:donor_id",
    name: "NewRecordDonationForm",
    component: NewRecordDonationForm,
    props: true,
    meta: { title: "Record Donation" },
  },
  {
    path: "/donor-deferral/:donor_id",
    name: "NewDonorDeferralForm",
    component: NewDonorDeferralForm,
    props: true,
    meta: { title: "Donor Deferral" },
  },
  {
    path: "/new-reaction/:donor_id",
    name: "NewReactionForm",
    component: NewReactionForm,
    props: true,
    meta: { title: "New Reaction" },
  },
  {
    path: "/edit-donor/:donor_id",
    name: "EditDonorInformationPage",
    component: EditDonorInformationPage,
    props: true,
    meta: { title: "Edit Donor Information" },
  },
  {
    path: "/facility-management/requisition-management",
    name: "RequisitionManagementPage",
    component: RequisitionManagementPage,
    meta: { title: "Requisition Management" },
  },
  {
    path: "/facility-management/theatre-days-management",
    name: "TheatreDaysManagementPage",
    component: TheatreDaysManagementPage,
    meta: { title: "Theatre Days Management" },
  },
  {
    path: "/new-theatre-days",
    name: "NewTheatreDayForm",
    component: NewTheatreDayForm,
    meta: { title: "Add new theatre only days" },
  },
  {
    path: "/unit-screening-form/:blood_donation_id",
    name: "UnitScreeningForm",
    component: UnitScreeningForm,
    meta: { title: "Blood unit screening" },
  },
  {
    path: "/departments",
    name: "DepartmentsPage",
    component: DepartmentsPage,
    meta: { title: "Department Management" },
  },
  {
    path: "/new-department/:departments_id?",
    name: "NewDepartmentForm",
    component: NewDepartmentForm,
    meta: { title: "Department Details" },
    props: true,
  },
  {
    path: "/new-bed/:bed_id?",
    name: "NewBedForm",
    component: NewBedForm,
    meta: { title: "Bed Details" },
  },
  {
    path: "/new-requisition/:requisition_id?",
    name: "NewRequisitionForm",
    component: NewRequisitionForm,
    meta: { title: "Blood Requisition" },
  },
  {
    path: "/clinical-area",
    name: "ClinicalAreaPage",
    component: ClinicalAreaPage,
    meta: { title: "Clinical Area" },
  },
  {
    path: "/user-management",
    name: "UserManagementPage",
    component: UserManagementPage,
    meta: { title: "User Management" },
  },
  {
    path: "/new-user/:userId?",
    name: "NewUserForm",
    component: NewUserForm,
    meta: { title: "Officer Details" },
    props: true,
  },
  {
    path: "/new-role",
    name: "NewRoleForm",
    component: NewRoleForm,
    meta: { title: "Add New Role" },
  },
  {
    path: "/profile-management",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { title: "Profiles" },
  },
  {
    path: "/facility-management/inventory-management",
    name: "InventoryManagementPage",
    component: InventoryManagementPage,
    meta: { title: "Inventory Management" },
  },
  {
    path: "/new-donor-registration",
    name: "NewDonorRegistrationPage",
    component: NewDonorRegistrationPage,
    meta: { title: "Add New Donor" },
  },
  {
    path: "/donor-questionnaire-info/:donor_id",
    name: "DonorQuestionnaireInformation",
    component: DonorQuestionnaireInformation,
    props: true,
    meta: { title: "Donor questionnaire information" },
  },
  {
    path: "/new-bed-type/:bed_type_id?",
    name: "NewBedTypeForm",
    component: NewBedTypeForm,
    meta: { title: "Bed Type Details" },
  },
  {
    path: "/unit-target",
    name: "UnitTargetsForm",
    component: UnitTargetsForm,
    meta: { title: "" },
  },
  {
    path: "/new-facility/:facilityId?",
    name: "NewFacilityForm",
    component: NewFacilityForm,
    meta: { title: "Facility Details" },
    props: true,
  },
  {
    path: "/live-counter-tally",
    name: "LiveCounterTallyPage",
    component: LiveCounterTallyPage,
    meta: { title: "Live Counter Tally" },
  },
  {
    path: "/component-preparation/:product_id/:flag",
    name: "ComponentPreparationPage",
    component: ComponentPreparationPage,
    meta: { title: "Component Preparation" },
  },
  {
    path: "/Add-Sample/:requisition_id?",
    name: "AddSampleForm",
    component: AddSampleForm,
    meta: { title: "New Sample" },
    props: true,
  },
  {
    path: "/Assign-Unit/:requisition_product_id",
    name: "AssignUnitPage",
    component: AssignUnitPage,
    meta: { title: "Assign Unit" },
  },
  {
    path: "/transfusion-monitoring/:transfusion_id?",
    name: "TransfusionChart",
    component: TransfusionChart,
    meta: { title: "Transfusion Monitoring" },
  },
  {
    path: "/new-referral-facility/:donor_id",
    name: "NewReferralFacilityForm",
    component: NewReferralFacilityForm,
    props: true,
    meta: { title: "New Referral Facility" },
  },
  {
    path: "/new-unit",
    name: "NewExternalUnit",
    component: NewExternalUnit,
    meta: { title: "New Extenal Unit" },
    props: true,
  },
  {
    path: "/issue-unit-external",
    name: "ExternalUnitIssuanceForm",
    component: ExternalUnitIssuanceForm,
    meta: { title: "New Extenal Unit Issuance" },
    props: true,
  },
  {
    path: "/staff-elearning",
    name: "StaffLearningManagementPage",
    component: StaffLearningManagementPage,
    meta: { title: "Learning Management" },
    props: true,
  },
  {
    path: "/supervisor-elearning",
    name: "SupervisorLearningManagementPage",
    component: SupervisorLearningManagementPage,
    meta: { title: "Learning Management" },
    props: true,
  },
  {
    path: "/staff-document-detail/:document_id?",
    name: "StaffDocumentDetailsPage",
    component: StaffDocumentDetailsPage,
    meta: { title: "Training Material Details" },
    props: true,
  },
  {
    path: "/staff-assessment-detail/:assessment_id?",
    name: "StaffAssessmentDetailsPage",
    component: StaffAssessmentDetailsPage,
    meta: { title: "Assessment Details" },
    props: true,
  },
  {
    path: "/staff-assessment-test/:assessment_id?",
    name: "StaffAssessmentTestPage",
    component: StaffAssessmentTestPage,
    meta: { title: "Assessment Test" },
    props: true,
  },
  {
    path: "/supervisor-document-detail/:document_id?",
    name: "SupervisorDocumentsDetailsPage",
    component: SupervisorDocumentsDetailsPage,
    meta: { title: "Training Material Details" },
    props: true,
  },
  {
    path: "/supervisor-assessment-detail/:assessment_id?",
    name: "SupervisorAssessementDetailsPage",
    component: SupervisorAssessementDetailsPage,
    meta: { title: "Assessment Details" },
    props: true,
  },
  {
    path: "/assessment-cat/:assessment_id?",
    name: "AddAssessementQuestionsPage",
    component: AddAssessementQuestionsPage,
    meta: { title: "Assessment Questions" },
    props: true,
  }, 
  {
    path: "/assessment-test-review/:assessment_id?",
    name: "ExamTestReviewPage",
    component: ExamTestReviewPage,
    meta: { title: "Assessment Test Review" },
    props: true,
  },
  {
    path: "/questions-review/:assessment_id?",
    name: "QuestionsReviewPage",
    component: QuestionsReviewPage,
    meta: { title: "Questions Review" },
    props: true,
  },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export default router;
