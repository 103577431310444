<template>
    <div>
        <AlertLayout title="Reminders!" message="-Kindly record the correct details." type="warning" />
        <div class="container mx-auto p-10 border border-t-0">
            <h2 class="text-2xl font-bold mb-6">Process new donation</h2>

            <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <!-- Donation type -->
                <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="donation_type">
                        Select donation type <span class="text-red-500">*</span>
                    </label>
                    <div class="relative">
                        <select id="donation_type" v-model="formData.donation_type"
                            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                            required>
                            <option value="" disabled selected>Select status</option>
                            <option value="Replacement">
                                Replacement (Replaces blood unit for specific patient)
                            </option>
                            <option value="Random">
                                Random (First-time donor, not for specific patient)
                            </option>
                            <option value="Frequent">
                                Regular-donor (Frequent donor, not for specific patient)
                            </option>
                            <option value="Designated"> 
                                Designated/Directed (Donates for specific patient with doctor's
                                letter)
                            </option>
                            <option value="Autologous">
                                Autologous (Donates for future self use with doctor's letter)
                            </option>
                        </select>
                    </div>
                </div>

                

                <!-- Conditionally show this field when "Replacement" is selected -->
                <div v-if="formData.donation_type === 'Replacement'" >
                    <label class="block mb-2 text-gray-700 font-medium" for="ipop">
                        Enter IP/OP Number/Name of the patient donor is replacing for
                        <span class="text-red-500">*</span>
                    </label>
                    <input id="ipop" v-model="formData.ipop" type="text"
                        class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400"
                        placeholder="Enter IP/OP Number or Patient Name" required />
                </div>


                <!-- Pint serial -->
                <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="pint_serial">Scan or type blood bag/pilot
                        tube serial number<span class="text-red-500">*</span></label>
                    <input id="pint_serial" v-model="formData.pint_serial" type="text"
                        class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                        placeholder="Pint Serial Number" required>
                </div>

                <!-- Donation process -->
                <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="technology">Select donation process <span
                            class="text-red-500">*</span></label>
                    <div class="relative">
                        <select id="technology" v-model="formData.technology"
                            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                            required>
                            <option value="" disabled>Select Donation Process</option>
                            <option value="Normal">Voluntary/Regular</option>
                            <option value="Apheresis">Apheresis</option>
                        </select>
                    </div>
                </div>

                <!-- Donation success -->
                <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="donation_successful">Was the donation
                        successful? <span class="text-red-500">*</span></label>
                    <div class="relative">
                        <select id="donation_successful" v-model="formData.donation_successful"
                            class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                            required>
                            <option value="" disabled>Select Success Status</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>

                <!-- Date added -->
                <div>
                    <label class="block mb-2 text-gray-700 font-medium" for="date_added">Enter date and time of donation
                        (yyyy-mm-dd H:m:s)<span class="text-red-500">*</span></label>
                    <input id="date_added" v-model="formData.date_added" type="datetime-local"
                        class="w-full p-3 border rounded bg-gray-100 placeholder-gray shadow-sm shadow-gray-400 appearance-none"
                        required>
                </div>
            </form>

            <div class="mt-8 flex justify-between">
                <button class="px-4 py-2 border rounded text-orange-500 border-orange-500"
                    @click="clearDonationForm">Clear</button>
                <div class="flex items-center space-x-2 mt-5 sm:mt-0">
                    <CustomButton v-if="formData.donation_successful === 'No'" label="Defer"
                        bg-class="bg-red-500 text-white px-8 py-3" @click="redirectDeferralForm" />
                    <CustomButton v-if="formData.donation_successful === 'No'" label="New Reaction"
                        bg-class="bg-red-500 text-white px-8 py-3" @click="redirectToDonationReactionForm" />
                    <CustomButton v-if="formData.donation_successful === 'Yes'" label="Register Donation"
                        bg-class="bg-custom-yellow text-white px-8 py-3" @click="handleRecordDonation" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AlertLayout from "@/components/AlertLayout.vue";
import CustomButton from '@/components/CustomButton.vue';
import api from '@/services/api';
import SweetAlertService from '@/services/SweetAlertService'; // Import SweetAlertService

export default {
    name: 'NewRecordDonationForm',
    components: {
        CustomButton,
        AlertLayout
    },
    props: {
        donorId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            facilityId: null,
            formData: {
                donation_type: '',
                pint_serial: '',
                technology: '',
                ipop: '',
                date_added: this.getCurrentDateTime(),
                donation_successful: '',
                added_by: this.user().user_id,
                facility_id: this.user().facility_id,
            },
            donor: {},
        };
        
    },

    computed: {
        userClaims() {
            return this.$store.getters['auth/claims'];
        },
        userData() {
            return this.$store.getters['auth/user'];
        },
    },

    mounted() {
        this.facilityId = this.userData.facility_id;
    },

    created() {
    this.fetchDonorDetails();
  },

  watch: {
    // Watch the donation type and autofill the IP/OP field if Replacement is selected
    "formData.donation_type"(newValue) {
      if (newValue === "Replacement" && this.donor.patient) {
        this.formData.ipop = this.donor.patient; // Autofill with the patient name
      } else {
        this.formData.ipop = ""; // Clear the field for other donation types
      }
    },
  },

    methods: {
        user() {
            return this.$store.getters["auth/user"];
        },

        async fetchDonorDetails() {
      try {
        const donorId = this.$route.params.donor_id;
        const response = await api.getDonor(donorId);
        this.donor = response.data || response;
        if (this.donor.donation_status === "Replacement") {
      this.formData.donation_type = "Replacement";
    }
      } catch (error) {
        this.error = error.message || "Failed to fetch donor details";
        console.error("Error fetching donor details:", this.error);
      }
    },

        getCurrentDateTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = ('0' + (now.getMonth() + 1)).slice(-2);
            const day = ('0' + now.getDate()).slice(-2);
            const hours = ('0' + now.getHours()).slice(-2);
            const minutes = ('0' + now.getMinutes()).slice(-2);
            const seconds = ('0' + now.getSeconds()).slice(-2);
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        },

        async handleRecordDonation() {
            // Validate form fields
            const emptyFields = [];
            if (!this.formData.donation_type) emptyFields.push("Donation Type");
            if (!this.formData.pint_serial) emptyFields.push("Pint Serial");
            if (!this.formData.technology) emptyFields.push("Donation Process");
            if (!this.formData.donation_successful) emptyFields.push("Success Status");

            // If there are empty fields, show warning alert
            if (emptyFields.length > 0) {
                SweetAlertService.showEmptyFieldsAlert(emptyFields);
                return;
            }

            // Proceed with the API request if all fields are filled
            try {
                const donorId = this.$route.params.donor_id;
                const response = await api.createBloodDonation(donorId, this.formData);
                console.log('Donation data saved successfully:', response);
                await SweetAlertService.showSuccess('Success', 'Donation data saved successfully!');
                this.$router.push({ name: 'DonorManagementPage' });
            } catch (error) {
                // console.error('Error submitting the form:', error.message);  
                SweetAlertService.showError('Error', error.message || 'An unknown error occurred.');
            }
        },

        redirectDeferralForm() {
            SweetAlertService.showConfirmation('Continue to deferrals', 'Do you want to continue to the deferral form?')
                .then((result) => {
                    if (result.value) {
                        this.$router.push({
                            name: 'NewDonorDeferralForm',
                            params: { donorId: this.$route.params.donor_id }
                        });
                    }
                });
        },
        redirectToDonationReactionForm() {
            SweetAlertService.showConfirmation('Continue to reactions', 'Do you want to continue to the reactions form?')
                .then((result) => {
                    if (result.value) {
                        this.$router.push({
                            name: 'NewReactionForm',
                            params: { donorId: this.$route.params.donor_id }
                        });
                    }
                });
        },
        clearDonationForm() {
            this.formData = {
                donation_type: '',
                pint_serial: '',
                technology: '',
                donation_successful: '',
            };
        },
    },
};
</script>

<style scoped></style>
