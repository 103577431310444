
<template>
  <div class="relative overflow-x-auto mt-5">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="px-6 py-2">
              <div class="flex items-center">
                Feature Name
              </div>
            </th>
            <th scope="col" class="px-6 py-2">
              <div class="flex items-center">
                Description
              </div>
            </th>
            <th scope="col" class="px-6 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(feature, index) in filteredFeatures" :key="index" class="border-b">
            <td class="px-6 py-2">{{ feature.name }}</td>
            <td class="px-6 py-2">{{ feature.description }}</td>
            <td class="flex items-center px-4 py-2">
              <CustomButton label="Manage Feature Accounts" @click="openFeatureModal(feature.feature_id)"
                bg-class="bg-custom-yellow text-sm py-2" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center gap-4 mt-4">
      <button disabled
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m15 19-7-7 7-7" />
        </svg>
        Previous
      </button>
      <div class="flex items-center gap-2">
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg bg-custom-blue text-center align-middle font-medium text-white shadow-md shadow-custom-blue/10 transition-all hover:shadow-lg hover:shadow-custom-blue/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            1
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            2
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            3
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            4
          </span>
        </button>
        <button
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium text-custom-blue transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            5
          </span>
        </button>
      </div>
      <button
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        Next
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>

    <div v-if="showFeaturesModal"
      class="fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full flex items-center justify-center">
      <div class="bg-white p-8 rounded-lg shadow-xl w-3/6 mt-4 mb-4">
        <h2 class="text-2xl text-gray-800 font-bold mb-4">Feature Assigning</h2>
        <hr class="h-px my-4 bg-orange-200 border-0 dark:bg-orange-200">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-5">
          <div v-for="(facility, index) in feature_accounts.facilities" :key="facility.facility_id"
            class="border p-4 rounded-md shadow">
            <div class="flex items-center justify-center mt-2">
              <input type="checkbox" :id="`facility-${index}`" v-model="facility.has_feature"
                class="mr-2 w-4 h-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
              <h3 class="text-lg font-semibold">{{ facility.name }}</h3>
            </div>
          </div>
        </div>
        <div class="flex justify-end space-x-2 mt-4">
          <button type="button" @click="closeFeatureModal"
            class="px-4 py-2 bg-gray-200 text-gray-800 rounded">Cancel</button>
          <button @click="handleFeatureAccountUpdate(feature_accounts.feature_id)" class="px-4 py-2 bg-custom-yellow text-white rounded">Update</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";
import SweetAlertService from '@/services/SweetAlertService';

export default {
  name: 'FeatureFlaggingTab',
  components: {
    CustomButton,
  },
  created() {
    this.fetchFeatures();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      features: [],
      feature_accounts: [],
      categorizedClaims: {},
      currentRoleId: '',
      showFeaturesModal: false,
      error: null,
    };
  },
  computed: {
    filteredFeatures() {
      const search = this.searchQuery.toLowerCase();
      return this.features.filter((feature) => {
        const safeToLower = (value) =>
          typeof value === "string" ? value.toLowerCase() : "";

        return (
          safeToLower(feature.name)?.includes(search) ||
          safeToLower(feature.description)?.includes(search)
        );
      });
    },
    userClaims() {
      return this.$store.getters['auth/claims'];
    },
    userData() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    this.fetchFeatures()
  },
  methods: {
    async fetchFeatures() {
      try {
        const response = await api.getFeatures();
        this.features = response.data || response;
      } catch (error) {
        this.error = error.message || "Failed to fetch users";
      }
    },
    async fetchFeatureAccounts(feature_id) {
      try {
        const response = await api.getFeatureAccounts(feature_id);
        this.feature_accounts = response.data || response;

        this.feature_accounts.facilities.forEach(facility => {
          if (facility.has_feature) {
            facility.existing_feature_account = true;  // Assuming this facility previously had the feature
          } else {
            // If unchecked, set existing_feature_account to false or any appropriate logic
            facility.existing_feature_account = false;
          }
        });
      } catch (error) {
        this.error = error.message || "Failed to fetch claims";
      }
    },
    openFeatureModal(feature_id) {
      this.currentRoleId = feature_id;
      this.showFeaturesModal = true;
      this.fetchFeatureAccounts(feature_id);
    },
    closeFeatureModal() {
      this.showFeaturesModal = false;
    },

    async handleFeatureAccountUpdate(feature_id) {
      try {
        // Extract facilities to add (those checked)
        const facilitiesToAdd = this.feature_accounts.facilities
          .filter(facility => facility.has_feature) // Only facilities with `has_feature` checked
          .map(facility => facility.facility_id); // Get their IDs

        // Extract facilities to remove (those unchecked and previously had the feature)
        const facilitiesToRemove = this.feature_accounts.facilities
          .filter(facility => !facility.has_feature && facility.existing_feature_account) // Unchecked and previously existed
          .map(facility => facility.facility_id); // Get their IDs

        const payload = {
          feature_id: feature_id,
          facilities_to_add: facilitiesToAdd,
          facilities_to_remove: facilitiesToRemove
        };

        // Send the payload to the backend
        await api.updateFeatureAccounts(payload);

        // Close modal and show success alert
        this.closeFeatureModal();
        await SweetAlertService.showSuccess('Success!', 'Accounts updated successfully!');
      } catch (error) {
        console.error("Failed to update privileges:", error);
        SweetAlertService.showError('Error', 'Error updating accounts for the feature.');
      }
    }

  },
}
</script>

<style lang="css" scoped>

</style>

