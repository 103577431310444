<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center min-h-[200px]">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-custom-yellow"></div>
    </div> 
    <template v-else>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
      <table class="w-full text-left rtl:text-right text-gray-700">
        <thead>
          <tr class="bg-gray-100">
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  class="w-4 h-4 text-custom-yellow bg-gray-100 border-gray-300 rounded focus:ring-custom-yellow dark:focus:ring-custom-yellow dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Patient Names
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                IP/OP No.
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Blood Group
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Situation
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Intent
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Ward
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Date
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Sample
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">
              <div class="flex items-center">
                Status
                <a href="#"><svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z" />
                  </svg></a>
              </div>
            </th>
            <th scope="col" class="px-6">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b" v-for="(requisition, index) in filteredRequisitions" :key="index">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-search-' + index" type="checkbox" class="w-4 h-4" />
                <label :for="'checkbox-table-search-' + index" class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-6 py-2">{{ requisition.patient.first_name + " " + requisition.patient.last_name }}</td>
            <td class="px-6 py-2">{{ requisition.patient.ipop }}</td>
            <td class="px-6 py-2">{{ requisition.patient.blood_group.description }}</td>
            <td class="px-6 py-2">{{ requisition.patient_situation }}</td>
            <td class="px-6 py-2">{{ requisition.patient_intent }}</td>
            <td class="px-6 py-2">{{ requisition.department.name }}</td>
            <td class="px-6 py-2">{{ new Date(requisition.created_at).toLocaleDateString('en-CA') }}</td>
            <td class="px-6 py-2">
              <template v-if="requisition.samples.length === 0">
                <span class="text-red-500 bg-red-100 px-2 py-1 rounded">
                Not Received
              </span>
              </template>
              <template v-else>
                <span class="p-2 rounded-lg" :class="{
                  'bg-green-100 text-green-500': ['received'].includes(requisition.samples[0].sample_status),
                  'bg-red-100 text-red-500': ['rejected'].includes(requisition.samples[0].sample_status)
                }">
                  {{ requisition.samples[0].sample_status }}
                </span>
              </template>
            </td>
            <td class="px-6 py-2">
              <span class="p-2 rounded-lg dark:text-custom-blue">
                {{ requisition.status }}
              </span>
            </td>
            <td class="flex items-center p-4">
              <CustomButton label="View Details" @click="redirectToRequisitionDetails(requisition.requisition_id)"
              :disabled="isLoading[requisition.requisition_id]" 
              :bg-class="isLoading[requisition.requisition_id] ? 'bg-gray-300 cursor-not-allowed' : 'bg-custom-yellow text-sm py-1'" />
              <div v-if="isLoading[requisition.requisition_id]" class="ml-2 animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-gray-900"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center gap-4 mt-4">
      <button @click="previousPage" :disabled="currentPage === 1"
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m15 19-7-7 7-7" />
        </svg>
        Previous
      </button>

      <div class="flex items-center gap-2">
        <button v-for="page in totalPages" :key="page" @click="goToPage(page)" :class="{
          'bg-custom-blue text-white': page === currentPage,
          'text-custom-blue': page !== currentPage
        }"
          class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-medium transition-all hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {{ page }}
          </span>
        </button>
      </div>

      <button @click="nextPage" :disabled="currentPage === totalPages"
        class="flex items-center gap-2 px-6 py-3 font-bold text-center text-custom-blue align-middle transition-all rounded-lg select-none hover:bg-custom-blue/10 active:bg-custom-blue/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        type="button">
        Next
        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
          viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m9 5 7 7-7 7" />
        </svg>
      </button>
    </div>
    </template>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import api from "@/services/api";

export default {
  name: "ClinicalRequisitionTab",
  components: {
    CustomButton,
  },
  created() {
    this.getRequisitions();
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      perPage: 10,
      loading: false,
      requisitions: [],
      isLoading: {},
      error: null,
      filters: {
        bloodGroup: "",
        dateRegistered: '',
        status: '',
        sample: ''
      },
    };
  },
  computed: {
    filteredRequisitions() {
      const search = this.searchQuery.toLowerCase();

      return this.requisitions.filter((requisition) => {
        const firstName = requisition.patient?.first_name || '';
        const lastName = requisition.patient?.last_name || '';
        const bloodGroupName = requisition.patient?.blood_group?.name || '';
        const bloodGroupDescription = requisition.patient?.blood_group?.description || '';
        const ipop = requisition.patient?.ipop || '';
        const patientSituation = requisition.patient_situation || '';
        const patientIntent = requisition.patient_intent || '';
        const departmentName = requisition.department?.name || '';
        const createdAt = requisition.created_at || '';
        const sampleStatus = requisition.samples[0]?.sample_status || '';
        const requisitionStatus = requisition.status || '';

        // Validate and handle the createdAt date
        const validCreatedAt = createdAt && !isNaN(new Date(createdAt).getTime())
          ? new Date(createdAt).toISOString()
          : null;

        // Check search query match (case-insensitive)
        const matchesSearch =
          firstName.toLowerCase().includes(search) ||
          lastName.toLowerCase().includes(search) ||
          bloodGroupName.toLowerCase().includes(search) ||
          ipop.toLowerCase().includes(search) ||
          patientSituation.toLowerCase().includes(search) ||
          patientIntent.toLowerCase().includes(search) ||
          departmentName.toLowerCase().includes(search) ||
          (validCreatedAt && validCreatedAt.toLowerCase().includes(search)) ||
          sampleStatus.toLowerCase().includes(search) ||
          requisitionStatus.toLowerCase().includes(search);

        // Check dropdown filters (case-insensitive)
        const matchesBloodGroup =
          !this.filters.bloodGroup ||
          bloodGroupDescription.toLowerCase() === this.filters.bloodGroup.toLowerCase();
        const matchesDateAdded =
          !this.filters.dataAdded || createdAt.startsWith(this.filters.dataAdded);
        const matchesStatus =
          !this.filters.status || requisitionStatus.toLowerCase() === this.filters.status.toLowerCase();
        const matchesSample =
          !this.filters.sample ||
          (requisition.samples.length > 0 &&
            sampleStatus.toLowerCase() === this.filters.sample.toLowerCase());

        return matchesSearch && matchesBloodGroup && matchesDateAdded && matchesStatus && matchesSample;
      });
    }
  },

  watch: {
    filters: { deep: true, handler: 'getRequisitions' },
    searchQuery: 'getRequisitions'
  },

  methods: {
    applyFilters(filters) {
      this.filters = { ...this.filters, ...filters };
      this.currentPage = 1;
    },

    resetFilters() {
      this.filters = {
        bloodGroup: "",
        dateRegistered: '',
        donationProcess: '',
        sample_status: "",
      };
      this.currentPage = 1;
    },

    async getRequisitions() {
      this.loading = true;
      try {
        const queryParams = { ...this.filters, search_query: this.searchQuery, page: this.currentPage, per_page: this.perPage };
        const response = await api.fetchRequisitions(queryParams);
        this.requisitions = response.data || response;
        this.currentPage = response.current_page;
        this.totalPages = response.total_pages;
      } catch (error) {
        this.error = error.message || "Failed to fetch requisitions";
      } finally {
        this.loading = false;
      }
    },

    async goToPage(page) {
      this.currentPage = page;
      await this.getRequisitions();
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.getRequisitions();
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.getRequisitions();
      }
    },

    async redirectToRequisitionDetails(id) {
      this.isLoading[id] = true;
      try {
        await new Promise(resolve => setTimeout(resolve, 500));     
      this.$router.push({
        name: "FacilityRequisitionDetailsPage",
        params: { requisition_id: id },
        query: { flag: "clinical_management" }
      });
    }catch {
          this.isLoading[id] = false
      }
    },

    calculateDaysRemaining(expiryDate) {
      const today = new Date();
      const expiry = new Date(expiryDate);
      const timeDiff = expiry - today;
      const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      return daysRemaining;
    },


  },
};
</script>

<style lang="css" scoped></style>
